import React, { useEffect, useState } from 'react'
import { Row } from 'reactstrap'
import { Card, Button, notification, Input } from 'antd'
import usePoolStatus from '../../../Hooks/usePoolStatus'
import { useWeb3React } from '@web3-react/core'
import {
  getRewardTokenSetStatus,
  setRewardsToken,
} from '../../../Blockchain/web3.service'
import FinalizedPoolButton from './FinalizedPoolButton'
import PoolCancelButton from './PoolCancelButton'
import WhiteListingAddressWidget from './WhiteListingAddressWidget'
import StartClaimingButton from './StartClaimingButton'

export default function AdminControlWidget(props) {

  const { privateSaleAddress } = props
  const {
    isLoading: isPoolStatusLoading,
    poolStatus
  } = usePoolStatus({ poolAddress: privateSaleAddress })
  const { account, library } = useWeb3React()

  const [isRewardsTokenSetLoading, setIsRewardsTokenSetLoading] = useState(false)
  const [isRewardsTokenSet, setIsRewardsTokenSet] = useState(false)
  const [rewardsTokenValue, setRewardsTokenValue] = useState('')
  const [isRewardsTokenSetExecutionLoading, setIsRewardsTokenSetExecutionLoading] = useState(false)

  useEffect(() => {
    if (privateSaleAddress) {
      fetchRewardTokenStatus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateSaleAddress])


  const handleRewardsTokenSetup = async () => {
    try {

      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        });
        setIsRewardsTokenSetExecutionLoading(false)
        return
      }

      if (!rewardsTokenValue) {
        notification['error']({
          message: 'Input Error',
          description:
            'Please enter a correct address for rewards token',
        });
        setIsRewardsTokenSetExecutionLoading(false)
        return
      }

      setIsRewardsTokenSetExecutionLoading(true)
      const response = await setRewardsToken(privateSaleAddress, rewardsTokenValue, library.getSigner())
      console.log("response ", response)
      setIsRewardsTokenSetExecutionLoading(false)
      notification['success']({
        message: 'Success',
        description: 'Rewards token address has been added to the pool.',
      });
    } catch (error) {
      console.error("ERROR while adding the rewards token address ", error)
      setIsRewardsTokenSetExecutionLoading(false)
      notification['error']({
        message: 'Transaction Execution Failed',
        description: error,
      });

    }
  }


  const fetchRewardTokenStatus = async () => {
    try {
      setIsRewardsTokenSetLoading(false)
      const rewardsTokenSetResponse = await getRewardTokenSetStatus(privateSaleAddress)
      setIsRewardsTokenSet(rewardsTokenSetResponse)
      setIsRewardsTokenSetLoading(false)
    } catch (error) {
      setIsRewardsTokenSet(false)
      setIsRewardsTokenSetLoading(false)
      console.error("ERROR while fetching reward token set status")
    }
  }



  return (
    <Card className="mt-4 admin-control-card">
      <Row>
        <div className="text-center">Admin Controls (Danger Zone)</div>
      </Row>

      <Row className="mt-2 finalized-pool">
        <FinalizedPoolButton
          isPoolStatusLoading={isPoolStatusLoading}
          poolStatus={poolStatus}
          privateSaleAddress={privateSaleAddress}
        />
      </Row>

      <Row className="mt-2 finalized-pool">
        <PoolCancelButton
          isPoolStatusLoading={isPoolStatusLoading}
          poolStatus={poolStatus}
          privateSaleAddress={privateSaleAddress}
        />
      </Row>

      <Row className="mt-2 finalized-pool">
        <StartClaimingButton
          isPoolStatusLoading={isPoolStatusLoading}
          poolStatus={poolStatus}
          privateSaleAddress={privateSaleAddress}
        />
      </Row>


      <Row className="mt-4">
        <span className='input-label'>
          Set your rewards token
        </span>
      </Row>
      <Row className="" style={{ padding: '0 10px' }}>

        <Input
          disabled={isRewardsTokenSetLoading || isRewardsTokenSet}
          value={isRewardsTokenSetLoading ? 'Please wait' : rewardsTokenValue}
          onChange={e => setRewardsTokenValue(e.target.value)}
        />
      </Row>

      <Row className="mt-2">
        <div className='contribution-button-container'>
          <Button
            loading={isRewardsTokenSetExecutionLoading}
            onClick={handleRewardsTokenSetup}
            disabled={isRewardsTokenSetLoading || isRewardsTokenSet || !rewardsTokenValue}
            type="primary">
            Set Rewards Token
          </Button>
        </div>
      </Row>

      {/* whitelist user address widget */}
      <WhiteListingAddressWidget
        privateSaleAddress={privateSaleAddress}
      />
    </Card>
  )
}