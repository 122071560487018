import React, { createContext, useState } from 'react'

export const GlobalState = createContext()

export const DataProvider = ({ children }) => {

    const [selectedToken, setSelectedToken] = useState('')

    const state = {
        selectedToken: [selectedToken, setSelectedToken]
    }

    return (
        <GlobalState.Provider value={state}>
            {children}
        </GlobalState.Provider>
    )
}