import React, { useRef } from 'react';
import {
  Row,
  Col,
  Card,
  Input,
  Button,
  Form,
} from 'antd';

import {
  LinkOutlined,
  GlobalOutlined,
  FacebookFilled,
  TwitterOutlined,
  GithubOutlined,
  RedditOutlined,
  InstagramFilled
} from '@ant-design/icons'

import BundledEditor from '../../../Helpers/BundledEditor';

import Discord from '../../../Images/discord.png'
import Telegram from '../../../Images/telegram.png'
export default function AdditionalInfo(props) {

  const {
    setStepNumber,
    stepNumber,
    logoURL,
    setLogoURL,
    website,
    setWebsite,
    facebookLink,
    setFacebookLink,
    twitterLink,
    setTwitterLink,
    githubLink,
    setGithubLink,
    telegramLink,
    setTelegramLink,
    instagramLink,
    setInstagramLink,
    discordLink,
    setDiscordLink,
    redditLink,
    setRedditLink,
    description,
    setDescription
  } = props

  const editorRef = useRef(null);

  const handleBack = () => {
    setStepNumber(stepNumber - 1)
  }

  const handleNext = () => {
    setStepNumber(stepNumber + 1)
  }

  return (
    <div className="mx-auto mt-5 col-11">
      <Card className="pt-3 pb-3">
        <Form
          name="step_three"
          initialValues={{
            logoURL: logoURL,
            website: website,
            facebookLink: facebookLink,
            twitterLink: twitterLink,
            githubLink: githubLink,
            telegramLink: telegramLink,
            instagramLink: instagramLink,
            discordLink: discordLink,
            redditLink: redditLink,
            description: description
          }}
        >

          <Row className="mt-4">
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className='right-padding'>
                <span className="text-muted">Logo URL</span>
                <Form.Item>
                  <Input
                    prefix={<LinkOutlined style={{ color: '#D3BC84' }} />}
                    value={logoURL}
                    name="logoUrl"
                    className="input-background-inside-form"
                    onChange={e => setLogoURL(e.target.value)} />
                </Form.Item>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <span className="text-muted">Website</span>
              <Form.Item>
                <Input
                  prefix={<GlobalOutlined style={{ color: '#D3BC84' }} />}
                  value={website}
                  name="website"
                  className="input-background-inside-form"
                  onChange={e => setWebsite(e.target.value)} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className='right-padding'>
                <span className="text-muted">Facebook</span>
                <Form.Item>
                  <Input
                    name="facebookLink"
                    prefix={<FacebookFilled style={{ color: '#D3BC84' }} />}
                    value={facebookLink}
                    className="input-background-inside-form"
                    onChange={e => setFacebookLink(e.target.value)} />
                </Form.Item>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <span className="text-muted">Twitter</span>
              <Form.Item >
                <Input
                  prefix={<TwitterOutlined style={{ color: '#D3BC84' }} />}
                  name="twitterLink"
                  value={twitterLink}
                  className="input-background-inside-form"
                  onChange={e => setTwitterLink(e.target.value)} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className='right-padding'>
                <span className="text-muted">Github</span>
                <Form.Item>
                  <Input
                    prefix={<GithubOutlined style={{ color: '#D3BC84' }} />}
                    name="githubLink"
                    value={githubLink}
                    className="input-background-inside-form"
                    onChange={e => setGithubLink(e.target.value)} />
                </Form.Item>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <span className="text-muted">Telegram</span>
              <Form.Item>
                <Input
                  prefix={<img src={Telegram}
                    style={{ width: '16px' }}
                    name="telegramLink"
                    alt="telegram logo" />}
                  value={telegramLink}
                  className="input-background-inside-form"
                  onChange={e => setTelegramLink(e.target.value)} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className='right-padding'>
                <span className="text-muted">Instagram</span>
                <Form.Item>
                  <Input
                    name="instagramLink"
                    prefix={<InstagramFilled style={{ color: '#D3BC84' }} />}
                    value={instagramLink}
                    className="input-background-inside-form"
                    onChange={e => setInstagramLink(e.target.value)} />
                </Form.Item>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <span className="text-muted">Discord</span>
              <Form.Item>
                <Input
                  prefix={<img src={Discord}
                    style={{ width: '18px' }} alt="discord" />}
                  value={discordLink}
                  name="discordLink"
                  className="input-background-inside-form"
                  onChange={e => setDiscordLink(e.target.value)} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <span className="text-muted">Reddit</span>
              <Form.Item>
                <Input
                  prefix={<RedditOutlined style={{ color: '#D3BC84' }} />}
                  value={redditLink}
                  name="redditLink"
                  className="input-background-inside-form"
                  onChange={e => setRedditLink(e.target.value)} />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <span className="text-muted">Description</span>
              <Form.Item>
                <BundledEditor
                      onInit={(evt, editor) => editorRef.current = editor}
                      onChange={ (e) => setDescription(editorRef.current.getContent())}
                      init={{
                      height: 500,
                      menubar: false,
                      plugins: [
                          'advlist', 'anchor', 'autolink', 'help', 'image', 'link', 'lists',
                          'searchreplace', 'table', 'wordcount'
                      ],
                      toolbar: 'undo redo | blocks | ' +
                          'bold italic forecolor | alignleft aligncenter ' +
                          'alignright alignjustify | bullist numlist outdent indent | ' +
                          'removeformat | help',
                      content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                      }}
                  />
                {/* <Input.TextArea
                  name="description"
                  showCount
                  maxLength={250}
                  value={description}
                  className="input-background-inside-form"
                  onChange={e => setDescription(e.target.value)} /> */}
              </Form.Item>
            </Col>
          </Row>


          <div className="d-flex justify-content-end">
            <Form.Item>
              <Button type="default" onClick={handleBack} style={{ marginRight: '8px' }}>
                Back
              </Button>
              <Button type="primary" onClick={handleNext}>
                Next
              </Button>
            </Form.Item>
          </div>

        </Form>
      </Card>
    </div>
  )
}
