import React, { useEffect, useState } from 'react'
import { Row, } from 'reactstrap'
import { Button, notification, Spin } from 'antd'
import {
  claimTokensFromPool,
  claimVestedTokensFromPool,
  getPoolVestingStatus
} from '../../../Blockchain/web3.service'
import { useWeb3React } from '@web3-react/core'
import useUserPrivateSalePoolDetails from '../../../Hooks/useUserPrivateSalePoolDetails'

export default function ClaimButtons(props) {

  const {
    isPoolStatusLoading,
    isTokenAddressLoading,
    tokenAddress,
    poolStatus,
    poolAddress
  } = props

  const [isClaimTokensLoading, setIsClaimTokensLoading] = useState(false)
  const [isClaimVestingLoading, setIsClaimVestingLoading] = useState(false)
  const [claimButtonText, setClaimButtonText] = useState('Claim tokens')
  const { account, library } = useWeb3React()
  const [isVestingEnabled, setIsVestingEnabled] = useState(false)

  const { userPrivateSalePoolDetails, isLoading: isUserDetailsLoading } = useUserPrivateSalePoolDetails({
    poolAddress: poolAddress,
    walletAddress: account,
    isWithoutToken: tokenAddress ? false : true
  })

  useEffect(() => {
    if (userPrivateSalePoolDetails && userPrivateSalePoolDetails.isClaimed) {
      setClaimButtonText('Claimed')
    } else {
      setClaimButtonText('Claim tokens')
    }
  }, [userPrivateSalePoolDetails])

  useEffect(() => {
    if (poolAddress) {
      fetchPoolVestingStatus(poolAddress)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolAddress])

  const fetchPoolVestingStatus = async () => {
    try {
      const vestingResponse = await getPoolVestingStatus(poolAddress)
      setIsVestingEnabled(vestingResponse)
    } catch (error) {
      console.error("ERROR while fetching pool vesting data ", error)
    }
  }
  const handleClaimTokens = async () => {
    try {

      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        });
        setIsClaimTokensLoading(false)
        return
      }

      setIsClaimTokensLoading(true)
      const response = await claimTokensFromPool(poolAddress, library.getSigner())
      console.log("response ", response)
      setIsClaimTokensLoading(false)
      notification['success']({
        message: 'Success',
        description: 'You have successfully claim your tokens.',
      });
    } catch (error) {
      setIsClaimTokensLoading(false)
      notification['error']({
        message: 'Error Occurred',
        description: error,
      });
    }
  }

  const handleClaimVesting = async () => {
    try {

      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        });
        setIsClaimVestingLoading(false)
        return
      }

      setIsClaimVestingLoading(true)
      const response = await claimVestedTokensFromPool(poolAddress, library.getSigner())
      console.log("response ", response)
      setIsClaimVestingLoading(false)
      notification['success']({
        message: 'Success',
        description: 'You have successfully claim your vesting tokens.',
      });
    } catch (error) {
      let errorMessage = 'Something went wrong while trying to claim your vested tokens. Please try again'
      if (error && error.reason && error.reason !== '') {
        errorMessage = error.reason
      }
      notification['error']({
        message: 'Error',
        description: errorMessage,
      });
      setIsClaimVestingLoading(false)
      console.error("ERROR while executing claim vesting tokens ", error)
    }
  }

  return (
    <>
      {
        isUserDetailsLoading ? (
          <div className="loader d-flex justify-content-center mt-4">
            <Spin size='small' />
          </div>
        ) : (
          <div className='claim-btn-container'>
            <div className='claim-token-btn'>
              {
                parseFloat(userPrivateSalePoolDetails?.myContributionInBNB) > 0 ? (
                  <Row className="mt-2 claim-tokens">
                    {
                      !isPoolStatusLoading &&
                        !isTokenAddressLoading &&
                        tokenAddress &&
                        (poolStatus?.poolStatus === 4) ? (
                        <Button
                          loading={isClaimTokensLoading}
                          onClick={handleClaimTokens}
                          block
                          disabled={isPoolStatusLoading || userPrivateSalePoolDetails.isClaimed}
                          type="primary">
                          {claimButtonText}
                        </Button>
                      ) : (<></>)
                    }

                  </Row>
                ) : (<></>)
              }
            </div>
            <div className='claim-token-btn'>
              {
                parseFloat(userPrivateSalePoolDetails?.myContributionInBNB) > 0 && isVestingEnabled ? (
                  <Row className="mt-2 claim-tokens">
                    {
                      !isPoolStatusLoading &&
                        !isTokenAddressLoading &&
                        tokenAddress &&
                        (poolStatus?.poolStatus === 4) ? (
                        <Button
                          loading={isClaimVestingLoading}
                          onClick={handleClaimVesting}
                          block
                          disabled={isPoolStatusLoading}
                          type="primary">
                          Claim your vesting
                        </Button>
                      ) : (<></>)
                    }
                  </Row>
                ) : (
                  <></>
                )
              }
            </div>
          </div>
        )
      }
    </>
  )
}
