/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import * as web3Service from '../Blockchain/web3.service'
export const usePoolProgressDetails = (props) => {

  const { poolAddress } = props
  const [poolProgressDetails, setPoolProgressDetails] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (poolAddress) {
      fetchPoolProgressDetails(poolAddress)
    }
  }, [poolAddress])

  useEffect(() => {

    const interval = setInterval(() => {
      if (poolAddress) {
        fetchPoolProgressDetailsDoInBackground(poolAddress)
      }

      //update every 2 seconds 
    }, 5000)
    return () => clearInterval(interval)
  }, [poolAddress])


  const fetchPoolProgressDetails = async (poolAddress) => {
    try {
      setIsLoading(true)
      const response = await web3Service.getPoolProgressDetails(poolAddress)
      setPoolProgressDetails(response)
      setIsLoading(false)
    } catch (error) {
      setPoolProgressDetails(null)
      setIsLoading(false)
      console.error("ERROR HOOK : while fetching pool status ", error)
    }
  }

  const fetchPoolProgressDetailsDoInBackground = async (poolAddress) => {
    try {

      const response = await web3Service.getPoolProgressDetails(poolAddress)
      setPoolProgressDetails(response)
    } catch (error) {
      setPoolProgressDetails(null)
      console.error("ERROR HOOK BACKGROUND: while fetching pool status ", error)
    }
  }


  return { poolProgressDetails, isLoading }
}


export default usePoolProgressDetails

