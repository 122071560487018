import React, { useState, useEffect } from 'react'
import { Spin, Button, notification } from 'antd'
import { startClaiming } from '../../../Blockchain/web3.service'
import { useWeb3React } from '@web3-react/core'

export default function StartClaimingButton(props) {
  const { isPoolStatusLoading, poolStatus, privateSaleAddress } = props


  const [canShowTheButton, setCanShowTheButton] = useState(false)
  const [isStartClaimingLoading, setIsStartClaimingLoading] = useState(false)
  const { account, library } = useWeb3React()

  useEffect(() => {
    if (poolStatus) {
      if (poolStatus?.statusCode === 'finalized') {
        setCanShowTheButton(true)
      } else {
        setCanShowTheButton(false)
      }
    }
  }, [poolStatus])

  const handleStartClaiming = async () => {
    try {

      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        });
        setIsStartClaimingLoading(false)
        return
      }

      setIsStartClaimingLoading(true)
      const response = await startClaiming(privateSaleAddress, library.getSigner())
      console.log("response ", response)
      setIsStartClaimingLoading(false)
      notification['success']({
        message: 'Success',
        description: 'You have successfully start claiming function.',
      });
    } catch (error) {
      setIsStartClaimingLoading(false)
      console.error("ERROR while start the claiming", error)
      notification['error']({
        message: 'Transaction execution failed',
        description: error,
      });

    }
  }

  return (
    <div className='finalized-pool-button-container'>

      {
        isPoolStatusLoading ? (
          <div className='loader text-centered'>
            <Spin size='small' />
          </div>
        ) : (
          <Button
            loading={isStartClaimingLoading}
            disabled={!canShowTheButton}
            onClick={handleStartClaiming}
            block
            type="primary">
            Start Claiming
          </Button>
        )
      }

    </div>
  )
}
