import React, { useEffect, useState } from 'react'
import { Row } from 'reactstrap'
import { Card, Button, notification, Input } from 'antd'
import { useWeb3React } from '@web3-react/core'
import {
  getDeployer,
  executeWithdrawBEP20Tokens
} from '../../../Blockchain/web3.service'



export default function BEP20TokenWithdrawWidget(props) {

  const { privateSaleAddress } = props
  const { account, library } = useWeb3React()

  const [tokenAddress, setTokenAddress] = useState('')
  const [percentage, setPercentage] = useState(0.0)
  const [isBEP20TokenWithdrawalLoading, setIsBEP20TokenWithdrawalLoading] = useState(false)
  const [deployerAddress, setDeployerAddress] = useState('')

  useEffect(() => {
    fetchDeployerAddress()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateSaleAddress, account])


  const handleBEP20TokenWithdrawal = async () => {
    try {

      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        });
        setIsBEP20TokenWithdrawalLoading(false)
        return
      }

      setIsBEP20TokenWithdrawalLoading(true)
      const response = await executeWithdrawBEP20Tokens(privateSaleAddress, tokenAddress, percentage, library.getSigner())
      console.log("response ", response)
      setIsBEP20TokenWithdrawalLoading(false)
      notification['success']({
        message: 'Success',
        description: 'BEP20 token withdrawal success.',
      });
    } catch (error) {
      console.error("ERROR while withdrawing BEP20 tokens ", error)
      setIsBEP20TokenWithdrawalLoading(false)
      notification['error']({
        message: 'Transaction Execution Failed',
        description: error,
      });

    }
  }

  const fetchDeployerAddress = async () => {
    try {
      const deployerAddressResponse = await getDeployer(privateSaleAddress)
      setDeployerAddress(deployerAddressResponse)

    } catch (error) {
      setDeployerAddress('')
      console.error("ERROR : while fetching deployer address ", error)
    }
  }

  return (
    <div className='owner-section'>
      {
        account?.toLowerCase() === deployerAddress?.toLowerCase() ? (
          <Card className="mt-4 admin-control-card">
            <Row>
              <div className="text-center">BEP 20 Token Withdrawal (Danger Zone)</div>
            </Row>

            <Row className="mt-4">
              <span className='input-label'>
                Set Token Address
              </span>
            </Row>
            <Row className="" style={{ padding: '0 10px' }}>

              <Input
                value={tokenAddress}
                onChange={e => setTokenAddress(e.target.value)}
              />
            </Row>

            <Row className="mt-4">
              <span className='input-label'>
                Set Percentage (%)
              </span>
            </Row>
            <Row className="" style={{ padding: '0 10px' }}>

              <Input
                value={percentage}
                onChange={e => setPercentage(e.target.value)}
              />
            </Row>

            <Row className="mt-2">
              <div className='contribution-button-container mt-2'>
                <Button
                  loading={isBEP20TokenWithdrawalLoading}
                  onClick={handleBEP20TokenWithdrawal}
                  block={true}
                  type="primary">
                  Withdraw Tokens
                </Button>
              </div>
            </Row>
          </Card>
        ) : (<></>)
      }

    </div>

  )
}