import React, { useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap'
import { Card, Tag, Spin } from 'antd'
import usePoolStatus from '../../../Hooks/usePoolStatus'
import PoolProgressBar from '../PoolProgressBar'
import { checkPoolTokenAddress, } from '../../../Blockchain/web3.service'

import UpcomingCountdown from '../../PrivateSales/UpcomingCountdown'
import { DateTime } from 'luxon'
import ClaimButtons from './ClaimButtons'
import ClaimAndExitButton from './ClaimAndExitButton'
import BuyWithBNBWidget from './BuyWithBNBWidget'
import ClaimRewardsButton from './ClaimRewardsButton'

export default function ContributionWidget(props) {

  const { poolAddress, privateSaleDetails } = props
  const { isLoading: isPoolStatusLoading, poolStatus } = usePoolStatus({ poolAddress })
  const [maxContributionAmount, setMaxContributionAmount] = useState(0.0)
  const [minContributionAmount, setMinContributionAmount] = useState(0.0)
  const [isPoolDataLoading, setIsPoolDataLoading] = useState(false)
  const [isTokenAddressLoading, setIsTokenAddressLoading] = useState(false)
  const [tokenAddress, setTokenAddress] = useState(null)
  const [shouldForcedRefresh, setShouldForcedRefresh] = useState(false)

  useEffect(() => {
    if (poolAddress) {
      fetchTokenAddressData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolAddress])

  const fetchTokenAddressData = async () => {
    try {
      setIsTokenAddressLoading(true)
      const tokenAddressesResponse = await checkPoolTokenAddress(poolAddress)
      if (tokenAddressesResponse) {
        const tokenAddress = tokenAddressesResponse.tokenAddress
        if (tokenAddress && tokenAddress.includes("0x00000")) {
          setTokenAddress(null)
        } else {
          setTokenAddress(tokenAddress)
        }
      }
      setIsTokenAddressLoading(false)
    } catch (error) {
      setTokenAddress(null)
      setIsTokenAddressLoading(false)
      console.log("ERROR : while fetching token address : ", error)
    }
  }



  return (
    <Card className="mt-4">
      {/* <Row>
        <div className="text-center">Contribution</div>
      </Row> */}

      <Row className="mt-2">
        <Col>
          Pool Status
        </Col>

        <Col className="text-end">
          {
            isPoolStatusLoading ? (
              <div className='loader'>
                <Spin size="small" />
              </div>
            ) : (
              <Tag color={poolStatus?.statusColor}>
                {poolStatus?.statusName}
              </Tag>
            )
          }
        </Col>
      </Row>

      <Row className='mt-4'>
        {
          poolStatus?.statusCode === 'upcoming' ? (
            <div className='cd-timer-title d-flex justify-content-center mb-2'>
              Sales Start In
            </div>
          ) : (<></>)
        }

        <div className='d-flex justify-content-center'>
          {
            poolStatus?.statusCode === 'upcoming' ? (
              <UpcomingCountdown
                startTime={privateSaleDetails ? privateSaleDetails.startTimeTimestamp : DateTime.now().toSeconds()} />
            ) : (<></>)
          }
        </div>
      </Row>

      {
        poolStatus?.statusCode === 'live' && privateSaleDetails.publicSaleStartTimestamp > DateTime.now().toSeconds() ? (
          <Row className='mt-4'>
            <div className='cd-timer-title d-flex justify-content-center mb-2'>
              Public Sales Starts In
            </div>
            <div className='d-flex justify-content-center'>
              <UpcomingCountdown
                startTime={privateSaleDetails ? privateSaleDetails.publicSaleStartTimestamp : DateTime.now().toSeconds()} />
            </div>
          </Row>
        ) :
          (
            <></>
          )
      }

      <Row className="mt-2">
        <div className='progress-bar-section'>
          <PoolProgressBar
            privateSaleDetails={privateSaleDetails}
            poolAddress={poolAddress}
            setIsPoolDataLoading={setIsPoolDataLoading}
            setMaxContributionAmount={setMaxContributionAmount}
            setMinContributionAmount={setMinContributionAmount}
          />
        </div>
      </Row>

      {/* Buy with BNB widget */}
      <div className='buy-with-bnb-widget'>
        <BuyWithBNBWidget
          setShouldForcedRefresh={setShouldForcedRefresh}
          minContributionAmount={minContributionAmount}
          maxContributionAmount={maxContributionAmount}
          isPoolStatusLoading={isPoolStatusLoading}
          isTokenAddressLoading={isTokenAddressLoading}
          poolStatus={poolStatus}
          isPoolDataLoading={isPoolDataLoading}
          poolAddress={poolAddress}
          privateSaleDetails={privateSaleDetails}
        />
      </div>

      {/* Claim and exit button section */}
      <div className='claim-and-exit-buttons mt-2'>
        <ClaimAndExitButton
          shouldForcedRefresh={shouldForcedRefresh}
          setShouldForcedRefresh={setShouldForcedRefresh}
          isPoolStatusLoading={isPoolStatusLoading}
          isTokenAddressLoading={isTokenAddressLoading}
          poolStatus={poolStatus}
          poolAddress={poolAddress}
        />
      </div>

      {/* claim token button section */}
      <div className='claim-your-token-buttons'>
        <ClaimButtons
          isPoolStatusLoading={isPoolStatusLoading}
          isTokenAddressLoading={isTokenAddressLoading}
          tokenAddress={tokenAddress}
          poolStatus={poolStatus}
          poolAddress={poolAddress}
        />
      </div>

      {/* claim rewards token button section */}
      <div className='claim-your-token-buttons mt-2'>
        <ClaimRewardsButton
          isPoolStatusLoading={isPoolStatusLoading}
          isTokenAddressLoading={isTokenAddressLoading}
          poolStatus={poolStatus}
          poolAddress={poolAddress}
        />
      </div>
    </Card >
  )
}
