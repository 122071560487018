import React, { useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap'
import { Card, Spin } from 'antd'
import DataTable, { createTheme } from 'react-data-table-component'
import { getPoolVestingStatus, calculateVestingCycles } from '../../Blockchain/web3.service'

export default function VestingDetailsWidget(props) {

  const { isPrivateSaleLoading, privateSaleAddress } = props
  const [isVestingStatusLoading, setIsVestingStatusLoading] = useState(false)
  const [vestingStatus, setVestingStatus] = useState(false)
  const [isVestingDetailsLoading, setIsVestingDetailsLoading] = useState(false)
  const [vestingCycleDetails, setVestingCycleDetails] = useState([])

  const fetchVestingStatus = async () => {
    try {
      setIsVestingStatusLoading(true)
      const vestingStatusResponse = await getPoolVestingStatus(privateSaleAddress)
      setVestingStatus(vestingStatusResponse || false)
      setIsVestingStatusLoading(false)
    } catch (error) {
      setIsVestingStatusLoading(false)
      setVestingStatus(false)
      console.error("ERROR while fetching vesting status ", error)
    }
  }

  const calculateVestingDetails = async () => {
    try {
      setIsVestingDetailsLoading(true)
      const vestingCycleDetailsResponse = await calculateVestingCycles(privateSaleAddress)
      setVestingCycleDetails(vestingCycleDetailsResponse || [])
      setIsVestingDetailsLoading(false)
    } catch (error) {
      setIsVestingDetailsLoading(false)
      console.error("ERROR while fetching vesting details ", error)
    }
  }


  useEffect(() => {
    if (privateSaleAddress) {
      fetchVestingStatus()
      calculateVestingDetails()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateSaleAddress])


  const columns = [
    {
      name: 'Release Date',
      sortable: false,
      cell: row => (
        <div className='d-flex justify-content-center align-items-center'>
          {row.nextDate}
        </div>
      )
    },
    {
      name: 'Percentage (%)',
      sortable: false,
      cell: row => (
        <div className='d-flex justify-content-center align-items-center'>
          {row.nextReleasePercentage}
        </div>
      )
    },
    {
      name: 'Amount',
      sortable: false,
      cell: row => (
        <div className='d-flex justify-content-center align-items-center'>
          {row.nextReleaseTokens}
        </div>
      )
    }

  ]

  createTheme('dark', {
    background: {
      default: 'transparent',
    },
  });

  return (
    <>
      {
        isPrivateSaleLoading || isVestingStatusLoading || isVestingDetailsLoading ? (
          <Card className="mt-4">
            <div className="d-flex justify-content-center" >
              <Spin />
            </div>
          </Card>

        ) : (
          vestingStatus ? (
            <Card className="mt-4" title="Investor Vesting Schedule">

              {
                isPrivateSaleLoading ? (
                  <div className='d-flex mt-2 justify-content-center'>
                    <Spin size="small" />
                  </div>
                ) : (
                  <div>
                    <Row>
                      <Col>
                        <DataTable
                          noHeaders
                          pagination
                          theme="dark"
                          data={vestingCycleDetails ? vestingCycleDetails : []}
                          columns={columns}
                          highlightOnHover
                          pointerOnHover
                          paginationRowsPerPageOptions={[10, 25, 50, 100]}
                        />
                      </Col>
                    </Row>


                  </div>
                )
              }

            </Card>
          ) : (
            <></>
          )
        )
      }
    </>

  )
}