import React, { useContext, useState } from 'react'
import { Steps } from 'antd';
import PreSalesInfo from '../../Components/BnbContributionPage/StepWizard/PreSalesInfo';
import AdditionalInfo from '../../Components/BnbContributionPage/StepWizard/AdditionalInfo';
import ReviewInfo from '../../Components/BnbContributionPage/StepWizard/ReviewInfo';
import { GlobalState } from '../../GlobalState';

function BnbContribution() {

    const { Step } = Steps;
    const [stepNumber, setStepNumber] = useState(0)
    const state = useContext(GlobalState)


    const [tokenPerBNB, setTokenPerBNB] = useState('')
    const [minimumBuy, setMinimumBuy] = useState('')
    const [maximumBuy, setMaximumBuy] = useState('')
    const [hardCap, setHardCap] = useState('')
    const [startTime, setStartTime] = useState(null)
    const [endTime, setEndTime] = useState(null)
    const [isVestingEnable, setIsVestingEnable] = useState(false)
    const [initialTokenReleasePercentage, setInitialTokenReleasePercentage] = useState(0)
    const [teamTokenReleasePercentage, setTeamTokenReleasePercentage] = useState(0)
    const [vestingPeriodInDays, setVestingPeriodInDays] = useState(0)
    const [preSaleInformation, setPreSaleInformation] = useState(null)
    const [additionalInformation, setAdditionalInformation] = useState(null)
    const [selectedToken, setSelectedToken] = state.selectedToken

    // AdditionalInfo
    const [logoURL, setLogoURL] = useState('')
    const [website, setWebsite] = useState('')
    const [facebookLink, setFacebookLink] = useState('')
    const [twitterLink, setTwitterLink] = useState('')
    const [githubLink, setGithubLink] = useState('')
    const [telegramLink, setTelegramLink] = useState('')
    const [instagramLink, setInstagramLink] = useState('')
    const [discordLink, setDiscordLink] = useState('')
    const [redditLink, setRedditLink] = useState('')
    const [description, setDescription] = useState('')

    const [isNextButtonActive, setIsNextButtonActive] = useState(false)
    const [isWhitelistingEnabled, setIsWhitelistingEnabled] = useState(false)
    const [publicStartTime, setPublicStartTime] = useState(null)
    const [privateSaleName, setPrivateSaleName] = useState('')

    return (
        <div className="container-fluid text-light mt-5 pb-5">
            <Steps current={stepNumber + 1}>
                <Step title="Start a Funding Project" description="Enter Contract if available for Crypto project" />
                <Step title="Enter Project Information" />
                <Step title="Additional Information" description="Let people know who you are" />
                <Step title="Finish" description="Review your information" />
            </Steps>


            {/* STEP ONE */}
            {
                stepNumber === 0 &&

                <PreSalesInfo
                    setPrivateSaleName={setPrivateSaleName}
                    privateSaleName={privateSaleName}
                    tokenPerBNB={tokenPerBNB}
                    setTokenPerBNB={setTokenPerBNB}
                    minimumBuy={minimumBuy}
                    setMinimumBuy={setMinimumBuy}
                    maximumBuy={maximumBuy}
                    setMaximumBuy={setMaximumBuy}
                    hardCap={hardCap}
                    setHardCap={setHardCap}
                    startTime={startTime}
                    setStartTime={setStartTime}
                    endTime={endTime}
                    setEndTime={setEndTime}
                    isVestingEnable={isVestingEnable}
                    setIsVestingEnable={setIsVestingEnable}
                    initialTokenReleasePercentage={initialTokenReleasePercentage}
                    setInitialTokenReleasePercentage={setInitialTokenReleasePercentage}
                    teamTokenReleasePercentage={teamTokenReleasePercentage}
                    setTeamTokenReleasePercentage={setTeamTokenReleasePercentage}
                    vestingPeriodInDays={vestingPeriodInDays}
                    setVestingPeriodInDays={setVestingPeriodInDays}

                    preSaleInformation={preSaleInformation}
                    setPreSaleInformation={setPreSaleInformation}
                    stepNumber={stepNumber}
                    setStepNumber={setStepNumber}
                    isNextButtonActive={isNextButtonActive}
                    setIsNextButtonActive={setIsNextButtonActive}
                    isWhitelistingEnabled={isWhitelistingEnabled}
                    setIsWhitelistingEnabled={setIsWhitelistingEnabled}
                    publicStartTime={publicStartTime}
                    setPublicStartTime={setPublicStartTime}
                    selectedToken={selectedToken}
                />
            }

            {/* STEP TWO */}
            {
                stepNumber === 1 &&

                <AdditionalInfo
                    setAdditionalInformation={setAdditionalInformation}
                    stepNumber={stepNumber}
                    setStepNumber={setStepNumber}
                    logoURL={logoURL}
                    setLogoURL={setLogoURL}
                    website={website}
                    setWebsite={setWebsite}
                    facebookLink={facebookLink}
                    setFacebookLink={setFacebookLink}
                    twitterLink={twitterLink}
                    setTwitterLink={setTwitterLink}
                    githubLink={githubLink}
                    setGithubLink={setGithubLink}
                    telegramLink={telegramLink}
                    setTelegramLink={setTelegramLink}
                    instagramLink={instagramLink}
                    setInstagramLink={setInstagramLink}
                    discordLink={discordLink}
                    setDiscordLink={setDiscordLink}
                    redditLink={redditLink}
                    setRedditLink={setRedditLink}
                    description={description}
                    setDescription={setDescription}
                />

            }

            {/* STEP THREE */}
            {
                stepNumber === 2 &&
                <ReviewInfo
                    privateSaleName={privateSaleName}
                    stepNumber={stepNumber}
                    setStepNumber={setStepNumber}
                    additionalInformation={additionalInformation}
                    preSaleInformation={preSaleInformation}
                    logoURL={logoURL}
                    website={website}
                    facebookLink={facebookLink}
                    twitterLink={twitterLink}
                    githubLink={githubLink}
                    telegramLink={telegramLink}
                    instagramLink={instagramLink}
                    discordLink={discordLink}
                    redditLink={redditLink}
                    description={description}

                    tokenPerBNB={tokenPerBNB}
                    minimumBuy={minimumBuy}
                    maximumBuy={maximumBuy}
                    hardCap={hardCap}
                    startTime={startTime}
                    endTime={endTime}
                    isVestingEnable={isVestingEnable}
                    initialTokenReleasePercentage={initialTokenReleasePercentage}
                    teamTokenReleasePercentage={teamTokenReleasePercentage}
                    vestingPeriodInDays={vestingPeriodInDays}
                    publicStartTime={publicStartTime}
                    isWhitelistingEnabled={isWhitelistingEnabled}
                    selectedToken={selectedToken}
                    setSelectedToken={setSelectedToken}
                />
            }
        </div>
    )
}

export default BnbContribution
