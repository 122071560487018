import React from 'react'
import { Link } from 'react-router-dom';
import { Form, Input, Button, Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import {useNavigate} from 'react-router-dom'
import {ArrowLeftCircle} from 'react-feather'

function Login() {

    const navigate = useNavigate();

    const onFinish = (values) => {
        console.log(values)
    }

    const handleBack = () => {
        navigate('/')
    }

        return (
            <div className="container">
              <div className="col-lg-6 col-md-10 col-sm-10 mx-auto mt-5">
                  <div className="p-3">

                    <div className="d-flex">
                        <ArrowLeftCircle className="back-button" onClick={handleBack} /> <h5 style={{marginLeft: '10px'}}>Back</h5>
                    </div>

                    <Card>
                      <h2 className="mt-3 mb-4">Login</h2>
    
                      <div className="container">
                        <Form
                          name="normal_login"
                          initialValues={{
                            remember: true,
                          }}
                          onFinish={onFinish}
                        >
                          <Form.Item
                            name="username"
                            rules={[
                              {
                                required: true,
                                message: 'Please input your username!',
                              },
                            ]}
                          >
                            <Input prefix={<UserOutlined className="icon-white" />} placeholder="Username" />
                          </Form.Item>
                          <Form.Item
                            name="password"
                            rules={[
                              {
                                required: true,
                                message: 'Please input your Password!',
                              },
                            ]}
                          >
                            <Input.Password
                              prefix={<LockOutlined className="icon-white" />}
                              type="password"
                              placeholder="Password"
                            />
                          </Form.Item>
                          <Form.Item>
                            
    
                            <Link className="login-form-forgot" to="/forgotpassword">
                              Forgot password
                            </Link>
                          </Form.Item>
    
                          
                          
                          <Form.Item>
                            <Button htmlType="submit" type="primary" style={{marginRight: '8px'}}>
                              Log in
                            </Button>
                            <span className="text-light">Or</span> <Link to="/register" className="register_link">Register now!</Link>
                          </Form.Item>
                        </Form>
                      </div>
                      </Card>
                  </div>
              </div>
            </div>
        )
}

export default Login
