import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Select } from 'antd'
import './Fundingpad.css'

function Fundingpad() {

    const { Option } = Select;
    const navigate = useNavigate();

    const onChange = (value) => {
        if (value === 'yes') {
            navigate('/fundingpoolcontribution')
        } else if (value === 'no') {
            navigate('/bnbcontribution')
        }
    }

    return (
        <div className="container text-center center-section">

            <h1>Do you have a Contract Address ?</h1>

            <Select
                size="large"
                className="col-8"
                placeholder="Please select an option to proceed"
                onChange={onChange}
            >
                <Option value="yes">Yes</Option>
                <Option value="no">No</Option>
            </Select>

        </div>
    )
}

export default Fundingpad
