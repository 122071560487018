import {
    GlobalOutlined,
    FacebookFilled,
    TwitterOutlined,
    GithubOutlined,
    RedditOutlined,
    InstagramFilled
} from '@ant-design/icons'

import Discord from '../../Images/discord.png'
import Telegram from '../../Images/telegram.png'

export default function SocialLinks(props) {

    const { privateSaleDetails, isPrivateSaleLoading } = props
    return (
        <>
            {
                isPrivateSaleLoading ? (
                    <></>
                ) : (
                    <>
                        {
                            privateSaleDetails?.websiteLink &&
                            <a
                                className="social-links-private-sale-profile"
                                href={privateSaleDetails.websiteLink}
                                target='_blank'
                                rel="noreferrer">
                                <GlobalOutlined size={20} className="" />
                            </a>
                        }
                        {
                            privateSaleDetails?.discordLink &&
                            <a
                                className="social-links-private-sale-profile"
                                href={privateSaleDetails.discordLink}
                                target='_blank'
                                rel="noreferrer">
                                <img src={Discord} style={{ width: '18px' }} alt="discord" />
                            </a>
                        }
                        {
                            privateSaleDetails?.facebookLink &&
                            <a
                                className="social-links-private-sale-profile"
                                href={privateSaleDetails.facebookLink}
                                target='_blank'
                                rel="noreferrer">
                                <FacebookFilled size={20} className="" />
                            </a>
                        }
                        {
                            privateSaleDetails?.githubLink &&
                            <a
                                className="social-links-private-sale-profile"
                                href={privateSaleDetails.githubLink}
                                target='_blank'
                                rel="noreferrer">
                                <GithubOutlined size={20} className="" />
                            </a>
                        }
                        {
                            privateSaleDetails?.instagramLink &&
                            <a
                                className="social-links-private-sale-profile"
                                href={privateSaleDetails.instagramLink}
                                target='_blank'
                                rel="noreferrer">
                                <InstagramFilled size={20} className="" />
                            </a>
                        }
                        {
                            privateSaleDetails?.redditLink &&
                            <a
                                className="social-links-private-sale-profile"
                                href={privateSaleDetails.redditLink}
                                target='_blank'
                                rel="noreferrer">
                                <RedditOutlined size={20} className="" />
                            </a>
                        }
                        {
                            privateSaleDetails?.telegramLink &&
                            <a className="social-links-private-sale-profile"
                                href={privateSaleDetails.telegramLink}
                                target='_blank'
                                rel="noreferrer">
                                <img src={Telegram} style={{ width: '15px' }} alt="telegram" />
                            </a>
                        }
                        {
                            privateSaleDetails?.twitterLink &&
                            <a
                                className="social-links-private-sale-profile"
                                href={privateSaleDetails.twitterLink}
                                target='_blank'
                                rel="noreferrer">
                                <TwitterOutlined size={20} className="" />
                            </a>
                        }
                    </>


                )
            }

        </>
    )
}