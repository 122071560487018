import React, { useEffect } from 'react'
import { Row, Col, Card, Input, Button, Alert, Spin } from 'antd';
import { useWeb3React } from '@web3-react/core';
import useApproveTokenTokenData from '../../../Hooks/useApproveTokenTokenData';
import './ApproveToken.css'

export default function ApproveToken(props) {

  const { setStepNumber, stepNumber, setTokenMetadata, setTokenAddressParent, tokenAddressParent } = props
  const { account } = useWeb3React()
  const { isLoading, tokenDetails, tokenValidationErrors } = useApproveTokenTokenData({ tokenAddress: tokenAddressParent, accountAddress: account })

  useEffect(() => {
    if (tokenDetails && tokenDetails.tokenData) {
      setTokenMetadata(tokenDetails.tokenData)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokenDetails])


  const handleTokenAddressChange = (e) => {
    const token = e.target.value
    if (token) {
      setTokenAddressParent(token)
    } else {
      setTokenAddressParent('')
    }
  }

  const handleNext = () => {
    setStepNumber(stepNumber + 1)
  }

  return (
    <div className='mx-auto mt-5 col-8'>
      <Card className="pt-3 pb-3">

        {
          !account ? (
            <Alert
              message="Authorization Error"
              description="Please connect your wallet to proceed"
              type="warning"
              showIcon
            />
          ) : (
            <Input
              placeholder="Token Address"
              required
              size="large"
              className="col-9 input-background-inside"
              name="tokenAddress"
              value={tokenAddressParent}
              onChange={handleTokenAddressChange}
            />

          )
        }


        {
          isLoading ? (
            <div className='loading-container'>
              <Spin />
            </div>
          ) : (<>
            {
              !tokenValidationErrors ?
                <>
                  <Row className='pt-3'>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className=''>
                      <div className="d-flex justify-content-between align-items-start">
                        <div className="data-info-type ">Token name:</div>
                        <div className="data-info-value"> {tokenDetails?.tokenData.tokenName}</div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className='pt-2'>
                      <div className="d-flex justify-content-between align-items-start ">
                        <div className="data-info-type">Token symbol:</div>
                        <div className="data-info-value"> {tokenDetails?.tokenData.tokenSymbol}</div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className='pt-2'>
                      <div className="d-flex justify-content-between align-items-start ">
                        <div className="data-info-type">Token decimals:</div>
                        <div className="data-info-value"> {tokenDetails?.tokenData.tokenDecimals}</div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className='pt-2'>
                      <div className="d-flex justify-content-between align-items-start ">
                        <div className="data-info-type">Total supply:</div>
                        <div className="data-info-value"> {tokenDetails?.tokenData.tokenTotalSupply}</div>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} className='pt-2'>
                      <div className="d-flex justify-content-between align-items-start ">
                        <div className="data-info-type">Available amount:</div>
                        <div className="data-info-value"> {tokenDetails?.tokenData.tokenCirculationSupply}</div>
                      </div>
                    </Col>
                  </Row>

                  <Row className="d-flex justify-content-end pt-4">
                    <Button type="primary" onClick={handleNext}>Next</Button>
                  </Row>

                </>
                :

                (
                  <div className='validation-error-container mt-2'>
                    <Alert
                      message="Validation Error"

                      description={tokenValidationErrors}
                      type="warning"
                      showIcon
                    />
                  </div>
                )
            }
          </>)

        }
      </Card>
    </div>
  )
}
