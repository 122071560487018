import React, { useEffect, useState } from 'react'
import { Row } from 'reactstrap'
import { Card, Button, notification, Input, Spin, Alert } from 'antd'
import usePoolStatus from '../../../Hooks/usePoolStatus'
import { useWeb3React } from '@web3-react/core'
import {
  getTheOwnerOfThePool,
  getDeployer,
  getTotalContributorsCount,
  executeBNBDistribution,
} from '../../../Blockchain/web3.service'

export default function DistributeBNBWidget(props) {

  const { privateSaleAddress } = props
  const {
    isLoading: isPoolStatusLoading,
    poolStatus
  } = usePoolStatus({ poolAddress: privateSaleAddress })
  const { account, library } = useWeb3React()

  const [startingContributorIndex, setStartingContributorIndex] = useState('')
  const [endingContributorIndex, setEndingContributorIndex] = useState('')
  const [totalContributors, setTotalContributors] = useState(0)
  const [inputValidationMessage, setInputValidationMessage] = useState('')
  const [canProceedDistribution, setCanProceedDistribution] = useState(false)
  const [isBNBDistributionLoading, setIsBNBDistributionLoading] = useState(false)
  const [deployerAddress, setDeployerAddress] = useState('')
  const [poolOwnerAddress, setPoolOwnerAddress] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)

  const fetchTotalInvestorCount = async () => {
    try {
      const totalContributorsResponse = await getTotalContributorsCount(privateSaleAddress)
      setTotalContributors(totalContributorsResponse)
    } catch (error) {
      setTotalContributors(0)
      console.error("ERROR while fetching total contributors count ", error)
    }
  }

  const fetchOwnerAddress = async () => {
    try {
      const deployerAddressResponse = await getDeployer(privateSaleAddress)
      const poolOwnerAddressResponse = await getTheOwnerOfThePool(privateSaleAddress)
      setPoolOwnerAddress(poolOwnerAddressResponse)
      setDeployerAddress(deployerAddressResponse)
    } catch (error) {
      setPoolOwnerAddress('')
      setDeployerAddress('')
      console.error("ERROR while fetching admin addresses", error)
    }
  }

  useEffect(() => {

    if (account?.toLowerCase() === poolOwnerAddress?.toLowerCase() || account?.toLowerCase() === deployerAddress?.toLowerCase()) {
      setIsAdmin(true)
    } else {
      setIsAdmin(false)
    }

  }, [account, poolOwnerAddress, deployerAddress])


  useEffect(() => {
    if (privateSaleAddress) {
      fetchTotalInvestorCount()
      fetchOwnerAddress()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateSaleAddress])

  //validate user input fields 
  useEffect(() => {
    if (parseInt(startingContributorIndex) > parseInt(endingContributorIndex)) {
      setInputValidationMessage("Starting index must grated than ending index")
      setCanProceedDistribution(false)
      return
    }

    if (parseInt(endingContributorIndex) - parseInt(startingContributorIndex) > 50) {
      setInputValidationMessage("System will only allows maximum of 50 contributors in a single cycle")
      setCanProceedDistribution(false)
      return
    }

    setInputValidationMessage("")
    setCanProceedDistribution(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startingContributorIndex, endingContributorIndex])

  const handleDistributeBNB = async () => {
    try {

      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        });
        setIsBNBDistributionLoading(false)
        return
      }

      if (!startingContributorIndex && !endingContributorIndex) {
        notification['error']({
          message: 'Input Error',
          description:
            'Start and Ending index can not be empty',
        });
        setIsBNBDistributionLoading(false)
        return
      }

      setIsBNBDistributionLoading(true)
      const response = await executeBNBDistribution(privateSaleAddress, startingContributorIndex, endingContributorIndex, library.getSigner())
      console.log("response ", response)
      setIsBNBDistributionLoading(false)
      notification['success']({
        message: 'Success',
        description: 'BNB has been distributed.',
      });
    } catch (error) {
      console.error("ERROR while executing BNB distribution ", error)
      setIsBNBDistributionLoading(false)
      notification['error']({
        message: 'Transaction Execution Failed',
        description: error,
      });

    }
  }

  return (
    <>
      {
        isAdmin ? (
          <Card className="mt-4 admin-control-card">
            <Row>
              <div className="text-center">Distribute BNB (Danger Zone)</div>
            </Row>

            {
              isPoolStatusLoading ? (
                <div className='d-flex justify-content-center mt-4'>
                  <Spin></Spin>
                </div>) : (<>
                  <Row className="mt-4">
                    <span className='total-contributors-amount'>
                      Total Contributors : {totalContributors}
                    </span>
                  </Row>

                  <Row className="mt-4">
                    <span className='input-label'>
                      [1] Enter Starting Contributor #
                    </span>
                  </Row>
                  <Row className="" style={{ padding: '0 10px' }}>

                    <Input
                      type="number"
                      value={startingContributorIndex}
                      onChange={e => setStartingContributorIndex(e.target.value)}
                    />
                  </Row>

                  <Row className="mt-1">
                    <span className='input-label'>
                      [2] Enter Ending Contributor #
                    </span>
                  </Row>
                  <Row className="" style={{ padding: '0 10px' }}>

                    <Input
                      type="number"
                      value={endingContributorIndex}
                      onChange={e => setEndingContributorIndex(e.target.value)}
                    />
                  </Row>

                  {
                    inputValidationMessage ? (
                      <Row className='mt-2 distribute-bnb-btn' style={{ padding: '0 10px' }}>
                        <Alert
                          message="Error"
                          description={inputValidationMessage}
                          type="error"
                          showIcon
                        />
                      </Row>
                    ) : (
                      <></>
                    )
                  }

                  <Row className='mt-2 distribute-bnb-btn' style={{ padding: '0 10px' }}>
                    <Button
                      disabled={!canProceedDistribution || poolStatus.statusCode !== 'cancel'}
                      onClick={handleDistributeBNB}
                      loading={isBNBDistributionLoading}
                      type='primary'
                      block={true}
                    >
                      Distribute BNB
                    </Button>
                  </Row>
                </>)
            }

          </Card>
        ) : (<></>)
      }
    </>
  )
}