import React from 'react'
import { Row, Col } from 'reactstrap'
import { Progress, Spin } from 'antd'
import NumberFormat from 'react-number-format'
import usePoolProgressDetails from '../../Hooks/usePoolProgressDetails'

export default function ProgressBarForWidget(props) {
  const { poolAddress, poolDetails } = props
  const { isLoading: isPoolProgressDetailsLoading, poolProgressDetails } = usePoolProgressDetails({ poolAddress })

  return (
    <div className='progress-bar-container mt-3'>

      {
        isPoolProgressDetailsLoading ? (
          <div className='text-center'>
            <Spin size="small" />
          </div>
        ) : (
          <div>
            <span className="mt-3 text-muted">Progress (  <NumberFormat
              value={poolProgressDetails ? poolProgressDetails.progressPercentage : 0.0}
              displayType={'text'}
              decimalScale={2}
              suffix=" %"
            />

              )</span> <br />

            <span className="mt-3 text-muted">
              <NumberFormat
                value={poolProgressDetails ? poolProgressDetails.bnbFilledSoFar : 0.0}
                displayType={'text'}
                decimalScale={4}
                suffix={' ' + poolDetails?.customCurrencySymbol}
              /> filled so far
            </span>
            <Progress
              percent={poolProgressDetails ? poolProgressDetails.progressPercentage : 0.0}
              showInfo={false}
              strokeColor={{
                from: '#d3bc84',
                to: '#d3bc84',
              }}
            />

            <Row className="text-muted">
              <Col>
                0.0 {poolDetails?.customCurrencySymbol}
              </Col>

              <Col className="text-end">
                {poolProgressDetails ? poolProgressDetails.hardCap : 0.0} {poolDetails?.customCurrencySymbol}
              </Col>
            </Row>
          </div>
        )
      }

    </div>
  )
}
