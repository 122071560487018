/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import * as web3Service from '../Blockchain/web3.service'
export const useApproveTokenTokenData = (props) => {

  const { tokenAddress, accountAddress } = props

  const [tokenValidationErrors, setTokenValidationErrors] = useState(null)
  const [tokenDetails, setTokenDetails] = useState(null)
  const [isLoading, setLoading] = useState(false)


  const fetchTokenApprovalData = async () => {
    try {
      setLoading(true)
      const approveTokenResponse = await web3Service.getTokenApprovalData(tokenAddress, accountAddress)

      if (approveTokenResponse) {
        const errors = approveTokenResponse.errors
        setTokenDetails(approveTokenResponse)
        if (errors.isErrors) {
          setTokenValidationErrors(errors.errorMessage)
        } else {
          setTokenValidationErrors(null)
        }
      } else {
        setTokenValidationErrors(null)
        setTokenDetails(null)
      }
      setLoading(false)
    } catch (error) {
      console.error("ERROR HOOK : while fetching token approval data ", error)
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchTokenApprovalData()
  }, [tokenAddress, accountAddress])
  return { tokenValidationErrors, tokenDetails, isLoading }
}


export default useApproveTokenTokenData

