import React, { useState, useEffect } from 'react'
import {
  Row,
  Col, Card,
  Input,
  Button,
  Form,
  DatePicker,
  Checkbox,
  message
} from 'antd';
import './ApproveToken.css'
import moment from 'moment'
// import { DateTime } from 'luxon';

export default function PreSalesInfo(props) {

  const {
    privateSaleName,
    setPrivateSaleName,
    setStepNumber,
    stepNumber,
    minimumBuy,
    setMinimumBuy,
    maximumBuy,
    setMaximumBuy,
    hardCap,
    setHardCap,
    startTime,
    setStartTime,
    setEndTime,
    endTime,
    initialTokenReleasePercentage,
    teamTokenReleasePercentage,
    vestingPeriodInDays,
    setIsNextButtonActive,
    isNextButtonActive,
    isWhitelistingEnabled,
    setIsWhitelistingEnabled,
    publicStartTime,
    setPublicStartTime,
    selectedToken
  } = props


  const [isMinBuyLarge, setIsMinBuyLarge] = useState(false)
  const [endTimeError, setEndTimeError] = useState(false)
  const [publicTimeError, setPublicTimeError] = useState(false)

  // const [isPublicStartTimeValid, setIsPublicStartTimeValid] = useState(true)

  // validation related data
  const [start, setStart] = useState(null)
  const [end, setEnd] = useState(null)

  useEffect(() => {
    if (parseFloat(minimumBuy) >= parseFloat(maximumBuy)) {
      setIsMinBuyLarge(true)
      setIsNextButtonActive(false)
    } else {
      setIsMinBuyLarge(false)
      setIsNextButtonActive(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maximumBuy, minimumBuy])

  useEffect(() => {
    if (!isWhitelistingEnabled) {
      setPublicTimeError(false)
      setPublicStartTime(null)
    } 
  }, [isWhitelistingEnabled, setPublicStartTime])

  const handleBack = () => {
    setStepNumber(stepNumber - 1)
  }


  const onChangeStartDate = (value, dateString) => {
    setStart(dateString)
    setStartTime(dateString)
  }

  const onChangeEndDate = (value, dateString) => {
    setEnd(dateString)
    setEndTime(dateString)
    validateStartAndEnd(dateString)
  }

  const onFinish = () => {
    setStepNumber(stepNumber + 1)
  };

  const onChangePublicStartTime = (value, dateString) => {
    setPublicStartTime(dateString)
    //validatePublicStartTime()
    validateEndTimeWithPublicStartTime(dateString)
  }


  // const validatePublicStartTime = () => {
  //   if (startTime && publicStartTime) {
  //     const _publicStartTimeTS = DateTime.fromFormat(publicStartTime, "yyyy-MM-dd HH:mm:ss", { zone: "utc" }).toSeconds()
  //     const _startTimeTS = DateTime.fromFormat(startTime, "yyyy-MM-dd HH:mm:ss", { zone: "utc" }).toSeconds()

  //     if (parseInt(_startTimeTS) > parseInt(_publicStartTimeTS)) {
  //       setIsPublicStartTimeValid(false)
  //     } else {
  //       setIsPublicStartTimeValid(true)
  //     }
  //   }
  // }

  const validateStartAndEnd = (end) => {
    if (moment(end).isBefore(start)) {
      message.error('Invalid end time!')
      setEndTimeError(true)
    } else {
      setEndTimeError(false)
    }
  }

  const validateEndTimeWithPublicStartTime = (publicstarttime) => {
    if (moment(publicstarttime).isAfter(end) || moment(publicstarttime).isBefore(start)) {
      message.error('Invalid public sale start time!')
      setPublicTimeError(true)
    } else {
      setPublicTimeError(false)
    }
  }


  return (
    <div className="mx-auto mt-5 col-11">
      <Card className="pt-3 pb-3">
        <Form
          name="step_two"
          onFinish={onFinish}
          initialValues={{
            privateSaleName: privateSaleName,
            minimumBuy: minimumBuy,
            maximumBuy: maximumBuy,
            hardCap: hardCap,
            startTime: startTime ? moment(startTime, "yyyy-MM-DD HH:mm:ss") : null,
            endTime: endTime ? moment(endTime, "yyyy-MM-DD HH:mm:ss") : null,
            initialTokenReleasePercentage: initialTokenReleasePercentage,
            vestingPeriodInDays: vestingPeriodInDays,
            teamTokenReleasePercentage: teamTokenReleasePercentage,
            publicStartTime: publicStartTime ? moment(publicStartTime, "yyyy-MM-DD HH:mm:ss") : null,
          }}
        >
          <Row className='pt-2'>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <span className="text-muted">Project Name</span>
              <div className='right-padding-ignore'>
                <Form.Item
                  value={privateSaleName}
                  name="privateSaleName"
                  rules={[
                    {
                      required: true,
                      message: 'This is a required field!',
                    },
                  ]}
                >
                  <div>
                    <Input
                      className="input-background-inside-form"
                      value={privateSaleName}
                      onChange={e => setPrivateSaleName(e.target.value)}
                    />
                  </div>

                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mt-3">
              <span className="text-muted">Minimum Buy ({selectedToken.tokenName})</span>
              <div className='right-padding'>
                <Form.Item
                  value={minimumBuy}
                  onChange={e => setMinimumBuy(e.target.value)}
                  className="input-background-inside-form"
                  name="minimumBuy"
                  rules={[
                    {
                      required: true,
                      message: 'This is a required field!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <div className='remove-margin-top'>
                  {
                    isMinBuyLarge ? (
                      <span className="text-validation-error">Min buy must be less than max buy</span>
                    ) : (
                      <></>
                    )
                  }
                </div>
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mt-3">
              <span className="text-muted">Maximum Buy ({selectedToken.tokenName})</span>
              <Form.Item
                className="input-background-inside-form"
                name="maximumBuy"
                onChange={e => setMaximumBuy(e.target.value)}
                rules={[
                  {
                    required: true,
                    message: 'This is a required field!',
                  },
                ]}
                value={maximumBuy}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className='right-padding'>
                <span className="text-muted">Hard Cap ({selectedToken.tokenName})</span>
                <Form.Item
                  className="input-background-inside-form"
                  name="hardCap"
                  value={hardCap}
                  onChange={e => setHardCap(e.target.value)}
                  rules={[
                    {
                      required: true,
                      message: 'This is a required field!',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className='right-padding'>
                <span className="text-muted">Start time (UTC {moment().utc().format('yyyy-MM-DD HH:mm:ss').toString()} Now)</span><br />
                <Form.Item
                  name="startTime"
                  rules={[
                    {
                      required: true,
                      message: 'This is a required field!',
                    },
                  ]}
                >
                  <DatePicker
                    className="input-background-inside-form col-12"
                    //format="YYYY-MM-DD HH:mm:ss"
                    format={date => date.utc().format('YYYY-MM-DD HH:mm:ss')}
                    onChange={onChangeStartDate}
                    disabledDate={d => !d || d.isBefore(moment().utc().format('yyyy-MM-DD').toString())}
                    showTime
                  />
                </Form.Item>
              </div>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <span className="text-muted">End time (UTC)</span><br />
              <Form.Item
                name="endTime"
                rules={[
                  {
                    required: true,
                    message: 'This is a required field!',
                  },
                ]}
              >
                <DatePicker
                  className="input-background-inside-form col-12"
                  onChange={onChangeEndDate}
                  disabledDate={d => !d || d.isBefore(startTime)}
                  //format="YYYY-MM-DD HH:mm:ss"
                  format={date => date.utc().format('YYYY-MM-DD HH:mm:ss')}
                  showTime
                  disabled={!startTime}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Checkbox
                checked={isWhitelistingEnabled}
                onChange={e => setIsWhitelistingEnabled(e.target.checked)}
                className="input-background-inside-form mt-4">
                Enable Whitelisting Feature
              </Checkbox>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div className='right-padding mt-2'>
                <span className="text-muted">Public Sale Start Time (UTC)</span><br />
                <Form.Item
                  name="publicStartTime"
                  rules={[
                    {
                      required: isWhitelistingEnabled ? true : false,
                      message: 'This is a required field!',
                    },
                  ]}
                >
                  <DatePicker
                    className="input-background-inside-form col-12"
                    //format="YYYY-MM-DD HH:mm:ss"
                    format={date => date.utc().format('YYYY-MM-DD HH:mm:ss')}
                    onChange={onChangePublicStartTime}
                    disabledDate={d => !d || d.isBefore(moment().utc().format('yyyy-MM-DD').toString())}
                    disabled={!isWhitelistingEnabled}
                    showTime
                  />
                </Form.Item>
                {/* <div className='remove-margin-top'>
                  {
                    !isPublicStartTimeValid ? (
                      <span className="text-validation-error">Public sale should start after the pool started</span>
                    ) : (
                      <></>
                    )
                  }
                </div> */}
              </div>
            </Col>
          </Row>

          <Row className="d-flex justify-content-end mt-4">
            <Form.Item>
              <Button type="default" onClick={handleBack} style={{ marginRight: '8px' }}>
                Back
              </Button>
              <Button type="primary" htmlType="submit" disabled={!isNextButtonActive || endTimeError || publicTimeError}>
                Next
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Card>
    </div >
  )
}
