import { getChainNetworkByChainId } from '../Blockchain/utils/chainList'
import { ethers, utils } from 'ethers'
import { configs } from './web3.config'
import { DateTime } from 'luxon'

export const getWeb3PRovider = () => {
  const defaultChainId = configs.chainId
  const web3configs = getChainNetworkByChainId(defaultChainId)
  //initiate the web3 instance
  const web3 = new ethers.providers.JsonRpcProvider(web3configs.rpcUrls[0], {
    name: web3configs.chainName, chainId: parseInt(web3configs.chainId, 16)
  })
  return web3
}


export const getTokenApprovalData = async (tokenAddress, accountAddress) => {
  let tokenName = ''
  let tokenSymbol = ''
  let tokenDecimals = ''
  let tokenTotalSupply = 0
  let tokenCirculationSupply = 0
  try {
    const provider = getWeb3PRovider()
    if (tokenAddress && accountAddress) {
      const eRC20ContractABI = JSON.parse(configs.commonContractABIS.bsc.commonERC20ContractABI)
      const contractInstance = new ethers.Contract(tokenAddress, eRC20ContractABI, provider)
      const balanceOfAddress = await contractInstance.balanceOf(accountAddress)
      const balanceInInt = parseInt(balanceOfAddress.toString())

      if (balanceInInt <= 0) {
        return {
          errors: {
            isErrors: true,
            errorMessage: 'You don\'t have enough token balance to proceed'
          },
          tokenData: {
            tokenAddress,
            tokenName,
            tokenSymbol,
            tokenDecimals,
            tokenTotalSupply,
            tokenCirculationSupply
          }
        }
      } else {

        //fetch the token data
        const _tokenName = await contractInstance.name()
        tokenName = _tokenName.toString()
        const _tokenSymbol = await contractInstance.symbol()
        tokenSymbol = _tokenSymbol.toString()
        const _tokenDecimals = await contractInstance.decimals()
        tokenDecimals = parseInt(_tokenDecimals.toString())
        const _tokenTotalSupply = await contractInstance.totalSupply()
        const totalSupplyInInt = parseInt(_tokenTotalSupply.toString())
        tokenTotalSupply = totalSupplyInInt / (10 ** tokenDecimals)

        //get the circulation supply
        tokenCirculationSupply = await getTokenCirculationSupplyByContractAddress(tokenAddress)

        return {
          errors: {
            isErrors: false,
            errorMessage: ''
          },
          tokenData: {
            tokenAddress,
            tokenName,
            tokenSymbol,
            tokenDecimals,
            tokenTotalSupply,
            tokenCirculationSupply
          }
        }
      }
    } else {
      return {
        errors: {
          isErrors: true,
          errorMessage: 'invalid user address or contract address'
        },
        tokenData: {
          tokenAddress,
          tokenName,
          tokenSymbol,
          tokenDecimals,
          tokenTotalSupply,
          tokenCirculationSupply
        }
      }
    }
  } catch (error) {
    console.error("ERROR while fetching native balance of an address ", error)
    return {
      errors: {
        isErrors: true,
        errorMessage: 'Something went wrong while calculating token data'
      },
      tokenData: {
        tokenAddress,
        tokenName,
        tokenSymbol,
        tokenDecimals,
        tokenTotalSupply,
        tokenCirculationSupply
      }
    }
  }
}


export const getTokenCirculationSupplyByContractAddress = async (contractAddress) => {

  let circulationSupply = 0
  try {
    if (contractAddress) {
      const provider = getWeb3PRovider()
      const eRC20ContractABI = JSON.parse(configs.commonContractABIS.bsc.commonERC20ContractABI)
      const contractInstance = new ethers.Contract(contractAddress, eRC20ContractABI, provider)
      const decimals = await contractInstance.decimals()
      const decimalsInInt = parseInt(decimals.toString())
      const totalSupply = await contractInstance.totalSupply()
      const totalSupplyInInt = parseInt(totalSupply.toString())
      const deadAddressFrom1 = '0x000000000000000000000000000000000000dEaD'
      const zeroAddress = '0x0000000000000000000000000000000000000000'


      const deadAddressFrom1Amount = await contractInstance.balanceOf(deadAddressFrom1)
      const zeroAddressAmount = await contractInstance.balanceOf(zeroAddress)

      const totalTokenWithoutCirculation = parseInt(deadAddressFrom1Amount.toString()) + parseInt(zeroAddressAmount.toString())
      const circulationSupplyUnformatted = totalSupplyInInt - totalTokenWithoutCirculation
      circulationSupply = circulationSupplyUnformatted / (10 ** decimalsInInt)
      return circulationSupply
    } else {
      return circulationSupply
    }

  } catch (error) {
    console.error("ERROR while fetching token circulation supply : ", error)
    return circulationSupply
  }
}

export const getTokenFeePercentageInPrivateSale = async () => {
  let tokenFeePercentage = 0.00
  try {
    const provider = getWeb3PRovider()
    const privateSaleContractABI = JSON.parse(configs.privateSaleContract.contractABI)
    const privateSaleContractAddress = configs.privateSaleContract.contractAddress
    const privateSaleContractInstance = new ethers.Contract(privateSaleContractAddress, privateSaleContractABI, provider)
    const tokenFee = await privateSaleContractInstance.tokenFeePercentage()
    const tokenFeeInInt = parseInt(tokenFee.toString())
    tokenFeePercentage = tokenFeeInInt / 100
    return tokenFeePercentage

  } catch (error) {
    console.error("ERROR while fetching private sale token fee percentage : ", error)
    return tokenFeePercentage
  }
}

export const getPrivatePoolCreationFeeInPrivateSale = async () => {
  try {
    const provider = getWeb3PRovider()
    const privateSaleContractABI = JSON.parse(configs.privateSaleContract.contractABI)
    const privateSaleContractAddress = configs.privateSaleContract.contractAddress
    const privateSaleContractInstance = new ethers.Contract(privateSaleContractAddress, privateSaleContractABI, provider)
    const feeAmountInWei = await privateSaleContractInstance.feeAmount()
    const feeAmountInWeiInt = parseInt(feeAmountInWei.toString())
    return feeAmountInWeiInt

  } catch (error) {
    console.error("ERROR while fetching private sale creation fee : ", error)
    return 0
  }
}

export const createNewPrivateSalePool = async (
  tokenAddress,
  pooDetails,
  vestingDetails,
  isWhiteListingEnabled,
  publicStartTime,
  poolCreationFee,
  signer
) => {

  try {
    const provider = getWeb3PRovider()
    const privateSaleContractABI = JSON.parse(configs.privateSaleContract.contractABI)
    const privateSaleContractAddress = configs.privateSaleContract.contractAddress
    const privateSaleContractInstance = new ethers.Contract(
      privateSaleContractAddress,
      privateSaleContractABI,
      provider
    )
    const privateSaleContractInstanceWithSigner = privateSaleContractInstance.connect(signer)
    const poolCreationFeeResponse = await privateSaleContractInstanceWithSigner.feeAmount()
    const actualFeeAmount = poolCreationFeeResponse.toString()
    const newPoolCreateReceipt = await privateSaleContractInstanceWithSigner.createNewPool(
      tokenAddress,
      pooDetails,
      vestingDetails,
      isWhiteListingEnabled,
      publicStartTime,
      { value: actualFeeAmount }
    )

    const result = await newPoolCreateReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to create new private sale pool. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const createNewPrivateSalePoolWithBEP20Token = async (
  tokenAddress,
  pooDetails,
  vestingDetails,
  isWhiteListingEnabled,
  publicStartTime,
  poolCreationFee,
  selectedTokenAddress,
  signer
) => {

  try {
    const provider = getWeb3PRovider()
    const privateSaleContractABI = JSON.parse(configs.privateSaleContract.contractWithTokenNonBNBABI)
    const privateSaleContractAddress = configs.privateSaleContract.contractAddressWithTokenNonBNB
    const privateSaleContractInstance = new ethers.Contract(
      privateSaleContractAddress,
      privateSaleContractABI,
      provider
    )
    const privateSaleContractInstanceWithSigner = privateSaleContractInstance.connect(signer)

    const poolCreationFeeResponse = await privateSaleContractInstanceWithSigner.feeAmount()
    const actualFeeAmount = poolCreationFeeResponse.toString()
    const newPoolCreateReceipt = await privateSaleContractInstanceWithSigner.createNewPool(
      tokenAddress,
      pooDetails,
      vestingDetails,
      isWhiteListingEnabled,
      publicStartTime,
      selectedTokenAddress,
      { value: actualFeeAmount }
    )

    const result = await newPoolCreateReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to create new private sale pool. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const approveToken = async (
  tokenAddress,
  tokenAmount,
  signer
) => {

  try {
    const provider = getWeb3PRovider()
    const erc20TokenABI = JSON.parse(configs.commonContractABIS.bsc.commonERC20ContractABI)
    const erc20ContractInstance = new ethers.Contract(
      tokenAddress,
      erc20TokenABI,
      provider
    )
    const spender = configs.privateSaleContract.contractAddress
    const erc20ContractInstanceWithSigner = erc20ContractInstance.connect(signer)

    const approveTokenReceipt = await erc20ContractInstanceWithSigner.approve(spender, tokenAmount)
    const result = await approveTokenReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to approve the token. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const approveTokenForNonBNB = async (
  tokenAddress,
  tokenAmount,
  signer
) => {

  try {
    const provider = getWeb3PRovider()
    const erc20TokenABI = JSON.parse(configs.commonContractABIS.bsc.commonERC20ContractABI)
    const erc20ContractInstance = new ethers.Contract(
      tokenAddress,
      erc20TokenABI,
      provider
    )
    const spender = configs.privateSaleContract.contractAddressWithTokenNonBNB
    const erc20ContractInstanceWithSigner = erc20ContractInstance.connect(signer)

    //TODO : might give an error if you don't have sufficient token balance in your wallet
    const approveTokenReceipt = await erc20ContractInstanceWithSigner.approve(spender, tokenAmount)
    const result = await approveTokenReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to approve the token. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const approveCustomCurrencyToken = async (
  poolAddress,
  customCurrencyAddress,
  tokenAmount,
  signer
) => {

  try {
    const provider = getWeb3PRovider()
    const erc20TokenABI = JSON.parse(configs.commonContractABIS.bsc.commonERC20ContractABI)
    const erc20ContractInstance = new ethers.Contract(
      customCurrencyAddress,
      erc20TokenABI,
      provider
    )
    const spender = poolAddress
    const erc20ContractInstanceWithSigner = erc20ContractInstance.connect(signer)
    const approveTokenReceipt = await erc20ContractInstanceWithSigner.approve(spender, tokenAmount)
    const result = await approveTokenReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to approve custom currency token. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const getPoolStatus = async (poolAddress) => {

  let statusCode = ''
  let statusName = ''
  let statusColor = ''
  let canContribute = false
  let message = ''
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)

    const poolStatusResponse = await privateSalePoolContractInstance.poolStatus()
    const poolStatus = parseInt(poolStatusResponse.toString())

    const startTimeResponse = await privateSalePoolContractInstance.startTime()
    const startTime = parseInt(startTimeResponse.toString())

    const endTimeResponse = await privateSalePoolContractInstance.endTime()
    const endTime = parseInt(endTimeResponse.toString())

    const hardCapResponse = await privateSalePoolContractInstance.hardCap()
    const hardCap = parseInt(hardCapResponse.toString())

    const filledBNBResponse = await privateSalePoolContractInstance.filledBNB()
    const filledBNB = parseInt(filledBNBResponse.toString())

    if (poolStatus === 4) {
      statusCode = 'finalized'
      statusName = 'Finalized'
      statusColor = 'orange'
      canContribute = false
      message = 'This pool has been finalized'
      return {
        statusCode,
        statusName,
        statusColor,
        canContribute,
        message,
        poolStatus
      }
    }

    if (poolStatus === 3) {
      statusCode = 'canceled'
      statusName = 'Canceled'
      statusColor = 'grey'
      canContribute = false
      message = 'This pool has been canceled'
      return {
        statusCode,
        statusName,
        statusColor,
        canContribute,
        message,
        poolStatus
      }
    }

    //get the current timestamp in seconds
    const currentUTC = parseInt(DateTime.now().toSeconds())
    if (currentUTC >= startTime && filledBNB < hardCap && currentUTC <= endTime) {
      statusCode = 'live'
      statusName = 'Ongoing'
      statusColor = 'green'
      canContribute = true
      message = ''
      return {
        statusCode,
        statusName,
        statusColor,
        canContribute,
        message,
        poolStatus
      }
    }

    if (currentUTC >= startTime && filledBNB === hardCap) {
      statusCode = 'filled'
      statusName = 'Filled'
      statusColor = 'green'
      canContribute = false
      message = 'This pool has been filled'
      return {
        statusCode,
        statusName,
        statusColor,
        canContribute,
        message,
        poolStatus
      }
    }

    if (currentUTC >= endTime) {
      statusCode = 'ended'
      statusName = 'Sale Ended'
      statusColor = 'red'
      message = 'This pool has been ended'
      return {
        statusCode,
        statusName,
        statusColor,
        message,
        poolStatus
      }
    }

    if (currentUTC < startTime) {
      statusCode = 'upcoming'
      statusName = 'Upcoming'
      statusColor = 'orange'
      canContribute = true
      message = 'This pool is not started yet'
      return {
        statusCode,
        statusName,
        statusColor,
        canContribute,
        message,
        poolStatus
      }
    }
  } catch (error) {
    console.error("ERROR while fetching token pool status : ", error)
    throw new Error(error)
  }
}

export const getPoolProgressDetails = async (poolAddress) => {

  let minContributionAmount = 0.0
  let maxContributionAmount = 0.0
  let hardCap = 0.0
  let bnbFilledSoFar = 0.0
  let progressPercentage = 0.0

  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)

    const hardCapResponse = await privateSalePoolContractInstance.hardCap()
    hardCap = parseFloat(utils.formatEther(hardCapResponse.toString()))

    const filledBNBResponse = await privateSalePoolContractInstance.filledBNB()
    bnbFilledSoFar = parseFloat(utils.formatEther(filledBNBResponse.toString()))

    const minimumContributionResponse = await privateSalePoolContractInstance.minimumParticipate()
    minContributionAmount = parseFloat(utils.formatEther(minimumContributionResponse.toString()))

    const maximumContributionResponse = await privateSalePoolContractInstance.maximumParticipate()
    maxContributionAmount = parseFloat(utils.formatEther(maximumContributionResponse.toString()))
    progressPercentage = (bnbFilledSoFar / hardCap) * 100

    return {
      minContributionAmount,
      maxContributionAmount,
      hardCap,
      bnbFilledSoFar,
      progressPercentage
    }

  } catch (error) {
    console.error("ERROR while fetching token pool status : ", error)
    return {
      minContributionAmount,
      maxContributionAmount,
      hardCap,
      bnbFilledSoFar,
      progressPercentage
    }
  }
}

export const contributeToPrivateSale = async (
  poolAddress,
  contributionAmountInBNB,
  signer
) => {
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
    const privateSalePoolContractInstanceWithSigner = privateSalePoolContractInstance.connect(signer)
    const contributionReceipt = await privateSalePoolContractInstanceWithSigner.participateToSale({ value: contributionAmountInBNB })
    const result = await contributionReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to contribute to the pool. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const contributeToPrivateSaleWithCustomCurrency = async (
  poolAddress,
  contributionAmountInBNB,
  signer
) => {
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABINonBNB)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
    const privateSalePoolContractInstanceWithSigner = privateSalePoolContractInstance.connect(signer)
    const contributionReceipt = await privateSalePoolContractInstanceWithSigner.participateToSale(contributionAmountInBNB.toString())
    const result = await contributionReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to contribute to the pool. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const claimAndExitFromPool = async (poolAddress, signer) => {
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
    const privateSalePoolContractInstanceWithSigner = privateSalePoolContractInstance.connect(signer)
    const claimYourBNBReceipt = await privateSalePoolContractInstanceWithSigner.claimBnb()
    const result = await claimYourBNBReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to claim your invested BNB. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const claimTokensFromPool = async (poolAddress, signer) => {
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
    const privateSalePoolContractInstanceWithSigner = privateSalePoolContractInstance.connect(signer)
    const claimYourTokensReceipt = await privateSalePoolContractInstanceWithSigner.claimTokens()
    const result = await claimYourTokensReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to claim your tokens. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const claimVestedTokensFromPool = async (poolAddress, signer) => {
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
    const privateSalePoolContractInstanceWithSigner = privateSalePoolContractInstance.connect(signer)
    const claimYourVestedTokensReceipt = await privateSalePoolContractInstanceWithSigner.claimVesting()
    const result = await claimYourVestedTokensReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to claim your vesting tokens. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const getTotalContributorsCount = async (poolAddress) => {
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
    const totalContributors = await privateSalePoolContractInstance.investorCount()
    return parseInt(totalContributors.toString())

  } catch (error) {
    console.error("ERROR while fetching total contributors count  : ", error)
    return 0
  }
}

export const getUserContributionDetails = async (poolAddress, walletAddress, isWithoutToken) => {
  let myContributionInBNB = 0.0
  let userTokenReserved = null
  let isClaimed = false
  try {

    if (poolAddress && walletAddress) {
      const provider = getWeb3PRovider()

      let privateSalePoolContractABI = null
      if (isWithoutToken) {
        privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABIWithoutToken)
      } else {
        privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
      }
      const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
      const userContributionDetails = await privateSalePoolContractInstance.shares(walletAddress)

      const myContributionInWei = userContributionDetails[0].toString()
      myContributionInBNB = utils.formatEther(myContributionInWei)
      //get the token address and token decimals if eligible
      const tokenAddress = await privateSalePoolContractInstance.tokenAddress()

      isClaimed = userContributionDetails[3]

      if (tokenAddress && tokenAddress.includes("0x0000000000")) {
        userTokenReserved = null
      } else {
        const erc20ContractABI = JSON.parse(configs.commonContractABIS.bsc.commonERC20ContractABI)
        const erc20ContractInstance = new ethers.Contract(tokenAddress.toString(), erc20ContractABI, provider)

        const tokenDecimalResponse = await erc20ContractInstance.decimals()
        const tokenDecimals = parseInt(tokenDecimalResponse.toString())

        const userTokensReserved = userContributionDetails[1].toString()
        userTokenReserved = parseInt(userTokensReserved) / (10 ** tokenDecimals)
      }
      return {
        myContributionInBNB,
        userTokenReserved,
        isClaimed
      }
    }



  } catch (error) {
    console.error("ERROR while fetching user contribution details  : ", error)
    return {
      myContributionInBNB,
      userTokenReserved
    }
  }
}

export const getMinAndMaxContributionAmount = async (poolAddress) => {
  let minContributionAmount = 0.0
  let maxContributionAmount = 0.0
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)

    const minimumContributionResponse = await privateSalePoolContractInstance.minimumParticipate()
    minContributionAmount = parseFloat(utils.formatEther(minimumContributionResponse.toString()))

    const maximumContributionResponse = await privateSalePoolContractInstance.maximumParticipate()
    maxContributionAmount = parseFloat(utils.formatEther(maximumContributionResponse.toString()))

    return {
      minContributionAmount,
      maxContributionAmount
    }

  } catch (error) {
    console.error("ERROR while fetching min max contribution amount  : ", error)
    return {
      minContributionAmount,
      maxContributionAmount
    }
  }
}

export const getTokensPerBNBInPool = async (poolAddress) => {
  try {
    if (poolAddress) {
      const provider = getWeb3PRovider()
      const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
      const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
      const tokensPerBNB = await privateSalePoolContractInstance.tokensPerbnb()
      //get the token address of the pool
      const tokenAddress = await privateSalePoolContractInstance.tokenAddress()
      const erc20ContractABI = JSON.parse(configs.commonContractABIS.bsc.commonERC20ContractABI)
      const erc20ContractInstance = new ethers.Contract(tokenAddress.toString(), erc20ContractABI, provider)

      const tokenDecimalResponse = await erc20ContractInstance.decimals()
      const tokenDecimals = parseInt(tokenDecimalResponse.toString())

      return tokensPerBNB / (10 ** tokenDecimals)
    }

  } catch (error) {
    console.error("ERROR while fetching tokens per bnb amount  : ", error)
    return 0
  }
}

export const getPoolAddresses = async (poolAddress) => {

  let poolOwner = null
  let tokenAddress = null
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)

    poolOwner = await privateSalePoolContractInstance.poolOwner()
    tokenAddress = await privateSalePoolContractInstance.tokenAddress()

    return {
      poolOwner,
      tokenAddress
    }

  } catch (error) {
    console.error("ERROR while fetching pool addresses  : ", error)
    return {
      poolOwner,
      tokenAddress
    }
  }
}

export const getTheOwnerOfThePool = async (poolAddress) => {
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)

    const owner = await privateSalePoolContractInstance.poolOwner()
    return owner
  } catch (error) {
    console.error("ERROR while fetching pool owner  : ", error)
    return null
  }
}


export const getPrivatePoolCreationFeeInPrivateSaleNonToken = async () => {
  try {
    const provider = getWeb3PRovider()
    const privateSaleContractABI = JSON.parse(configs.privateSaleContract.contractABINonToken)
    const privateSaleContractAddress = configs.privateSaleContract.contractForNonToken
    const privateSaleContractInstance = new ethers.Contract(privateSaleContractAddress, privateSaleContractABI, provider)
    const feeAmountInWei = await privateSaleContractInstance.feeAmount()
    const feeAmountInWeiInt = parseInt(feeAmountInWei.toString())
    return feeAmountInWeiInt

  } catch (error) {
    console.error("ERROR while fetching private sale creation fee : ", error)
    return 0
  }
}

export const createNewPrivateSalePoolForNonToken = async (
  startTime,
  endTime,
  hardCap,
  minimumBuy,
  maximumBuy,
  isWhiteListingEnabled,
  publicSaleStartTimestamp,
  poolCreationFee,
  signer
) => {

  try {
    const provider = getWeb3PRovider()
    const privateSaleContractABI = JSON.parse(configs.privateSaleContract.contractABINonToken)
    const privateSaleContractAddress = configs.privateSaleContract.contractForNonToken
    const privateSaleContractInstance = new ethers.Contract(
      privateSaleContractAddress,
      privateSaleContractABI,
      provider
    )
    const privateSaleContractInstanceWithSigner = privateSaleContractInstance.connect(signer)
    const poolCreationFeeResponse = await privateSaleContractInstanceWithSigner.feeAmount()
    const actualFeeAmount = poolCreationFeeResponse.toString()
    const newPoolCreateReceipt = await privateSaleContractInstanceWithSigner.createNewPool(
      startTime,
      endTime,
      hardCap,
      minimumBuy,
      maximumBuy,
      isWhiteListingEnabled,
      publicSaleStartTimestamp,
      { value: actualFeeAmount }
    )

    const result = await newPoolCreateReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to create new private sale pool. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const createNewPrivateSalePoolForNonTokenWithCustomCurrency = async (
  poolDetails,
  isWhiteListingEnabled,
  publicSaleStartTimestamp,
  customCurrencyToken,
  poolCreationFee,
  signer
) => {

  try {
    const provider = getWeb3PRovider()
    const privateSaleContractABI = JSON.parse(configs.privateSaleContract.contractWithoutTokenNonBNBABI)
    const privateSaleContractAddress = configs.privateSaleContract.contractAddressWithoutTokenNonBNB
    const privateSaleContractInstance = new ethers.Contract(
      privateSaleContractAddress,
      privateSaleContractABI,
      provider
    )
    const privateSaleContractInstanceWithSigner = privateSaleContractInstance.connect(signer)
    const poolCreationFeeResponse = await privateSaleContractInstanceWithSigner.feeAmount()
    const actualFeeAmount = poolCreationFeeResponse.toString()
    const newPoolCreateReceipt = await privateSaleContractInstanceWithSigner.createNewPool(
      poolDetails,
      isWhiteListingEnabled,
      publicSaleStartTimestamp,
      customCurrencyToken,
      { value: actualFeeAmount }
    )

    const result = await newPoolCreateReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to create new private sale pool with custom currency token. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}


export const checkPoolTokenAddress = async (poolAddress) => {

  let poolOwner = null
  let tokenAddress = null
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)

    poolOwner = await privateSalePoolContractInstance.owner()
    tokenAddress = await privateSalePoolContractInstance.tokenAddress()

    return {
      poolOwner,
      tokenAddress
    }

  } catch (error) {
    console.error("ERROR while fetching pool addresses  : ", error)
    return {
      poolOwner,
      tokenAddress
    }
  }
}

export const finalizedPool = async (
  poolAddress,
  signer
) => {
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
    const privateSalePoolContractInstanceWithSigner = privateSalePoolContractInstance.connect(signer)
    const finalizedPoolReceipt = await privateSalePoolContractInstanceWithSigner.finalizePool({ gasLimit: '2700000' })
    const result = await finalizedPoolReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to finalized the private sale pool. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const cancelPool = async (
  poolAddress,
  signer
) => {
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
    const privateSalePoolContractInstanceWithSigner = privateSalePoolContractInstance.connect(signer)
    const finalizedPoolReceipt = await privateSalePoolContractInstanceWithSigner.cancelPool()
    const result = await finalizedPoolReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to cancel the private sale pool. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}


export const startClaiming = async (
  poolAddress,
  signer
) => {
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
    const privateSalePoolContractInstanceWithSigner = privateSalePoolContractInstance.connect(signer)
    const finalizedPoolReceipt = await privateSalePoolContractInstanceWithSigner.startClaiming()
    const result = await finalizedPoolReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to start the claiming. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const getRewardTokenSetStatus = async (poolAddress) => {

  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
    const isRewardsTokenSet = await privateSalePoolContractInstance.isRewardTokenSet()
    return isRewardsTokenSet

  } catch (error) {
    console.error("ERROR while checking rewards token set status  : ", error)
    return null
  }
}

export const setRewardsToken = async (
  poolAddress,
  rewardsTokenAddress,
  signer
) => {
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
    const privateSalePoolContractInstanceWithSigner = privateSalePoolContractInstance.connect(signer)
    const setRewardsTokenReceipt = await privateSalePoolContractInstanceWithSigner.setRewardToken(rewardsTokenAddress)
    const result = await setRewardsTokenReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to setting the reward token. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const calculateTokenomics = async (poolAddress, tokenAddress) => {

  let burnAmount = 0.00
  let burnPercentage = 0.00
  let lpAmount = 0.00
  let lpPercentage = 0.00
  let privateSaleAmount = 0.0
  let privateSalePercentage = 0.0
  let totalSupplyAmount = 0.0

  try {

    if (poolAddress && tokenAddress) {

      //get the decimals for the token
      const tokenDecimals = await getTheDecimalsOfTokenAddress(tokenAddress)

      //get the total supply of the token
      const unformattedTotalSupply = await getTotalSupplyOfToken(tokenAddress)
      totalSupplyAmount = unformattedTotalSupply / (10 ** tokenDecimals)

      //get the unformatted burn amount
      const unformattedBurnAmount = await getTotalBurnAmountInToken(tokenAddress)
      burnAmount = unformattedBurnAmount / (10 ** tokenDecimals)

      //get the unformatted private sale amount
      privateSaleAmount = await getTheBalanceOfAnotherAddress(tokenAddress, poolAddress)

      //get the LP amount of the token
      const unformattedLPAmount = await getLPTokenAmount(tokenAddress)
      lpAmount = unformattedLPAmount / (10 ** tokenDecimals)

      burnPercentage = (burnAmount / totalSupplyAmount) * 100
      lpPercentage = (lpAmount / totalSupplyAmount) * 100
      privateSalePercentage = (privateSaleAmount / totalSupplyAmount) * 100

      return {
        burnAmount,
        burnPercentage,
        lpAmount,
        lpPercentage,
        privateSaleAmount,
        privateSalePercentage,
        totalSupplyAmount
      }
    } else {
      return {
        burnAmount,
        burnPercentage,
        lpAmount,
        lpPercentage,
        privateSaleAmount,
        privateSalePercentage,
        totalSupplyAmount
      }
    }


  } catch (error) {
    console.error("ERROR while checking rewards token set status  : ", error)
    return {
      burnAmount,
      burnPercentage,
      lpAmount,
      lpPercentage,
      privateSaleAmount,
      privateSalePercentage,
      totalSupplyAmount
    }
  }
}

export const getTotalBurnAmountInToken = async (tokenAddress) => {
  let totalBurnAmount = 0.0
  try {
    if (tokenAddress) {
      const provider = getWeb3PRovider()
      const erc20ContractABI = JSON.parse(configs.commonContractABIS.bsc.commonERC20ContractABI)
      const erc20ContractInstance = new ethers.Contract(tokenAddress, erc20ContractABI, provider)
      const balanceOfBurnAddress1 = await erc20ContractInstance.balanceOf('0x000000000000000000000000000000000000dead')
      const balanceOfBurnAddress2 = await erc20ContractInstance.balanceOf('0x0000000000000000000000000000000000000000')
      totalBurnAmount = parseInt(balanceOfBurnAddress1.toString()) + parseInt(balanceOfBurnAddress2.toString())
      return totalBurnAmount
    }
  } catch (error) {
    console.error("ERROR while getting total amount of burn tokens  : ", error)
    return totalBurnAmount
  }
}

export const getTotalSupplyOfToken = async (tokenAddress) => {
  let totalSupply = 0.0
  try {
    if (tokenAddress) {
      const provider = getWeb3PRovider()
      const erc20ContractABI = JSON.parse(configs.commonContractABIS.bsc.commonERC20ContractABI)
      const erc20ContractInstance = new ethers.Contract(tokenAddress, erc20ContractABI, provider)
      const totalSupplyValue = await erc20ContractInstance.totalSupply()
      totalSupply = parseInt(totalSupplyValue.toString())
      return totalSupply
    }
  } catch (error) {
    console.error("ERROR while getting total supply of a token  : ", error)
    return totalSupply
  }
}

export const getTheBalanceOfAnotherAddress = async (tokenAddress, tokenAddressNeedToCheckBalance) => {
  let tokenBalance = 0.0
  try {
    if (tokenAddress && tokenAddressNeedToCheckBalance) {
      const provider = getWeb3PRovider()
      const erc20ContractABI = JSON.parse(configs.commonContractABIS.bsc.commonERC20ContractABI)
      const erc20ContractInstance = new ethers.Contract(tokenAddress, erc20ContractABI, provider)
      const tokenBalanceValue = await erc20ContractInstance.balanceOf(tokenAddressNeedToCheckBalance)
      const tokenDecimals = await erc20ContractInstance.decimals()
      const decimals = parseInt(tokenDecimals.toString())
      tokenBalance = parseInt(tokenBalanceValue.toString()) / 10 ** decimals
      return tokenBalance
    }
  } catch (error) {
    console.error("ERROR while getting the balance of a token address  : ", error)
    return tokenBalance
  }
}

export const getTheDecimalsOfTokenAddress = async (tokenAddress) => {
  let decimals = 0
  try {
    if (tokenAddress) {
      const provider = getWeb3PRovider()
      const erc20ContractABI = JSON.parse(configs.commonContractABIS.bsc.commonERC20ContractABI)
      const erc20ContractInstance = new ethers.Contract(tokenAddress, erc20ContractABI, provider)
      const decimalValue = await erc20ContractInstance.decimals()
      decimals = parseInt(decimalValue.toString())
      return decimals
    }
  } catch (error) {
    console.error("ERROR while getting the decimal amount of a token  : ", error)
    return decimals
  }
}

export const getTokenPairAddress = async (tokenAddress) => {
  let pairAddress = null
  try {
    if (tokenAddress) {
      const provider = getWeb3PRovider()
      const pcsFactoryContractABI = JSON.parse(configs.pancakeSwap.pcsFactoryAbi)
      const pcsFactoryAddress = configs.pancakeSwap.factoryAddress
      const wBNBAddress = configs.pancakeSwap.wrapBNBAddress
      const pcsFactoryContractInstance = new ethers.Contract(pcsFactoryAddress, pcsFactoryContractABI, provider)
      pairAddress = await pcsFactoryContractInstance.getPair(wBNBAddress, tokenAddress)
      if (pairAddress.includes('0x000000000')) {
        pairAddress = await pcsFactoryContractInstance.getPair(tokenAddress, wBNBAddress)
      }

      return pairAddress;
    }
  } catch (error) {
    console.error("ERROR while getting the pair address from pancakeswap : ", error)
    return pairAddress
  }
}

export const getLPTokenAmount = async (tokenAddress) => {
  let liquidityTokenSelected = 0.0
  try {
    if (tokenAddress) {
      const provider = getWeb3PRovider()

      //get token pair address
      const pairAddress = await getTokenPairAddress(tokenAddress)

      //create LP token contract instance 
      const LPTokenContractABI = JSON.parse(configs.pancakeSwap.lpTokenAbi)
      const LPTokenContractInstance = new ethers.Contract(pairAddress, LPTokenContractABI, provider)

      const token0 = await LPTokenContractInstance.token0()
      const reserves = await LPTokenContractInstance.getReserves()

      if (tokenAddress.toLowerCase() === token0.toLowerCase()) {
        liquidityTokenSelected = parseFloat(reserves['_reserve0']);
      } else {
        liquidityTokenSelected = parseFloat(reserves['_reserve1']);
      }
      return liquidityTokenSelected;
    }
  } catch (error) {
    console.error("ERROR while getting the lp amount of the token : ", error)
    return liquidityTokenSelected
  }
}

export const whitelistUserList = async (poolAddress, walletAddress, signer) => {
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
    const privateSalePoolContractInstanceWithSigner = privateSalePoolContractInstance.connect(signer)
    const whitelistUsersReceipt = await privateSalePoolContractInstanceWithSigner.whiteListUsers(walletAddress)
    const result = await whitelistUsersReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to whitelist addresses. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const getPoolWhiteListedStatus = async (
  poolAddress,
) => {
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
    const poolWhiteListStatus = await privateSalePoolContractInstance.isWhitelisted()
    return poolWhiteListStatus
  } catch (error) {
    console.error("ERROR while getting pool whitelist status  : ", error)
    return false
  }
}

export const getPoolVestingStatus = async (poolAddress) => {
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
    const poolVestingStatus = await privateSalePoolContractInstance.isVesting()
    return poolVestingStatus
  } catch (error) {
    console.error("ERROR while getting pool vesting status  : ", error)
    return false
  }
}

export const calculateVestingCycles = async (poolAddress) => {
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)

    //check pool is finalized or not
    const finalizedDate = await privateSalePoolContractInstance.finalizedAt()
    const initialReleaseValue = await privateSalePoolContractInstance.initialRelease()
    const initialValuePercentage = parseInt(initialReleaseValue.toString())

    const releaseCycleValue = await privateSalePoolContractInstance.releaseCycle()
    const releaseCycleInt = parseInt(releaseCycleValue.toString())

    const SECONDS_PER_DAY = 86400
    const releaseCycleInDays = releaseCycleInt / SECONDS_PER_DAY

    const releasePercentageValue = await privateSalePoolContractInstance.releasePercentage()
    const releasePercentage = parseInt(releasePercentageValue.toString())

    const endTimeValue = await privateSalePoolContractInstance.endTime()
    const endTimeTimestamp = parseInt(endTimeValue.toString())
    const finalizedDateTimestamp = parseInt(finalizedDate.toString())

    const remainingPercentageForVestingCycles = 100 - initialValuePercentage

    //calculate cycles 
    const noOfCycles = remainingPercentageForVestingCycles / releasePercentageValue

    //check if remainder available
    const noOfCyclesInInt = parseInt(noOfCycles)
    const cumulativePercentage = noOfCyclesInInt * releasePercentageValue
    const remainingPercentageVale = remainingPercentageForVestingCycles - cumulativePercentage
    const dataArray = []

    //first payload 
    let cycleStartDateTimestamp = 0;
    if (finalizedDateTimestamp !== 0) {
      cycleStartDateTimestamp = finalizedDateTimestamp
    } else {
      cycleStartDateTimestamp = endTimeTimestamp
    }

    const tokenAddress = await privateSalePoolContractInstance.tokenAddress()
    const tokenDecimals = await getTheDecimalsOfTokenAddress(tokenAddress)

    const totalTokens = await privateSalePoolContractInstance.totalTokenAmount()
    const actualTokenAmount = totalTokens / (10 ** tokenDecimals)

    const payload = {
      nextDate: "On Launch",
      nextReleasePercentage: initialValuePercentage,
      nextReleaseTokens: (actualTokenAmount / 100) * initialValuePercentage
    }

    dataArray.push(payload)
    let daysToAdd = releaseCycleInDays
    for (let i = 0; i < noOfCyclesInInt; i++) {
      const payload = {
        nextDate: `After ${daysToAdd} days`,
        nextReleasePercentage: releasePercentage,
        nextReleaseTokens: (actualTokenAmount / 100) * releasePercentage
      }
      dataArray.push(payload)
      daysToAdd += releaseCycleInDays
    }

    if (remainingPercentageVale > 0) {
      const payload = {
        nextDate: `After ${daysToAdd} days`,
        nextReleasePercentage: remainingPercentageVale,
        nextReleaseTokens: (actualTokenAmount / 100) * remainingPercentageVale
      }
      dataArray.push(payload)
    }
    return dataArray
  } catch (error) {
    console.error("ERROR while getting pool vesting details  : ", error)
    return []
  }
}

export const getPoolFinalizedStatus = async (poolAddress) => {
  let isPoolFinalized = false
  let poolFinalizedAtTimestamp = 0
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
    const poolFinalizedAt = await privateSalePoolContractInstance.finalizedAt()
    const poolFinalizedAtNumber = parseInt(poolFinalizedAt.toString())
    if (poolFinalizedAtNumber) {
      isPoolFinalized = true
      poolFinalizedAtTimestamp = poolFinalizedAtNumber
    } else {
      isPoolFinalized = false
      poolFinalizedAtTimestamp = poolFinalizedAtNumber
    }
    return {
      isPoolFinalized,
      poolFinalizedAtTimestamp
    }
  } catch (error) {
    console.error("ERROR while getting pool finalized  status  : ", error)
    return {
      isPoolFinalized,
      poolFinalizedAtTimestamp
    }
  }
}

export const getWhitelistedAddresses = async (poolAddress) => {
  let whitelistedAddresses = []
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
    whitelistedAddresses = await privateSalePoolContractInstance.getWhiteListedUsers()
    console.log("whitelistedAddresses", whitelistedAddresses)
    return whitelistedAddresses
  } catch (error) {
    console.error("ERROR while getting whitelisted users array  : ", error)
    return whitelistedAddresses
  }
}

export const claimRewardsTokensFromPool = async (poolAddress, signer) => {
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
    const privateSalePoolContractInstanceWithSigner = privateSalePoolContractInstance.connect(signer)
    const claimYourTokensReceipt = await privateSalePoolContractInstanceWithSigner.claimRewards()
    const result = await claimYourTokensReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to claim your rewards tokens. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const getPendingRewardsTokenAmount = async (poolAddress, walletAddress) => {
  let pendingRewardsAmount = 0.0
  try {

    if (poolAddress && walletAddress) {
      const provider = getWeb3PRovider()
      const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
      const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
      const userRewardsDetails = await privateSalePoolContractInstance.rewards(walletAddress)

      //get rewards token address
      const rewardsTokenAddress = await privateSalePoolContractInstance.rewardToken()

      //get rewards per share
      const rewardPerShareResponse = await privateSalePoolContractInstance.rewardPerShare()
      const rewardsPerShareInt = parseInt(rewardPerShareResponse.toString())
      const eRC20ContractABI = JSON.parse(configs.commonContractABIS.bsc.commonERC20ContractABI)
      const contractInstance = new ethers.Contract(rewardsTokenAddress, eRC20ContractABI, provider)
      const decimals = await contractInstance.decimals()
      const rewardsTokenDecimals = parseInt(decimals.toString())


      const sharedAmount = parseInt(userRewardsDetails[0].toString()) / 10 ** rewardsTokenDecimals
      const totalClaimed = parseInt(userRewardsDetails[1].toString()) / 10 ** rewardsTokenDecimals

      pendingRewardsAmount = (rewardsPerShareInt * sharedAmount) - totalClaimed
      return pendingRewardsAmount
    }
  } catch (error) {
    console.error("ERROR while fetching pending rewards token amount  : ", error)
    return pendingRewardsAmount
  }
}


export const setPoolCreationFeeWithoutTokenBNB = async (poolCreationFee, signer) => {
  try {
    const provider = getWeb3PRovider()

    const privateSaleContractABI = JSON.parse(configs.privateSaleContract.contractABINonToken)
    const privateSaleContractAddress = configs.privateSaleContract.contractForNonToken
    const privateSaleContractInstance = new ethers.Contract(
      privateSaleContractAddress,
      privateSaleContractABI,
      provider
    )
    const privateSaleContractInstanceWithSigner = privateSaleContractInstance.connect(signer)

    const setFeeReceipt = await privateSaleContractInstanceWithSigner.changeFeeAmount(poolCreationFee)
    const result = await setFeeReceipt.wait()
    return result

  } catch (error) {
    let errorMessage = 'Something went wrong while setting pool creation fee for without token BNB. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const setPoolCreationFeeWithTokenBNB = async (poolCreationFee, signer) => {
  try {
    const provider = getWeb3PRovider()

    const privateSaleContractABI = JSON.parse(configs.privateSaleContract.contractABI)
    const privateSaleContractAddress = configs.privateSaleContract.contractAddress
    const privateSaleContractInstance = new ethers.Contract(
      privateSaleContractAddress,
      privateSaleContractABI,
      provider
    )
    const privateSaleContractInstanceWithSigner = privateSaleContractInstance.connect(signer)

    const setFeeReceipt = await privateSaleContractInstanceWithSigner.changeFeeAmount(poolCreationFee)
    const result = await setFeeReceipt.wait()
    return result

  } catch (error) {
    let errorMessage = 'Something went wrong while setting pool creation fee for with token BNB. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const setPoolCreationFeeWithoutTokenNonBNB = async (poolCreationFee, signer) => {
  try {
    const provider = getWeb3PRovider()

    const privateSaleContractABI = JSON.parse(configs.privateSaleContract.contractWithoutTokenNonBNBABI)
    const privateSaleContractAddress = configs.privateSaleContract.contractAddressWithoutTokenNonBNB
    const privateSaleContractInstance = new ethers.Contract(
      privateSaleContractAddress,
      privateSaleContractABI,
      provider
    )
    const privateSaleContractInstanceWithSigner = privateSaleContractInstance.connect(signer)

    const setFeeReceipt = await privateSaleContractInstanceWithSigner.changeFeeAmount(poolCreationFee)
    const result = await setFeeReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while setting pool creation fee for without token NON BNB. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const setPoolCreationFeeWithTokenNonBNB = async (poolCreationFee, signer) => {
  try {
    const provider = getWeb3PRovider()

    const privateSaleContractABI = JSON.parse(configs.privateSaleContract.contractWithTokenNonBNBABI)
    const privateSaleContractAddress = configs.privateSaleContract.contractAddressWithTokenNonBNB
    const privateSaleContractInstance = new ethers.Contract(
      privateSaleContractAddress,
      privateSaleContractABI,
      provider
    )
    const privateSaleContractInstanceWithSigner = privateSaleContractInstance.connect(signer)

    const setFeeReceipt = await privateSaleContractInstanceWithSigner.changeFeeAmount(poolCreationFee)
    const result = await setFeeReceipt.wait()
    return result

  } catch (error) {
    let errorMessage = 'Something went wrong while setting pool creation fee for with token NON BNB. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const executeWithdrawBEP20Tokens = async (poolContractAddress, tokenAddress, percentage, signer) => {

  console.log("poolContractAddress", poolContractAddress)
  console.log("tokenAddress", tokenAddress)
  console.log("percentage", percentage)
  try {
    const provider = getWeb3PRovider()

    const poolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const poolContractInstance = new ethers.Contract(
      poolContractAddress,
      poolContractABI,
      provider
    )
    const poolContractInstanceWithSigner = poolContractInstance.connect(signer)

    const withdrawalReceipt = await poolContractInstanceWithSigner.withdrawBep20Tokens(tokenAddress, percentage, { gasLimit: '2700000' })
    const result = await withdrawalReceipt.wait()
    return result

  } catch (error) {
    let errorMessage = 'Something went wrong while withdrawing BEP20 tokens. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const getDeployer = async (poolAddress) => {
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)

    const owner = await privateSalePoolContractInstance.owner()
    return owner
  } catch (error) {
    console.error("ERROR while fetching pool owner  : ", error)
    return null
  }
}

export const executeBNBDistribution = async (
  poolAddress,
  startIndex,
  endIndex,
  signer
) => {
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
    const privateSalePoolContractInstanceWithSigner = privateSalePoolContractInstance.connect(signer)
    const bnbDistributionReceipt = await privateSalePoolContractInstanceWithSigner.distributeBnb(startIndex, endIndex)
    const result = await bnbDistributionReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to distribute your BNB. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const executeTokenDistribution = async (
  poolAddress,
  startIndex,
  endIndex,
  signer
) => {
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
    const privateSalePoolContractInstanceWithSigner = privateSalePoolContractInstance.connect(signer)
    const bnbDistributionReceipt = await privateSalePoolContractInstanceWithSigner.distributeTokens(startIndex, endIndex)
    const result = await bnbDistributionReceipt.wait()
    return result
  } catch (error) {
    let errorMessage = 'Something went wrong while trying to distribute your tokens. Please try again'
    if (error && error.reason && error.reason !== '') {
      errorMessage = error.reason
    }
    throw errorMessage
  }
}

export const getThePoolAdmin = async (poolAddress) => {
  try {
    const provider = getWeb3PRovider()
    const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
    const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)

    const poolAdmin = await privateSalePoolContractInstance.poolOwner()
    return poolAdmin
  } catch (error) {
    console.error("ERROR while fetching pool admin  : ", error)
    return null
  }
}

export const calculatePendingRewardsTokenAmount = async (poolAddress, walletAddress) => {

  try {
    if (walletAddress) {
      const provider = getWeb3PRovider()
      const privateSalePoolContractABI = JSON.parse(configs.privateSaleContract.poolContractABI)
      const privateSalePoolContractInstance = new ethers.Contract(poolAddress, privateSalePoolContractABI, provider)
      const rewardsPerShareResponse = await privateSalePoolContractInstance.rewardPerShare()
      const rewardsPerShareAmount = parseFloat(rewardsPerShareResponse.toString())

      const userRewards = await privateSalePoolContractInstance.rewards(walletAddress)
      const shareAmountResponse = parseFloat(userRewards[0].toString())
      const totalClaimedResponse = parseFloat(userRewards[1].toString())

      //calculations
      const totalRewardsAmountForUser = (rewardsPerShareAmount * shareAmountResponse) - totalClaimedResponse
      const totalRewardsAmountForUserFormatted = utils.formatEther(totalRewardsAmountForUser.toString())
      return totalRewardsAmountForUserFormatted.toString()
    } else {
      return 0.0
    }
  } catch (error) {
    console.error("ERROR while calculating rewards token for user : ", error)
    return 0.0
  }
}