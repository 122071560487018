import React, { useEffect, useState } from 'react'
import { Row, } from 'reactstrap'
import { Button, notification, Spin } from 'antd'
import {
  claimAndExitFromPool,
} from '../../../Blockchain/web3.service'
import { useWeb3React } from '@web3-react/core'
import useUserPrivateSalePoolDetails from '../../../Hooks/useUserPrivateSalePoolDetails'
import axios from 'axios'
export default function ClaimAndExitButton(props) {

  const {
    isPoolStatusLoading,
    tokenAddress,
    poolStatus,
    poolAddress,
    shouldForcedRefresh,
    setShouldForcedRefresh
  } = props

  const [isClaimAndExitLoading, setIsClaimAndExitLoading] = useState(false)
  const [canShowClaimButton, setCanShowClaimButton] = useState(false)

  const { account, library } = useWeb3React()

  const { userPrivateSalePoolDetails, isLoading: isUserDetailsLoading } = useUserPrivateSalePoolDetails({
    poolAddress: poolAddress,
    walletAddress: account,
    isWithoutToken: tokenAddress ? false : true,
    forcedRefresh: shouldForcedRefresh
  })

  useEffect(() => {
    if (!isUserDetailsLoading &&
      !isPoolStatusLoading &&
      parseFloat(userPrivateSalePoolDetails?.myContributionInBNB) > 0 &&
      (poolStatus?.statusCode === 'canceled')) {
      setCanShowClaimButton(true)

    } else {
      setCanShowClaimButton(false)
    }
  }, [
    shouldForcedRefresh,
    poolStatus,
    isUserDetailsLoading,
    isPoolStatusLoading,
    userPrivateSalePoolDetails])

  const handleClaimAndExitPool = async () => {
    try {

      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        });
        setIsClaimAndExitLoading(false)
        return
      }

      setIsClaimAndExitLoading(true)
      const response = await claimAndExitFromPool(poolAddress, library.getSigner())

      //save contribution details to the api
      const payload = {
        "poolContractAddress": poolAddress,
        "walletAddress": account
      }

      let config = {
        method: 'delete',
        url: `${process.env.REACT_APP_API_BASE_URL}/private-sale/contribute`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(payload)
      };
      const apiResponse = await axios(config)
      console.log("response ", response)
      console.log("apiResponse ", apiResponse)

      setIsClaimAndExitLoading(false)
      setShouldForcedRefresh(true)
      notification['success']({
        message: 'Success',
        description: 'You have successfully claim your BNB, you are no longer contributor for this pool.',
      });
    } catch (error) {
      notification['error']({
        message: 'Error Occurred',
        description: error,
      });
      setIsClaimAndExitLoading(false)
      console.error("ERROR while executing the contribution ", error)
    }
  }


  return (
    <>
      {
        isUserDetailsLoading ? (
          <div className="loader d-flex justify-content-center mt-4">
            <Spin size='small' />
          </div>
        ) : (
          <Row className="mt-2 claim-and-exit">
            <div className='claim-and-exit-button-container'>
              {
                canShowClaimButton ? (
                  <Button
                    loading={isClaimAndExitLoading}
                    onClick={handleClaimAndExitPool}
                    block
                    disabled={isUserDetailsLoading}
                    type="primary">
                    Exit the pool
                  </Button>
                ) : (<></>)
              }
            </div>
          </Row>
        )
      }
    </>
  )
}
