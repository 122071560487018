/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import * as web3Service from '../Blockchain/web3.service'
export const usePoolStatus = (props) => {

  const { poolAddress } = props
  const [poolStatus, setPoolStatus] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (poolAddress) {
      fetchPoolStatus(poolAddress)
    }
  }, [poolAddress])

  const fetchPoolStatus = async (poolAddress) => {
    try {
      setIsLoading(true)
      const response = await web3Service.getPoolStatus(poolAddress)
      setPoolStatus(response)
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error("ERROR HOOK : while fetching pool status ", error)
    }
  }

  return { poolStatus, isLoading }
}


export default usePoolStatus

