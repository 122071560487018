/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import * as web3Service from '../Blockchain/web3.service'
export const usePoolAddresses = (props) => {

  const { poolAddress } = props

  const [poolAddressDetails, setPoolAddressDetails] = useState(null)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (poolAddress) {
      fetchPoolAddressesData()
    }

  }, [poolAddress])

  const fetchPoolAddressesData = async () => {
    try {
      setLoading(true)
      const poolAddressesResponse = await web3Service.getPoolAddresses(poolAddress)
      setPoolAddressDetails(poolAddressesResponse)
      setLoading(false)
    } catch (error) {
      console.error("ERROR HOOK : while fetching pool addresses ", error)
      setLoading(false)
      setPoolAddressDetails(null)
    }
  }


  return { poolAddressDetails, isLoading }
}


export default usePoolAddresses

