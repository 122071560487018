/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import * as web3Service from '../Blockchain/web3.service'

export const usePrivateSaleCreationFeeNonToken = () => {

  const [feeAmountInWei, setFeeAmountInWei] = useState(0.00)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    fetchTokenFeePercentage()
  }, [])

  const fetchTokenFeePercentage = async () => {
    try {
      setLoading(true)
      const feeAmountResponse = await web3Service.getPrivatePoolCreationFeeInPrivateSaleNonToken()
      setFeeAmountInWei(feeAmountResponse)
      setLoading(false)
    } catch (error) {
      console.error("ERROR HOOK : while fetching private sale creation fee ", error)
      setLoading(false)
    }
  }


  return { feeAmountInWei, isLoading }
}


export default usePrivateSaleCreationFeeNonToken

