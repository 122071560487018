import React from 'react'
import { Link } from 'react-router-dom';
import { Card, Col, Row, Tag, Spin } from 'antd';
import { Bell } from 'react-feather'
import { utils } from 'ethers';
import ProgressBarForWidget from './ProgressBarForWidget';
import PoolCountDownTimer from './PoolCountDownTimer';
import usePoolStatus from '../../Hooks/usePoolStatus';
import RFCLogo from '../../Images/rfc-logo.svg'
export default function PrivateSaleWidget(props) {

  const { data: poolData } = props
  const {
    isLoading: isPoolStatusLoading,
    poolStatus
  } = usePoolStatus({ poolAddress: poolData.poolContractAddress })

  const getPrivateSaleImage = () => {
    if (poolData && poolData.logoURL) {
      return poolData.logoURL
    } else {
      return RFCLogo
    }
  }

  return (
    <div>
      <Link to={`/fundingpool/${poolData.poolContractAddress}`}>
        <Card className="bounce-card">

          <div className="d-flex justify-content-center">
            <img src={getPrivateSaleImage()} alt={poolData ? poolData.privateSaleName : ''} className="card-logo" />
          </div>

          <div>
            <div className="text-end">
              {
                isPoolStatusLoading ? (
                  <div className='loader'>
                    <Spin size="small" />
                  </div>
                ) : (
                  <Tag color={poolStatus?.statusColor}>
                    {poolStatus?.statusName}
                  </Tag>
                )
              }
            </div>
          </div>

          <h6 className="mt-3">{poolData.privateSaleName ? poolData.privateSaleName : "~"}</h6>
          <span>Max Contribution: {poolData.maximumBuy ? utils.formatEther(poolData.maximumBuy) : 0} {poolData?.customCurrencySymbol} </span><br /><br />

          <ProgressBarForWidget poolAddress={poolData.poolContractAddress} poolDetails={poolData} />

          <Row className="mt-3">
            <Col>
              <h6>Hard Cap:</h6>
            </Col>

            <Col className="text-end">
              <h6 className="card-value" style={{ paddingLeft: '5px' }}>
                {poolData.hardCap ? utils.formatEther(poolData.hardCap) : 0} {poolData?.customCurrencySymbol}
              </h6>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <PoolCountDownTimer
                startTime={poolData ? poolData.startTimeTimestamp : null}
                endTime={poolData ? poolData.endTimeTimestamp : null}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-end">
              <div className='d-flex justify-content-end'>
                <Bell size={20} className="mt-3" style={{ cursor: 'pointer' }} />
              </div>
            </Col>
          </Row>
        </Card>
      </Link>
    </div>
  )
}
