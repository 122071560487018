/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import * as web3Service from '../Blockchain/web3.service'

export const usePrivateSaleTokenFee = () => {

  const [tokenFeePercentage, setTokenFeePercentage] = useState(0.00)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    fetchTokenFeePercentage()
  }, [])

  const fetchTokenFeePercentage = async () => {
    try {
      setLoading(true)
      const tokenFeeResponse = await web3Service.getTokenFeePercentageInPrivateSale()
      setTokenFeePercentage(tokenFeeResponse)
      setLoading(false)
    } catch (error) {
      console.error("ERROR HOOK : while fetching token fee percentage data ", error)
      setLoading(false)
    }
  }


  return { tokenFeePercentage, isLoading }
}


export default usePrivateSaleTokenFee

