import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Privatesales from './Privatesales/Privatesales'
import PrivatesaleDetail from './PrivatesaleDetail/PrivatesaleDetail'
import BnbContribution from './BnbContribution/BnbContribution'
import PrivatesaleContribution from './PrivatesaleContribution/PrivatesaleContribution'
import Fundingpad from './Launchpad/Fundingpad'
import Dashboard from './Dashboard/Dashboard'
import Login from './Login/Login'


function Pages() {
    return (
        <Routes>
            <Route path="/" exact element={<Privatesales />} />
            <Route path="fundingpad" exact element={<Fundingpad />} />
            <Route path="fundingpool" exact element={<Privatesales />} />
            <Route path="fundingpool/:privatesale_address" exact element={<PrivatesaleDetail />} />
            <Route path="dashboard" exact element={<Dashboard />} />
            <Route path="bnbcontribution" exact element={<BnbContribution />} />
            <Route path="fundingpoolcontribution" exact element={<PrivatesaleContribution />} />
            <Route path="login" exact element={<Login />} />
        </Routes>
    )
}

export default Pages
