import React, { useState, useEffect } from 'react'
import { Row, } from 'reactstrap'
import { Button, notification, Input } from 'antd'
import {
  contributeToPrivateSale,
  approveCustomCurrencyToken,
  contributeToPrivateSaleWithCustomCurrency
} from '../../../Blockchain/web3.service'
import { useWeb3React } from '@web3-react/core'
import { utils } from 'ethers'
import axios from 'axios'
export default function BuyWithBNBWidget(props) {

  const {
    minContributionAmount,
    isPoolStatusLoading,
    maxContributionAmount,
    poolStatus,
    isPoolDataLoading,
    poolAddress,
    setShouldForcedRefresh,
    privateSaleDetails
  } = props

  const [contributionAmountInBNB, setContributionAmountInBNB] = useState('')
  const [isContributionLoading, setIsContributionLoading] = useState(false)
  const [isBuyButtonDisabled, setIsBuyButtonDisabled] = useState(true)
  const [isApproveCustomCurrencyLoading, setIsApproveCustomCurrencyLoading] = useState(false)
  const [isBuyWithCustomCurrencyButtonDisabled, setIsBuyWithCustomCurrencyButtonDisabled] = useState(true)
  const { account, library } = useWeb3React()

  useEffect(() => {
    if (!isPoolDataLoading && !isPoolStatusLoading && contributionAmountInBNB > 0 && !(contributionAmountInBNB < minContributionAmount)) {
      setIsBuyButtonDisabled(false)
    } else {
      setIsBuyButtonDisabled(true)
    }
  }, [isPoolDataLoading, isPoolStatusLoading, contributionAmountInBNB, minContributionAmount])

  const handleBuyAmount = (e) => {
    const value = e.target.value
    if (value) {
      if (parseFloat(value) > parseFloat(maxContributionAmount)) {
        setContributionAmountInBNB(maxContributionAmount)
      } else {
        setContributionAmountInBNB(value)
      }
    } else {
      setContributionAmountInBNB('')
    }
  }
  const handleMaxInput = () => {
    setContributionAmountInBNB(maxContributionAmount)
  }
  const handleContributionPayment = async () => {
    try {

      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        });
        setIsContributionLoading(false)
        return
      }

      setIsContributionLoading(true)
      const contributionAmountInWei = utils.parseEther(contributionAmountInBNB.toString())
      const response = await contributeToPrivateSale(poolAddress, contributionAmountInWei, library.getSigner())

      //save contribution details to the api
      const payload = {
        "poolContractAddress": poolAddress,
        "contributedAmountInWei": contributionAmountInWei ? contributionAmountInWei.toString() : "0.00",
        "walletAddress": account
      }

      let config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_BASE_URL}/private-sale/contribute`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(payload)
      };

      const apiResponse = await axios(config)
      console.log("response ", response)
      console.log("apiResponse ", apiResponse)

      setIsContributionLoading(false)
      setContributionAmountInBNB('')
      setShouldForcedRefresh(true)
      notification['success']({
        message: 'Success',
        description: 'You have successfully contributed to private pool.',
      });
    } catch (error) {
      setIsContributionLoading(false)
      setContributionAmountInBNB('')
      notification['error']({
        message: 'Error Occurred',
        description: error,
      });

    }
  }

  const handleCustomCurrencyContributionPayment = async () => {
    try {

      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        });
        setIsContributionLoading(false)
        return
      }

      setIsContributionLoading(true)
      const contributionAmountInWei = utils.parseEther(contributionAmountInBNB.toString())
      const response = await contributeToPrivateSaleWithCustomCurrency(poolAddress, contributionAmountInWei, library.getSigner())

      //save contribution details to the api
      const payload = {
        "poolContractAddress": poolAddress,
        "contributedAmountInWei": contributionAmountInWei ? contributionAmountInWei.toString() : "0.00",
        "walletAddress": account
      }

      let config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_BASE_URL}/private-sale/contribute`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(payload)
      };

      const apiResponse = await axios(config)
      console.log("response ", response)
      console.log("apiResponse ", apiResponse)

      setIsContributionLoading(false)
      setContributionAmountInBNB('')
      setShouldForcedRefresh(true)
      notification['success']({
        message: 'Success',
        description: 'You have successfully contributed to private pool.',
      });
    } catch (error) {
      setIsContributionLoading(false)
      setContributionAmountInBNB('')
      notification['error']({
        message: 'Error Occurred',
        description: error,
      });

    }
  }



  const handleApproveCustomCurrency = async () => {
    try {

      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        });
        setIsApproveCustomCurrencyLoading(false)
        return
      }

      setIsApproveCustomCurrencyLoading(true)
      const contributionAmountInWei = utils.parseEther(contributionAmountInBNB.toString())
      await approveCustomCurrencyToken(poolAddress, privateSaleDetails?.customCurrencyTokenAddress, contributionAmountInWei, library.getSigner())

      setIsApproveCustomCurrencyLoading(false)
      setIsBuyWithCustomCurrencyButtonDisabled(false)
      notification['success']({
        message: 'Success',
        description: 'Token has been approved.',
      });
    } catch (error) {
      setIsApproveCustomCurrencyLoading(false)
      notification['error']({
        message: 'Error Occurred',
        description: error,
      });

    }
  }

  return (
    <>
      {
        poolStatus && poolStatus.canContribute ? (
          <>
            <Row className="mt-4">
              <span className='input-label'>
                Amount ( min:{minContributionAmount} ~ max: {maxContributionAmount} {privateSaleDetails?.customCurrencySymbol})
              </span>
              <Input.Group compact style={{
                padding: '0 0 0 10px'
              }}>
                < Input
                  style={{
                    width: 'calc(100% - 70px)',
                  }}
                  disabled={isPoolDataLoading || isPoolStatusLoading ? true : false}
                  value={isPoolDataLoading || isPoolStatusLoading ? 'Please wait' : contributionAmountInBNB}
                  onChange={handleBuyAmount}
                  placeholder="0.0"
                />
                <Button
                  disabled={isPoolDataLoading || isPoolStatusLoading ? true : false}
                  onClick={handleMaxInput}
                  type="primary">max
                </Button>
              </Input.Group>
            </Row>

            {
              privateSaleDetails?.isTokenHasCustomCurrency ? (
                <Row className="mt-2">
                  <div className='contribution-button-container'>
                    <Button
                      style={{ marginRight: '5px' }}
                      loading={isApproveCustomCurrencyLoading}
                      onClick={handleApproveCustomCurrency}
                      disabled={isBuyButtonDisabled}
                      type="primary">
                      Approve {privateSaleDetails?.customCurrencySymbol}
                    </Button>
                    <Button
                      loading={isContributionLoading}
                      onClick={handleCustomCurrencyContributionPayment}
                      disabled={isBuyWithCustomCurrencyButtonDisabled || poolStatus.statusCode !== 'live'}
                      type="primary">
                      Buy with {privateSaleDetails?.customCurrencySymbol}
                    </Button>
                  </div>
                </Row>
              ) : (
                <Row className="mt-2">
                  <div className='contribution-button-container'>
                    <Button
                      loading={isContributionLoading}
                      onClick={handleContributionPayment}
                      disabled={isBuyButtonDisabled}
                      type="primary">
                      Buy with BNB
                    </Button>
                  </div>
                </Row>
              )
            }

          </>
        ) : (
          <></>
        )
      }
    </>
  )
}
