import React, {useState} from 'react'
import { Row, Col } from 'reactstrap'
import {ArrowLeftCircle} from 'react-feather'
import {useNavigate} from 'react-router-dom'
import {Card, Button} from 'antd'
import DataTable, {createTheme} from 'react-data-table-component'

function Dashboard() {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate(-1)
    }

    const [data] = useState([
        {
            "release_date": '13/05/2022',
            "percentage": '43',
            "amount": 22,
        },
        {
            "release_date": '12/05/2022',
            "percentage": '68',
            "amount": 26,
        },
    ])

    const columns = [
        {
            name: 'Relase Date',
            sortable: false,
            cell: row => (
                <div className='d-flex justify-content-center align-items-center'>
                    {row.release_date}
                </div>
            )
        },
        {
            name: 'Percentage (%)',
            sortable: false,
            cell: row => (
                <div className='d-flex justify-content-center align-items-center'>
                    {row.percentage}
                </div>
            )
        },
        {
            name: 'Amount',
            sortable: false,
            cell: row => (
                <div className='d-flex justify-content-center align-items-center'>
                    {row.amount}
                </div>
            )
        },
        {
            name: 'Action',
            sortable: false,
            cell: row => (
                <div className='d-flex justify-content-center align-items-center'>
                    <Button type="primary" size="small">Claim</Button>
                </div>
            )
        }
       
      ]

      createTheme('dark', {
        background: {
          default: 'transparent',
        },
      });

    return (
        <div className="container mb-5">
            <Row className="mt-3">
                <Col className="text-start">
                    <div className="d-flex">
                        <ArrowLeftCircle className="back-button" onClick={handleBack} /> <h5 style={{marginLeft: '10px'}}>Back</h5>
                    </div>
                </Col>
            </Row>

            <Card>
                <Row>
                    <Col lg="3" md="6" sm="6" className="mt-3 mb-3 mx-auto">
                            <Card style={{background: '#282828'}}>
                                <h6>Your Token Balance</h6>
                                <span className="fw-bold">0 ETH</span>
                            </Card>
                        </Col>
                    </Row>
                <Row>
                    
                    <Col lg="3" md="6" sm="6" className="mt-3">
                        <Card style={{background: '#282828'}}>
                            <h6>Total User Rewards So Far</h6>
                            <span className="fw-bold">0 ETH</span>
                        </Card>
                    </Col>

                    <Col lg="3" md="6" sm="6" className="mt-3">
                        <Card style={{background: '#282828'}}>
                            <h6>Total User Rewards So Far</h6>
                            <span className="fw-bold">~ 0 USD</span>
                        </Card>
                    </Col>

                    <Col lg="3" md="6" sm="6" className="mt-3">
                        <Card style={{background: '#282828'}}>
                            <h6>Your Dividend Claimable</h6>
                            <span className="fw-bold">0.000000 ETH</span>
                        </Card>
                    </Col>

                    <Col lg="3" md="6" sm="6" className="mt-3">
                        <Card style={{background: '#282828'}}>
                            <h6>Your Dividend Claimable</h6>
                            <span className="fw-bold">~ 0 USD</span>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Button type="primary" size="large" className="mx-auto col-lg-4 col-md-4 col-sm-10 mt-5 mb-3">
                        <span className="fw-bold">Claim Your Rewards</span>
                    </Button>
                </Row>
            </Card>

            <Card title={<h4>Your Vesting Periods</h4>} className="mt-5">
                    <DataTable
                        noHeaders
                        pagination
                        theme="dark"
                        data={data}
                        columns={columns}
                        highlightOnHover
		                pointerOnHover
                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                    />
            </Card>
        </div>
    )
}

export default Dashboard
