import React, { useState } from 'react'
import {
  Row,
  Col,
  Card,
  Button,
  notification
} from 'antd';
import './ApproveToken.css'
import usePrivateSaleCreationFeeNonToken from '../../../Hooks/usePrivateSaleCreationFeeNonToken';
import { useWeb3React } from '@web3-react/core';
import { utils } from 'ethers';
import { DateTime } from 'luxon';
import {
  createNewPrivateSalePoolForNonToken,
  createNewPrivateSalePoolForNonTokenWithCustomCurrency
} from '../../../Blockchain/web3.service'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import { configs } from '../../../Blockchain/web3.config';
import ReactHtmlParser from 'react-html-parser'; 

export default function ReviewInfo(props) {

  const {
    privateSaleName,
    logoURL,
    website,
    facebookLink,
    twitterLink,
    githubLink,
    telegramLink,
    instagramLink,
    discordLink,
    redditLink,
    description,
    minimumBuy,
    maximumBuy,
    hardCap,
    startTime,
    endTime,
    isVestingEnable,
    initialTokenReleasePercentage,
    teamTokenReleasePercentage,
    vestingPeriodInDays,
    stepNumber,
    setStepNumber,
    publicStartTime,
    isWhitelistingEnabled,
    selectedToken
  } = props

  const navigate = useNavigate();

  const { feeAmountInWei, isLoading } = usePrivateSaleCreationFeeNonToken()
  const { account, library } = useWeb3React()
  const [isPoolCreationLoading, setIsPoolCreationLoading] = useState(false)
  const { WBNBTokenAddress } = configs

  const handleBack = () => {
    setStepNumber(stepNumber - 1)
  }


  const handlePrivateSaleCreation = async () => {
    try {

      setIsPoolCreationLoading(true)
      //check user has logged in and connect the wallet
      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        });
        setIsPoolCreationLoading(false)
        return
      }

      //prepare the variables to create pool in chain
      const _startTime = DateTime.fromFormat(startTime, "yyyy-MM-dd HH:mm:ss", { zone: "utc" }).toSeconds()
      const _endTime = DateTime.fromFormat(endTime, "yyyy-MM-dd HH:mm:ss", { zone: "utc" }).toSeconds()
      const _softCap = utils.parseEther('1').toString()
      const _hardCap = utils.parseEther(hardCap.toString()).toString()
      const _minimumBuyAmount = utils.parseEther(minimumBuy.toString()).toString()
      const _maximumBuyAmount = utils.parseEther(maximumBuy.toString()).toString()
      const _poolCreationFee = feeAmountInWei.toString()



      const secondsPerDay = 86400
      const _initialReleasePercentage = initialTokenReleasePercentage ? parseInt(initialTokenReleasePercentage).toString() : '0'
      const _releaseCycleInDays = vestingPeriodInDays ? (parseInt(vestingPeriodInDays) * secondsPerDay).toString() : '0'
      const _releasePercentage = teamTokenReleasePercentage ? parseInt(teamTokenReleasePercentage).toString() : '0'

      let _publicSaleStart = 0
      if (isWhitelistingEnabled) {
        _publicSaleStart = DateTime.fromFormat(publicStartTime, "yyyy-MM-dd HH:mm:ss", { zone: "utc" }).toSeconds()
      }


      console.log("PS _startTime", _startTime)
      console.log("PS _endTime", _endTime)
      console.log("PS _hardCap", _hardCap)

      console.log("PS _minimumBuyAmount", _minimumBuyAmount)
      console.log("PS _maximumBuyAmount", _maximumBuyAmount)
      console.log("PS feeAmountInWei", feeAmountInWei)
      console.log("PS _poolCreationFee", _poolCreationFee)

      console.log("PS _isVestingEnable", isVestingEnable)
      console.log("PS _initialReleasePercentage", _initialReleasePercentage)
      console.log("PS _releaseCycleInDays", _releaseCycleInDays)
      console.log("PS _releasePercentage", _releasePercentage)
      console.log("PS _publicSaleStart", _publicSaleStart)
      console.log("PS isWhitelistingEnabled", isWhitelistingEnabled)

      let isTokenHasCustomCurrency = false
      let customCurrencyTokenAddress = null
      let response = ''

      if (selectedToken.tokenAddress === WBNBTokenAddress) {
        isTokenHasCustomCurrency = false
        response = await createNewPrivateSalePoolForNonToken(
          _startTime.toString(),
          _endTime.toString(),
          _hardCap,
          _minimumBuyAmount,
          _maximumBuyAmount,
          isWhitelistingEnabled,
          _publicSaleStart,
          _poolCreationFee,
          library.getSigner()
        )
      } else {
        isTokenHasCustomCurrency = true
        customCurrencyTokenAddress = selectedToken.tokenAddress
        response = await createNewPrivateSalePoolForNonTokenWithCustomCurrency(
          [
            _startTime.toString(),
            _endTime.toString(),
            _hardCap,
            _minimumBuyAmount,
            _maximumBuyAmount
          ],
          isWhitelistingEnabled,
          _publicSaleStart,
          customCurrencyTokenAddress,
          _poolCreationFee,
          library.getSigner()
        )

      }


      const poolAddress = response?.events[0]?.address
      //if private sale contract creation success save data into the database
      const payload = {
        "privateSaleName": privateSaleName,
        "poolContractAddress": poolAddress,
        "tokenAddress": null,
        "tokenName": null,
        "tokenSymbol": null,
        "tokenDecimals": null,
        "tokenTotalSupply": null,
        "tokenCirculationSupply": null,
        "totalTokenAmount": null,
        "isNotYetFinalized": false,
        "minimumBuy": _minimumBuyAmount,
        "maximumBuy": _maximumBuyAmount,
        "hardCap": _hardCap,
        "softCap": _softCap,
        "startTime": startTime,
        "endTime": endTime,
        "startTimeTimestamp": _startTime,
        "endTimeTimestamp": _endTime,
        "isInvestorVestingEnable": null,
        "initialTokenReleasePercentage": null,
        "vestingPeriod": null,
        "teamTokenReleaseCyclePercentage": null,
        "isWhiteListingEnabled": isWhitelistingEnabled,
        "publicSaleStartTimestamp": _publicSaleStart,
        "logoURL": logoURL,
        "websiteLink": website,
        "facebookLink": facebookLink,
        "twitterLink": twitterLink,
        "githubLink": githubLink,
        "telegramLink": telegramLink,
        "instagramLink": instagramLink,
        "discordLink": discordLink,
        "redditLink": redditLink,
        "description": description,
        "poolOwner": account,
        "isWithoutToken": true,
        "isTokenHasCustomCurrency": isTokenHasCustomCurrency,
        "customCurrencyTokenAddress": customCurrencyTokenAddress,
        "customCurrencySymbol": selectedToken.tokenName
      }


      let config = {
        method: 'post',
        url: `${process.env.REACT_APP_API_BASE_URL}/private-sale/create`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(payload)
      };

      const apiResponse = await axios(config)
      console.log("PS response", response)
      console.log("PS apiResponse", apiResponse)
      notification['success']({
        message: 'Success',
        description: 'Your private sale pool has been created.',
      });
      setIsPoolCreationLoading(false)
      navigate('/fundingpool')

    } catch (error) {
      notification['error']({
        message: 'Transaction Execution Failed',
        description: error,
      });
      setIsPoolCreationLoading(false)
      console.error("ERROR while creating private sale pool component ", error)
    }
  }

  return (
    <div className="mx-auto mt-5 col-11" style={{ overflow: 'hidden' }}>
      <Card className="pb-3 pt-3" >

        <div className='token-data-widget mb-4'>
          <div className='cross-line-header'>
            <span className="text-muted">Token meta data</span>
          </div>


          <Row className="mt-2">
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <h6 className="text-light">Project Name</h6>
            </Col>

            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <span className="text-light d-flex justify-content-md-end">
                {privateSaleName ? privateSaleName : 'N/A'}
              </span>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <h6 className="text-light">Minimum buy</h6>
            </Col>

            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <span className="text-light d-flex justify-content-md-end">
                {minimumBuy ? minimumBuy : 'N/A'}
              </span>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <h6 className="text-light">Maximum buy</h6>
            </Col>

            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <span className="text-light d-flex justify-content-md-end">
                {maximumBuy ? maximumBuy : 'N/A'}
              </span>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <h6 className="text-light">Hard Cap</h6>
            </Col>

            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <span className="text-light d-flex justify-content-md-end">
                {hardCap ? hardCap : 'N/A'}
              </span>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <h6 className="text-light">Start time (UTC)</h6>
            </Col>

            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <span className="text-light d-flex justify-content-md-end">
                {startTime ? startTime : 'N/A'}
              </span>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <h6 className="text-light">End time (UTC)</h6>
            </Col>

            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <span className="text-light d-flex justify-content-md-end">
                {endTime ? endTime : 'N/A'}
              </span>
            </Col>
          </Row>


          <Row className="mt-2">
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <h6 className="text-light">Is Whitelisting Enabled</h6>
            </Col>

            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <span className="text-light d-flex justify-content-md-end">
                {isWhitelistingEnabled ? 'Yes' : 'No'}
              </span>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col xs={24} sm={24} md={6} lg={6} xl={6}>
              <h6 className="text-light">Public Sales Start AT</h6>
            </Col>

            <Col xs={24} sm={24} md={18} lg={18} xl={18}>
              <span className="text-light d-flex justify-content-md-end">
                {publicStartTime ? publicStartTime : 'N/A'}
              </span>
            </Col>
          </Row>
        </div>

        <div className='token-data-widget mb-4'>
          <div className='cross-line-header'>
            <span className="text-muted">Social Details</span>
          </div>
          {logoURL ?
            <Row className="mt-2">
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <h6 className="text-light">Logo url</h6>
              </Col>

              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <span className="text-light d-flex justify-content-md-end">
                  {logoURL ? logoURL : 'N/A'}
                </span>
              </Col>
            </Row> : ""}

          {website ?
            <Row className="mt-2">
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <h6 className="text-light">Website</h6>
              </Col>

              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <span className="text-light d-flex justify-content-md-end">
                  {website ? website : 'N/A'}
                </span>
              </Col>
            </Row> : ""}

          {facebookLink ?
            <Row className="mt-2">
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <h6 className="text-light">Facebook </h6>
              </Col>

              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <span className="text-light d-flex justify-content-md-end">
                  {facebookLink ? facebookLink : 'N/A'}
                </span>
              </Col>
            </Row> : ""}

          {twitterLink ?
            <Row className="mt-2">
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <h6 className="text-light">Twitter</h6>
              </Col>

              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <span className="text-light d-flex justify-content-md-end">
                  {twitterLink ? twitterLink : 'N/A'}
                </span>
              </Col>
            </Row> : ""}

          {telegramLink ?
            <Row className="mt-2">
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <h6 className="text-light">Telegram</h6>
              </Col>

              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <span className="text-light d-flex justify-content-md-end">
                  {telegramLink ? telegramLink : 'N/A'}
                </span>
              </Col>
            </Row> : ""}

          {githubLink ?
            <Row className="mt-2">
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <h6 className="text-light">Github</h6>
              </Col>

              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <span className="text-light d-flex justify-content-md-end">
                  {githubLink ? githubLink : 'N/A'}
                </span>
              </Col>
            </Row> : ""}

          {instagramLink ?
            <Row className="mt-2">
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <h6 className="text-light">Instagram</h6>
              </Col>

              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <span className="text-light d-flex justify-content-md-end">
                  {instagramLink ? instagramLink : 'N/A'}
                </span>
              </Col>
            </Row> : ""}

          {discordLink ?
            <Row className="mt-2">
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <h6 className="text-light">Discord</h6>
              </Col>

              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <span className="text-light d-flex justify-content-md-end">
                  {discordLink ? discordLink : 'N/A'}
                </span>
              </Col>
            </Row> : ""}

          {redditLink ?
            <Row className="mt-2">
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <h6 className="text-light">Reddit</h6>
              </Col>

              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <span className="text-light d-flex justify-content-md-end">
                  {redditLink ? redditLink : 'N/A'}
                </span>
              </Col>
            </Row> : ""}

          {description ?
            <Row className="mt-2">
              <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                <h6 className="text-light">Description</h6>
              </Col>

              <Col xs={24} sm={24} md={18} lg={18} xl={18}>
                <span className="text-light d-flex justify-content-md-end">
                  {description ? <div> { ReactHtmlParser (description) } </div> : 'N/A'}
                </span>
              </Col>
            </Row> : ""}
        </div>

        <div className="d-flex justify-content-center mt-3">
          <Button
            style={{ marginRight: '5px' }}
            loading={isLoading || isPoolCreationLoading}
            type="default"
            onClick={handleBack}>
            Go Back
          </Button>
          <Button
            loading={isLoading || isPoolCreationLoading}
            type="primary"
            onClick={handlePrivateSaleCreation}>
            Create Funding Pool
          </Button>
        </div>
      </Card>
    </div>
  )
}
