import React, { useEffect, useState } from 'react'
import { Spin, Pagination } from 'antd';
import { Row, Col } from 'reactstrap'
import './AllPrivateSales.css'
import API from '../../../services/api'
import PrivateSaleWidget from '../../PrivateSales/PrivateSaleWidget';
import { useWeb3React } from '@web3-react/core';

function MyContributedPrivateSales() {

  const [allPools, setAllPools] = useState([])
  const [isLoading, setIsLoading] = useState([])
  const [totalPools, setTotalPools] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const { account } = useWeb3React()

  const onChange = (pageNumber) => {
    if (pageNumber !== currentPage) {
      setCurrentPage(pageNumber)
    }
  };

  useEffect(() => {
    getPools(currentPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, account])

  const getPools = (page) => {
    setIsLoading(true)
    API.get(`/private-sale/get-contributions/${account}?page=${page}&limit=9`)
      .then(res => {
        const payload = res.data.payload.items
        let privateSalesList = []
        if (payload && payload.length > 0) {
          privateSalesList = payload.map(item => {
            return item?.privateSale || []
          })
        }
        setAllPools(privateSalesList)

        const total = res.data.payload.meta.totalItems
        setTotalPools(total)
        setIsLoading(false)
      }).catch(error => {
        console.error("ERROR While listing private sale pools from API ", error)
        setIsLoading(false)
      })
  }

  return (
    <>
      {/* <div className="d-flex justify-content-end mt-3">
        <Select placeholder="Filter By" onChange={handleFilterChange}>
          <Option value="all_sales">All sales</Option>
          <Option value="upcoming">Upcoming</Option>
          <Option value="live">Live</Option>
          <Option value="listed_on_dex">Listed on dex</Option>
          <Option value="kyc">KYC</Option>
          <Option value="audit">Audit</Option>
          <Option value="gold">Gold</Option>
          <Option value="platinum">Platinum</Option>
          <Option value="diamond">Diamond</Option>
        </Select>

        <div className="sort-filter">
          <Select placeholder="Sort By" onChange={handleSortChange}>
            <Option value="no_filter">No filter</Option>
            <Option value="hard_cap">Hard cap</Option>
            <Option value="soft_cap">Soft cap</Option>
            <Option value="LP_percent">LP percent</Option>
            <Option value="start_time">Start time</Option>
            <Option value="end_time">End time</Option>
            <Option value="tier">Tier</Option>
          </Select>
        </div>
      </div> */}

      <div>
        {!isLoading ? (
          <Row>
            {allPools.map((item, index) => (
              <Col lg="4" md="6" sm="12" key={index}>
                <PrivateSaleWidget
                  data={item}
                  key={index}
                />
              </Col>
            ))}
          </Row>
        ) : (
          <div className='loading-container'>
            <Spin />
          </div>
        )}
        <div className="d-flex justify-content-center my-5">
          <Pagination
            total={totalPools}
            defaultPageSize={10}
            defaultCurrent={1}
            onChange={onChange}
          />
        </div>
      </div>
    </>
  )
}

export default MyContributedPrivateSales
