import { Row, Col } from 'reactstrap'
import { Card, Spin, Tag } from 'antd'
import Cover from '../../Images/cover.jpg'
import { configs } from '../../Blockchain/web3.config'
import NumberFormat from 'react-number-format'
import { utils } from 'ethers';
import { DateTime } from 'luxon'
import SocialLinks from '../PrivateSales/SocialLinks'
import { useEffect, useState } from 'react'
import RFCLogo from '../../Images/rfc-logo.svg'
import ReactHtmlParser from 'react-html-parser';
import Tags from '../PrivateSales/Tags'

export default function PoolTokenDetails(props) {

    const { privateSaleDetails, privateSaleAddress, isPrivateSaleLoading } = props
    const [formattedStartDate, setFormattedStartDate] = useState(DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss"))
    const [formattedEndDate, setFormattedEndDate] = useState(DateTime.now().toFormat("yyyy-MM-dd HH:mm:ss"))
    const calculateTokensForPool = () => {

        if (privateSaleDetails) {
            const tokenDecimals = parseInt(privateSaleDetails.tokenDecimals)
            const totalTokenAmount = parseInt(privateSaleDetails.totalTokenAmount)
            const actualTotalTokenAmount = totalTokenAmount / (10 ** tokenDecimals)
            return actualTotalTokenAmount
        } else {
            return 0
        }
    }

    const getPrivateSaleImage = () => {
        if (privateSaleDetails && privateSaleDetails.logoURL) {
            return privateSaleDetails.logoURL
        } else {
            return RFCLogo
        }
    }

    useEffect(() => {
        if (privateSaleDetails && privateSaleDetails.startTimeTimestamp) {
            const startTimeFormatted = DateTime.fromSeconds(parseInt(privateSaleDetails.startTimeTimestamp), { zone: "utc" })
                .toFormat("yyyy-MM-dd HH:mm:ss")
            setFormattedStartDate(startTimeFormatted)
        }

        if (privateSaleDetails && privateSaleDetails.endTimeTimestamp) {
            const endTimeFormatted = DateTime.fromSeconds(parseInt(privateSaleDetails.endTimeTimestamp), { zone: "utc" })
                .toFormat("yyyy-MM-dd HH:mm:ss")
            setFormattedEndDate(endTimeFormatted)
        }
    }, [privateSaleDetails])

    return (
        <Card className="mt-4">
            {
                isPrivateSaleLoading ? (
                    <div className='d-flex mt-2 justify-content-center'>
                        <Spin size="small" />
                    </div>
                ) : (
                    <div className="cover-area" style={{ backgroundImage: `url(${Cover})` }}></div>
                )
            }

            <Row>
                <Col lg="12" md="12" sm="12">
                    {
                        isPrivateSaleLoading ? (
                            <div className='d-flex mt-2 justify-content-center'>
                                <Spin size="small" />
                            </div>
                        ) : (
                            <div>
                                <div className='d-flex justify-content-between'>

                                    <div>
                                        <img className="user-profile-pic" src={getPrivateSaleImage()} alt="user" />
                                        <span className="profile-name">
                                            {privateSaleDetails ? privateSaleDetails.privateSaleName : <Tag color="#d3bc84">Pending</Tag>}
                                        </span>
                                    </div>

                                    <div className='mt-2 hide-on-small-devices'>
                                        <Tags
                                            privateSaleDetails={privateSaleDetails}
                                            isPrivateSaleLoading={isPrivateSaleLoading}
                                        />
                                    </div>
                                </div>
                                <div className='mt-2 left-margin hide-on-small-devices'>
                                    <SocialLinks
                                        privateSaleDetails={privateSaleDetails}
                                        isPrivateSaleLoading={isPrivateSaleLoading}
                                    />
                                </div>
                            </div>
                        )
                    }

                </Col>
            </Row>
            {/* <Row>
                <div className='d-flex justify-content-between align-items-start'>
                    <Send size={20} className="profile-contact-link" />
                </div>
            </Row> */}

            {
                isPrivateSaleLoading ? (
                    <div className='d-flex mt-2 justify-content-center'>
                        <Spin size="small" />
                    </div>
                ) : (
                    <div>
                        <Row className="mt-3">
                            <Col>
                                <p>
                                    {privateSaleDetails ? <div> {ReactHtmlParser(privateSaleDetails.description)} </div> : <Tag color="#d3bc84">Pending</Tag>}
                                </p>
                            </Col>
                        </Row>

                        <div className='hide-on-large-devices'>
                            <SocialLinks
                                privateSaleDetails={privateSaleDetails}
                                isPrivateSaleLoading={isPrivateSaleLoading}
                            />
                        </div>

                        <Row className="mt-4">
                            <Col>
                                Fund Pool Address
                            </Col>

                            <Col lg="9" md="9" className="text-md-end">
                                <a className="small" href={`${configs.blockExplorer}/address/${privateSaleAddress}`} rel="noreferrer" target="_blank">
                                    {privateSaleAddress ? privateSaleAddress : <Tag color="#d3bc84">Pending</Tag>}
                                </a><br />
                                <span className="small">Do not send BNB directly to the Funding pool address!</span>
                            </Col>
                        </Row>

                        <hr />

                        <Row className="mt-2">
                            <Col>
                                Token Name
                            </Col>

                            <Col className="text-end">
                                {privateSaleDetails ? privateSaleDetails.tokenName : <Tag color="#d3bc84">Pending</Tag>}
                            </Col>
                        </Row>

                        <hr />

                        <Row className="mt-2">
                            <Col>
                                Total Supply
                            </Col>

                            <Col className="text-end">
                                <NumberFormat
                                    value={privateSaleDetails ? privateSaleDetails.tokenTotalSupply : 0}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                />
                            </Col>
                        </Row>

                        <hr />

                        <Row className="mt-2">
                            <Col>
                                Tokens For Fund Pool
                            </Col>

                            <Col className="text-end">
                                <NumberFormat
                                    value={calculateTokensForPool()}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                />
                            </Col>
                        </Row>

                        <hr />

                        <Row className="mt-2">
                            <Col>
                                Hard Cap
                            </Col>

                            <Col className="text-end">
                                <NumberFormat
                                    value={privateSaleDetails ? utils.formatEther(privateSaleDetails.hardCap) : '0.00'}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    suffix={' ' + privateSaleDetails?.customCurrencySymbol}
                                />
                            </Col>
                        </Row>

                        <hr />

                        <Row className="mt-2">
                            <Col>
                                Fund Pool Start Time (UTC)
                            </Col>

                            <Col className="text-end">
                                {formattedStartDate ? formattedStartDate : 'N/A'}
                            </Col>
                        </Row>

                        <hr />

                        <Row className="mt-2">
                            <Col>
                                Whitelisting Status
                            </Col>

                            <Col className="text-end">
                                {privateSaleDetails ? privateSaleDetails.isWhiteListingEnabled ? 'Active' : 'Inactive' : 'N/A'}
                            </Col>
                        </Row>

                        <hr />

                        <Row className="mt-2">
                            <Col>
                                Public Sales Time (UTC)
                            </Col>

                            <Col className="text-end">
                                {
                                    privateSaleDetails ? privateSaleDetails.publicSaleStartTimestamp !== "0" ?
                                        DateTime.fromSeconds(parseInt(privateSaleDetails.publicSaleStartTimestamp), { zone: "utc" }).toFormat("yyyy-MM-dd HH:mm:ss") : 'N/A' : 'N/A'}
                            </Col>
                        </Row>

                        <hr />

                        <Row className="mt-2">
                            <Col>
                                Fund Pool End Time (UTC)
                            </Col>

                            <Col className="text-end">
                                {formattedEndDate ? formattedEndDate : 'N/A'}
                            </Col>
                        </Row>

                        <hr />

                        <Row className="mt-2">
                            <Col>
                                Unused Tokens
                            </Col>

                            <Col className="text-end">
                                Refund
                            </Col>
                        </Row>
                    </div>
                )
            }
        </Card>
    )
}