import React, { useEffect, useState } from 'react'
import { Layout } from 'antd';
import SiderComponent from './Components/Sider/SiderComponent';
import Navbar from './Components/Navbar/Navbar';
import Pages from './Pages/Pages';
import { useLocation } from 'react-router';

// footer logos
import rugfreecoins from './Images/rugfreecoins.png'
import versa from './Images/versatile_logo.png'

function LayoutView() {

    const { Content, Footer } = Layout;
    const location = useLocation();
    const [hideSider, setHideSider] = useState(false)
    const [margin, setMargin] = useState(220)

    useEffect(() => {
        if (location.pathname === '/login' || location.pathname === '/register') {
            setHideSider(true)
            setMargin(0)
        } else {
            setHideSider(false)
            setMargin(220)
        }
    }, [location.pathname])

    return (
        <Layout>

            {
                !hideSider && <SiderComponent />
            }


            <Layout
                className="site-layout"
                style={{ marginLeft: margin }}
            >

                <Navbar />

                <Content>
                    <div className="site-layout-background" style={{ minHeight: '100vh', paddingTop: '100px' }}>
                        <Pages />
                    </div>
                </Content>

                <Footer style={{ textAlign: 'center', backgroundColor: '#171717' }}>
                    <span className="text-muted">
                        COPYRIGHT © {new Date().getFullYear()} collaborated by <br />
                        <a href="https://versatile.finance" rel="noreferrer" target="_blank">Versatile Finance</a> and developed by
                        <a href="https://www.rugfreecoins.com" rel="noreferrer" target="_blank"> rugfreecoins</a>. <br />
                        All rights Reserved
                        {
                            process.env.REACT_APP_ENV === 'dev' ? (<div className='env-container'>
                                application running on dev instance
                            </div>) : (<></>)
                        }
                        <br />
                        <img src={rugfreecoins} alt="rugfree_coins_logo" style={{ width: "30px" }} />
                        <img src={versa} alt="rugfree_coins_logo" style={{ width: "30px", marginLeft: '15px' }} />
                    </span>
                </Footer>

            </Layout>

        </Layout>
    )
}

export default LayoutView
