import React from 'react'
import { Card, } from 'antd'
import {
    FacebookShareButton,
    TwitterShareButton,
    TelegramShareButton,
    PinterestShareButton,
    RedditShareButton
} from "react-share";
import {
    FacebookIcon,
    TwitterIcon,
    TelegramIcon,
    PinterestIcon,
    RedditIcon
} from "react-share";

export default function SocialShareWidget() {

    const url = window.location.href
    console.log(url);

    return (
        <Card className="mt-4">
            Share on Social Media
            <div className='d-flex justify-content-between mt-4'>
                <FacebookShareButton
                    url={url}
                    quote={"pool"}
                    hashtag={"#hashtag"}
                    description={"pool"}
                    className="Demo__some-network__share-button"
                >
                    <FacebookIcon size={32} round />
                </FacebookShareButton>
                <TwitterShareButton
                    title={"test"}
                    url={url}
                    hashtags={["hashtag1", "hashtag2"]}
                    className="px-1"
                >
                    <TwitterIcon size={32} round />
                </TwitterShareButton>
                <PinterestShareButton
                    url={url}
                >
                    <PinterestIcon size={32} round />
                </PinterestShareButton>
                <TelegramShareButton
                    url={url}
                >
                    <TelegramIcon size={32} round />
                </TelegramShareButton>
                <RedditShareButton
                    url={url}
                >
                    <RedditIcon size={32} round />
                </RedditShareButton>
            </div>
        </Card>
    )
}