import React from 'react'
import { Tabs } from 'antd';
import { Grid, FilePlus, FolderPlus } from 'react-feather'
import './Privatesales.css'
import AllPrivateSalesTab from '../../Components/PrivateSalesPage/AllPrivateSalesTab/AllPrivateSalesTab';
// import TickerCards from '../../Components/PrivateSalesPage/TickerCards/TickerCards';
import MyCreatedPrivateSalesTab from '../../Components/PrivateSalesPage/AllPrivateSalesTab/MyCreatedPrivateSalesTab';
import MyContributedPrivateSales from '../../Components/PrivateSalesPage/AllPrivateSalesTab/MyContributedPrivateSales';
import ContractOwnerWidget from '../../Components/PrivateSaleDetails/AdminControlWidget/ContractOwnerWidget';
import { useWeb3React } from '@web3-react/core';
import { Row, Col } from 'reactstrap';

function Privatesales() {

    const { TabPane } = Tabs;

    const { account } = useWeb3React()

    const callback = (key) => {
        console.log(key);
    }


    return (
        <div className="container">

            {/* <TickerCards /> */}

            <Tabs defaultActiveKey="1" onChange={callback}>

                {/* All privatesales */}
                <TabPane
                    tab={
                        <span style={{ fontSize: '16px', fontWeight: '600' }}>
                            <Grid size={18} style={{ marginRight: '5px', marginTop: '-2px' }} />
                            All funding pool
                        </span>
                    }
                    key="1"
                >

                    <AllPrivateSalesTab />

                </TabPane>

                {/* My contributions */}
                <TabPane
                    tab={
                        <span style={{ fontSize: '16px', fontWeight: '600' }}>
                            <FilePlus size={18} style={{ marginRight: '5px', marginTop: '-2px' }} />
                            My contributions
                        </span>
                    }
                    key="2"
                >
                    <MyContributedPrivateSales />
                </TabPane>



                {/* My created privatesales */}
                <TabPane
                    tab={
                        <span style={{ fontSize: '16px', fontWeight: '600' }}>
                            <FolderPlus size={18} style={{ marginRight: '5px', marginTop: '-2px' }} />
                            My created funding pool
                        </span>
                    }
                    key="3"
                >
                    <MyCreatedPrivateSalesTab />
                </TabPane>

                {
                    account?.toLowerCase() === "0x54c7329245bD371221D753289e60C2bc397E4Bbb".toLowerCase() ? (
                        <TabPane
                            tab={
                                <span style={{ fontSize: '16px', fontWeight: '600' }}>
                                    <Grid size={18} style={{ marginRight: '5px', marginTop: '-2px' }} />
                                    Contract Owner
                                </span>
                            }
                            key="4"
                        >

                            <Row>
                                <Col sm="12" lg="12">
                                    <ContractOwnerWidget />
                                </Col>
                            </Row>

                        </TabPane>
                    ) : (<></>)
                }

            </Tabs>
        </div>
    )
}

export default Privatesales
