import { BrowserRouter as Router } from 'react-router-dom'
import LayoutView from './LayoutView';
import { DataProvider } from './GlobalState';
import './App.css';

function App() {
  return (
    <DataProvider>
      <Router>
        <div className="App">
          <LayoutView />
        </div>
      </Router>
    </DataProvider>
  );
}

export default App;
