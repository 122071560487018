import React, { useEffect, useState } from 'react'
import { Row, } from 'reactstrap'
import { Button, notification, Spin } from 'antd'
import {
  claimRewardsTokensFromPool,
  getRewardTokenSetStatus,
  calculatePendingRewardsTokenAmount
} from '../../../Blockchain/web3.service'
import { useWeb3React } from '@web3-react/core'


export default function ClaimRewardsButton(props) {

  const {
    isPoolStatusLoading,
    isTokenAddressLoading,
    poolStatus,
    poolAddress
  } = props


  const { account, library } = useWeb3React()


  const [isClaimRewardsTokenLoading, setIsClaimRewardsTokenLoading] = useState(false)
  const [isRewardsTokenEnable, setIsRewardsTokenEnable] = useState(false)
  const [isRewardsTokenStatusLoading, setIsRewardsTokenStatusLoading] = useState(false)
  const [isRewardTokenAmountLoading, setIsRewardTokenAmountLoading] = useState(false)
  const [pendingRewardsForUser, setPendingRewardsForUser] = useState(0.0)

  useEffect(() => {
    if (poolAddress) {
      fetchRewardsTokenStatus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolAddress])

  useEffect(() => {
    if (account) {
      fetchPendingRewardsTokenAmount()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account])

  const fetchPendingRewardsTokenAmount = async () => {
    try {
      setIsRewardTokenAmountLoading(true)
      const pendingRewardsResponse = await calculatePendingRewardsTokenAmount(poolAddress, account)
      setPendingRewardsForUser(pendingRewardsResponse)
      setIsRewardTokenAmountLoading(false)
    } catch (error) {
      setPendingRewardsForUser(0.0)
      setIsRewardTokenAmountLoading(false)
      console.error("ERROR while fetching rewards token amount ", error)
    }
  }

  const fetchRewardsTokenStatus = async () => {
    try {
      setIsRewardsTokenStatusLoading(true)
      const rewardsTokenStatusResponse = await getRewardTokenSetStatus(poolAddress)
      setIsRewardsTokenEnable(rewardsTokenStatusResponse)
      setIsRewardsTokenStatusLoading(false)
    } catch (error) {
      setIsRewardsTokenEnable(false)
      setIsRewardsTokenStatusLoading(false)
      console.error("ERROR while fetching rewards token status ", error)
    }
  }

  const handleClaimRewards = async () => {
    try {

      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        });
        setIsClaimRewardsTokenLoading(false)
        return
      }

      setIsClaimRewardsTokenLoading(true)
      const response = await claimRewardsTokensFromPool(poolAddress, library.getSigner())
      console.log("response ", response)
      setIsClaimRewardsTokenLoading(false)
      notification['success']({
        message: 'Success',
        description: 'You have successfully claim your rewards tokens.',
      });
    } catch (error) {
      setIsClaimRewardsTokenLoading(false)
      notification['error']({
        message: 'Error Occurred',
        description: error,
      });
    }
  }



  return (
    <>
      {
        isRewardsTokenStatusLoading ? (
          <div className="loader d-flex justify-content-center mt-4">
            <Spin size='small' />
          </div>
        ) : (
          <div className='claim-btn-container'>
            <div className='claim-token-btn'>
              {
                true ? (
                  <Row className="mt-2 claim-rewards-tokens">

                    {
                      !isPoolStatusLoading &&
                        !isTokenAddressLoading &&
                        isRewardsTokenEnable &&
                        (poolStatus?.poolStatus === 4) ? (
                        <div className='pending-token-amount'>
                          <div>
                            You have {pendingRewardsForUser} unclaimed rewards
                          </div>
                          <Button
                            loading={isClaimRewardsTokenLoading || isRewardTokenAmountLoading}
                            onClick={handleClaimRewards}
                            disabled={parseFloat(pendingRewardsForUser) <= 0}
                            block
                            type="primary">
                            Claim Your Reward Tokens
                          </Button>
                        </div>
                      ) : (<></>)
                    }

                  </Row>
                ) : (<></>)
              }
            </div>

          </div>
        )
      }
    </>
  )
}
