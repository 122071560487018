import React from 'react'
import { Layout } from 'antd';
import MenuComponent from '../Menu/MenuComponent';

// social icons
import facebook from '../../Images/social/facebook.png'
import twitter from '../../Images/social/twitter.png'
import telegram from '../../Images/social/telegram.png'
import email from '../../Images/social/email.png'

function SiderComponent() {

    const { Sider } = Layout;

    return (
        <Sider width={220}
            style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                borderRight: '1px solid rgba(153, 153, 153, .5)',
                left: 0,
                top: 0,
                bottom: 0,
            }}
            className="hide-on-small-devices">


            <MenuComponent />

            <div className='social-icons'>
                <a href='https://facebook.com'>
                    <img alt="facebook" src={facebook} style={{ width: '25px', marginLeft: '43px' }} />
                </a>
                <a href='https://twitter.com'>
                    <img alt="twitter" src={twitter} style={{ width: '25px', marginLeft: '10px' }} />
                </a>
                <a href='https://telegran.com'>
                    <img alt="telegram" src={telegram} style={{ width: '25px', marginLeft: '10px' }} />
                </a>
                <span className='nav-bar-link'>
                    <img alt="email" src={email} style={{ width: '25px', marginLeft: '10px' }} />
                </span>
            </div>

        </Sider>
    )
}

export default SiderComponent
