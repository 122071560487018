import React, { useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap'
import './PrivatesaleDetail.css'
import { ArrowLeftCircle } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import ContributionWidget from '../../Components/PrivateSaleDetails/ContributionWidget/ContributionWidget'
import ListDexWidget from '../../Components/PrivateSaleDetails/ListDexWidget'
import FundingPoolWidget from '../../Components/PrivateSaleDetails/FundingPoolWidget'
import PoolTokenDetails from '../../Components/PrivateSaleDetails/PoolTokenDetails'
import SocialShareWidget from '../../Components/PrivateSaleDetails/SocialShareWidget'
import { getTheOwnerOfThePool, getThePoolAdmin } from '../../Blockchain/web3.service'
import { useWeb3React } from '@web3-react/core'
import AdminControlWidget from '../../Components/PrivateSaleDetails/AdminControlWidget/AdminControlWidget'
// import TokonomasPieChart from '../../Components/PrivateSaleDetails/TokenomicsPieChart'
import VestingDetailsWidget from '../../Components/PrivateSaleDetails/VestingDetailsWidget'
import WhitelistedAddressWidget from '../../Components/PrivateSaleDetails/WhitelistedAddressWidget'
import BEP20TokenWithdrawWidget from '../../Components/PrivateSaleDetails/AdminControlWidget/BEP20TokenWithdrawWidget'
import DistributeBNBWidget from '../../Components/PrivateSaleDetails/AdminControlWidget/DistributeBNBWidget'
import DistributeTokens from '../../Components/PrivateSaleDetails/AdminControlWidget/DistributeTokens'

function PrivatesaleDetail() {

    const navigate = useNavigate();
    const privateSaleAddress = useParams().privatesale_address
    const [privateSaleDetails, setPrivateSaleDetails] = useState(null)
    const [isPrivateSaleLoading, setIsPrivateSaleLoading] = useState(false)
    const [isPoolAdmin, setIsPoolAdmin] = useState(false)
    const [ownerAddress, setOwnerAddress] = useState(false)
    const [poolAdmin, setPoolAdmin] = useState('')
    const { account } = useWeb3React()

    useEffect(() => {
        if (privateSaleAddress) {
            fetchPrivateSalePoolDetails()
            fetchThePoolOwner()
            fetchDeployerAddress()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [privateSaleAddress])

    useEffect(() => {
        if (ownerAddress && account) {
            if (ownerAddress.toLowerCase() === account.toLowerCase() || poolAdmin.toLowerCase() === account.toLowerCase()) {
                setIsPoolAdmin(true)
            } else {
                setIsPoolAdmin(false)
            }
        } else {
            setIsPoolAdmin(false)
        }
    }, [ownerAddress, account, poolAdmin])

    const fetchPrivateSalePoolDetails = async () => {
        try {
            setIsPrivateSaleLoading(true)
            const endpoint = `${process.env.REACT_APP_API_BASE_URL}/private-sale/get/${privateSaleAddress}`
            const response = await axios.get(endpoint)
            if (response.status === 200) {
                setPrivateSaleDetails(response.data.payload || null)
            } else {
                setPrivateSaleDetails(null)
            }
            setIsPrivateSaleLoading(false)
        } catch (error) {
            console.error("ERROR while fetching api data for pool ", error)
            setIsPrivateSaleLoading(false)
            setPrivateSaleDetails(null)
        }
    }

    const fetchThePoolOwner = async () => {
        try {
            const response = await getTheOwnerOfThePool(privateSaleAddress)
            if (response) {
                setOwnerAddress(response)
            } else {
                setOwnerAddress(null)
            }
        } catch (error) {
            setOwnerAddress(null)
            console.error("ERROR while fetching the pool owner ", error)
        }
    }

    const fetchDeployerAddress = async () => {
        try {
            const response = await getThePoolAdmin(privateSaleAddress)
            if (response) {
                setPoolAdmin(response)
            } else {
                setPoolAdmin(null)
            }
        } catch (error) {
            setPoolAdmin(null)
            console.error("ERROR while fetching the pool admin ", error)
        }
    }



    const handleBack = () => {
        navigate('/fundingpool')
    }

    return (
        <div className="container mb-5">
            <Row>
                <Col className="text-start">
                    <div className="d-flex">
                        <ArrowLeftCircle className="back-button" onClick={handleBack} /> <h5 style={{ marginLeft: '10px' }}>Back</h5>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col lg="8" md="8" sm="12">

                    <div className='hide-on-large-devices'>
                        <ContributionWidget
                            poolAddress={privateSaleAddress}
                            privateSaleDetails={privateSaleDetails}
                        />
                    </div>


                    <PoolTokenDetails
                        privateSaleDetails={privateSaleDetails}
                        privateSaleAddress={privateSaleAddress}
                        isPrivateSaleLoading={isPrivateSaleLoading}
                    />


                    <VestingDetailsWidget
                        privateSaleAddress={privateSaleAddress}
                        isPrivateSaleLoading={isPrivateSaleLoading}
                    />

                    <WhitelistedAddressWidget
                        privateSaleAddress={privateSaleAddress}
                        isPrivateSaleLoading={isPrivateSaleLoading}
                    />

                    {/* <div className='hide-on-small-devices'>
                        <TokonomasPieChart
                            privateSaleDetails={privateSaleDetails}
                            privateSaleAddress={privateSaleAddress}
                            isPrivateSaleLoading={isPrivateSaleLoading}
                        />
                    </div> */}

                </Col>



                <Col lg="4" md="4" sm="12">

                    <SocialShareWidget />

                    <div className='hide-on-small-devices'>
                        <ContributionWidget
                            poolAddress={privateSaleAddress}
                            privateSaleDetails={privateSaleDetails}
                        />
                    </div>


                    <ListDexWidget
                        privateSaleAddress={privateSaleAddress}
                        privateSaleDetails={privateSaleDetails}
                        isPrivateSaleLoading={isPrivateSaleLoading}
                    />

                    <FundingPoolWidget
                        privateSaleDetails={privateSaleDetails}
                        privateSaleAddress={privateSaleAddress}
                        isPrivateSaleLoading={isPrivateSaleLoading}
                    />

                    {/* <div className='hide-on-large-devices'>
                        <TokonomasPieChart
                            privateSaleDetails={privateSaleDetails}
                            privateSaleAddress={privateSaleAddress}
                            isPrivateSaleLoading={isPrivateSaleLoading}
                        />
                    </div> */}

                    {
                        isPoolAdmin ? (
                            <AdminControlWidget
                                privateSaleAddress={privateSaleAddress}
                            />
                        ) : (<></>)
                    }

                    <BEP20TokenWithdrawWidget privateSaleAddress={privateSaleAddress} />
                    <DistributeBNBWidget privateSaleAddress={privateSaleAddress} />
                    <DistributeTokens privateSaleAddress={privateSaleAddress} />
                </Col>
            </Row>
        </div>
    )
}

export default PrivatesaleDetail
