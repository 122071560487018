import React from 'react'
import { useNavigate } from 'react-router-dom';
import { Menu } from 'antd';
import { AppstoreOutlined, RocketOutlined } from '@ant-design/icons';
// import { Send, Shield, FileText } from 'react-feather';
import './MenuComponent.css'
import Logo from '../../Images/logo.png'

function MenuComponent() {

  const navigate = useNavigate()
  const getItem = (label, key, icon, children, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }

  const items = [

    getItem('Funding Pool', 'fundingpool', <AppstoreOutlined />),
    // getItem('Special Sales', 'sub2', <DollarOutlined />, [
    //     getItem('Explore', 'specials'),
    //     getItem('My Contributions', 'specials/mycontributions'),
    //     getItem('My Alarms', 'specials/myalarms'),
    // ]),
    getItem('Start a Project', 'fundingpoolcontribution', <RocketOutlined />),
    // getItem('Locks', 'sub4', <LockOutlined />, [
    //     getItem('Create Lock', 'create-lock'),
    //     getItem('Token Lock', 'lock/token'),
    //     getItem('Liquidity Lock', 'lock/liquidity')
    // ]),
    // getItem('Utility & Tools', 'sub5', <ToolOutlined />, [
    //     getItem('Airdrop', 'airdrop'),
    //     getItem('Stake', 'stake')
    // ]),
    // getItem('Listing Alerts (Beta)', 'alerts', <Send size={16} />),
    // getItem('KYC & Audit', 'audit', <Shield size={16} />),
    // getItem('Docs', 'docs', <FileText size={16} />),
  ];


  const onClick = (e) => {
    navigate(e.key);
  };


  return (
    <>
      <img alt='menu-item' src={Logo} style={{ width: '200px', padding: '10px' }} />
      <Menu
        mode="inline"
        defaultSelectedKeys={['fundingpool']}
        theme="dark"
        onClick={onClick}
        style={{ marginTop: '30px' }}
        items={items}
      >

      </Menu>
    </>
  )
}

export default MenuComponent
