import React, { useEffect, useState } from 'react'
import { Tag } from 'antd'

export default function Tags(props) {

    const { privateSaleDetails, isPrivateSaleLoading } = props

    const [tags, setTags] = useState([])

    useEffect(() => {
        if (!isPrivateSaleLoading && privateSaleDetails) {
            if (privateSaleDetails.tags) {
                let tagsArray = privateSaleDetails.tags.split(',')
                setTags(tagsArray)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [privateSaleDetails, privateSaleDetails])

    return (
        <>
            {
                isPrivateSaleLoading ? (
                    <></>
                ) : (
                    <>
                        {tags.map((item, index) => (
                            <Tag color={'green'} key={index}>
                                {item}
                            </Tag>
                        ))}
                    </>


                )
            }

        </>
    )
}