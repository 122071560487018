import { Row, Col } from 'reactstrap'
import { Card, Spin } from 'antd'
import NumberFormat from 'react-number-format'
import { useEffect, useState } from 'react';
import * as web3Service from '../../Blockchain/web3.service'
import useUserPrivateSalePoolDetails from '../../Hooks/useUserPrivateSalePoolDetails';
import { useWeb3React } from '@web3-react/core';

export default function ListDexWidget(props) {

    const { privateSaleDetails, isPrivateSaleLoading, privateSaleAddress } = props
    const [minimumBuy, setMinimumBuy] = useState(0.0)
    const [maximumBuy, setMaximumBuy] = useState(0.0)
    const [totalContributorCount, setTotalContributorCount] = useState(0.0)
    const [tokenPerBNB, setTokenPerBNB] = useState(0.0)

    const [isMinMaxAmountLoading, setIsMinMaxAmountLoading] = useState(false)
    // const [isTotalContributorCountLoading, setIsTotalContributorCountLoading] = useState(false)
    const [isTokenPerBNBLoading, setIsTokenPerBNBLoading] = useState(false)
    const { account } = useWeb3React()
    const {
        isLoading: isUserContributionDetailsLoading,
        userPrivateSalePoolDetails
    } = useUserPrivateSalePoolDetails({ poolAddress: privateSaleAddress, walletAddress: account, isWithoutToken: privateSaleDetails?.isWithoutToken })
    useEffect(() => {
        fetchMinMaxContributionAmounts()
        if (privateSaleDetails && privateSaleDetails.tokenAddress) {
            fetchTokenPerBNBAmountInPool()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [privateSaleAddress, privateSaleDetails])

    useEffect(() => {

        const interval = setInterval(() => {
            if (privateSaleAddress) {
                fetchTotalContributorCount(privateSaleAddress)
            }
            //update every 2 seconds 
        }, 5000)
        return () => clearInterval(interval)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [privateSaleAddress])

    const fetchMinMaxContributionAmounts = async () => {
        try {
            setIsMinMaxAmountLoading(true)
            const minMaxAmountResponse = await web3Service.getMinAndMaxContributionAmount(privateSaleAddress)
            setMinimumBuy(minMaxAmountResponse.minContributionAmount)
            setMaximumBuy(minMaxAmountResponse.maxContributionAmount)
            setIsMinMaxAmountLoading(false)
        } catch (error) {
            setMinimumBuy(0.0)
            setMaximumBuy(0.0)
            setIsMinMaxAmountLoading(false)
            console.error("ERROR Fetching min max contribution amounts : ", error)
        }
    }

    const fetchTotalContributorCount = async () => {
        try {
            //setIsTotalContributorCountLoading(true)
            const contributorCountResponse = await web3Service.getTotalContributorsCount(privateSaleAddress)
            setTotalContributorCount(contributorCountResponse)
            //setIsTotalContributorCountLoading(false)
        } catch (error) {
            setTotalContributorCount(0)
            //setIsTotalContributorCountLoading(false)
            console.error("ERROR Fetching total contributor count : ", error)
        }
    }

    const fetchTokenPerBNBAmountInPool = async () => {
        try {
            setIsTokenPerBNBLoading(true)
            const tokenPerBNBResponse = await web3Service.getTokensPerBNBInPool(privateSaleAddress)
            setTokenPerBNB(tokenPerBNBResponse)
            //setTotalContributorCount(contributorCountResponse)
            setIsTokenPerBNBLoading(false)
        } catch (error) {
            setTokenPerBNB(0)
            setIsTokenPerBNBLoading(false)
            console.error("ERROR Fetching tokens per bnb amount in the pool : ", error)
        }
    }

    return (

        <Card className="mt-4">
            <Row>
                <div className="text-center">Private Sale Pool Details</div>
            </Row>

            <div>
                {
                    isPrivateSaleLoading ? (
                        <div className='d-flex mt-2 justify-content-center'>
                            <Spin size="small" />
                        </div>
                    ) : (
                        <div>
                            <Row className="mt-2">
                                <Col>
                                    Minimum Buy
                                </Col>

                                <Col className="text-end">

                                    {
                                        isMinMaxAmountLoading ? (
                                            <div className=''>
                                                <Spin size='small' />
                                            </div>
                                        ) : (
                                            <NumberFormat
                                                value={minimumBuy ? minimumBuy : '0.00'}
                                                decimalScale={2}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                suffix={' ' + privateSaleDetails?.customCurrencySymbol}
                                            />
                                        )
                                    }

                                </Col>
                            </Row>

                            <hr />

                            <Row className="mt-2">
                                <Col>
                                    Maximum Buy
                                </Col>

                                <Col className="text-end">
                                    {
                                        isMinMaxAmountLoading ? (
                                            <div className=''>
                                                <Spin size='small' />
                                            </div>
                                        ) : (
                                            <NumberFormat
                                                value={maximumBuy ? maximumBuy : '0.00'}
                                                displayType={'text'}
                                                decimalScale={2}
                                                thousandSeparator={true}
                                                suffix={' ' + privateSaleDetails?.customCurrencySymbol}
                                            />
                                        )
                                    }
                                </Col>
                            </Row>

                            <hr />

                            <Row className="mt-2">
                                <Col>
                                    Total Contributors
                                </Col>

                                <Col className="text-end">
                                    {/* {
                                        isTotalContributorCountLoading ? (
                                            <div className=''>
                                                <Spin size='small' />
                                            </div>
                                        ) : (
                                            <NumberFormat
                                                value={totalContributorCount ? totalContributorCount : '0'}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                            />
                                        )
                                    } */}
                                    <NumberFormat
                                        value={totalContributorCount ? totalContributorCount : '0'}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                    />
                                </Col>
                            </Row>

                            <hr />

                            <Row className="mt-2">
                                <Col>
                                    My Contribution
                                </Col>

                                <Col className="text-end">

                                    {
                                        isUserContributionDetailsLoading ? (
                                            <div className=''>
                                                <Spin size='small' />
                                            </div>
                                        ) : (
                                            <NumberFormat
                                                value={userPrivateSalePoolDetails ? userPrivateSalePoolDetails?.myContributionInBNB : '0.00'}
                                                decimalScale={2}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                suffix={' ' + privateSaleDetails?.customCurrencySymbol}
                                            />
                                        )
                                    }
                                </Col>
                            </Row>

                            <hr />


                            {
                                privateSaleDetails?.tokenAddress ? (
                                    <>
                                        <Row className="mt-2">
                                            <Col>
                                                My Reserved Tokens
                                            </Col>

                                            <Col className="text-end">
                                                {
                                                    isUserContributionDetailsLoading ? (
                                                        <div className=''>
                                                            <Spin size='small' />
                                                        </div>
                                                    ) : (
                                                        <NumberFormat
                                                            value={userPrivateSalePoolDetails ? userPrivateSalePoolDetails?.userTokenReserved : '0.00'}
                                                            decimalScale={2}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            suffix={privateSaleDetails ? "  " + privateSaleDetails?.tokenSymbol : ''}
                                                        />
                                                    )
                                                }
                                            </Col>

                                        </Row>
                                        <hr />
                                    </>

                                ) : (<></>)
                            }

                            {

                                privateSaleDetails?.tokenAddress ? (
                                    <>
                                        <Row className="mt-2">
                                            <Col>
                                                Fund Pool Rate
                                            </Col>

                                            <Col className="text-end">
                                                {
                                                    isTokenPerBNBLoading ? (
                                                        <div className=''>
                                                            <Spin size='small' />
                                                        </div>
                                                    ) : (
                                                        <NumberFormat
                                                            value={tokenPerBNB ? tokenPerBNB : '0'}
                                                            displayType={'text'}
                                                            thousandSeparator={true}
                                                            suffix={privateSaleDetails ? "  " + privateSaleDetails?.tokenSymbol : ''}
                                                        />
                                                    )
                                                }
                                            </Col>
                                        </Row>
                                    </>
                                ) : (<></>)
                            }



                        </div>
                    )
                }
            </div>
        </Card>
    )
}