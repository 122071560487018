/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react"
import * as web3Service from '../Blockchain/web3.service'

export const useUserPrivateSalePoolDetails = (props) => {

  const { poolAddress, walletAddress, isWithoutToken, forcedRefresh } = props
  const [userPrivateSalePoolDetails, setUserPrivateSalePoolDetails] = useState(0.00)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    if (walletAddress && poolAddress) {
      fetchUserContributionDetails()
    }

  }, [poolAddress, walletAddress, isWithoutToken])

  useEffect(() => {
    if (forcedRefresh) {
      fetchUserContributionDetails()
    }

  }, [forcedRefresh])

  const fetchUserContributionDetails = async () => {
    try {
      setLoading(true)
      const userPrivateSaleDetailsResponse = await web3Service.getUserContributionDetails(poolAddress, walletAddress, isWithoutToken)
      setUserPrivateSalePoolDetails(userPrivateSaleDetailsResponse)
      setLoading(false)
    } catch (error) {
      console.error("ERROR HOOK : while fetching user contribution details ", error)
      setLoading(false)
    }
  }


  return { userPrivateSalePoolDetails, isLoading }
}


export default useUserPrivateSalePoolDetails

