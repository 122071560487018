import React, { useState } from 'react'
import { Row } from 'reactstrap'
import { Card, Button, notification, Input } from 'antd'
import { useWeb3React } from '@web3-react/core'
import {
  setPoolCreationFeeWithTokenNonBNB,
  setPoolCreationFeeWithoutTokenNonBNB,
  setPoolCreationFeeWithTokenBNB,
  setPoolCreationFeeWithoutTokenBNB
} from '../../../Blockchain/web3.service'



export default function ContractOwnerWidget() {


  const { account, library } = useWeb3React()

  const [poolCreationFeeInWei, setPoolCreationFeeInWei] = useState(0)
  const [isPoolCreationFeeExecutionLoading, setIsPoolCreationFeeExecutionLoading] = useState(false)



  const handlePoolCreationForBNBWithoutToken = async () => {
    try {

      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        });
        setIsPoolCreationFeeExecutionLoading(false)
        return
      }

      setIsPoolCreationFeeExecutionLoading(true)
      const response = await setPoolCreationFeeWithoutTokenBNB(poolCreationFeeInWei, library.getSigner())
      console.log("response ", response)
      setIsPoolCreationFeeExecutionLoading(false)
      notification['success']({
        message: 'Success',
        description: 'Pool creation fee has been changed.',
      });
    } catch (error) {
      console.error("ERROR while changing pool creation fee ", error)
      setIsPoolCreationFeeExecutionLoading(false)
      notification['error']({
        message: 'Transaction Execution Failed',
        description: error,
      });

    }
  }


  const handlePoolCreationForBNBWithToken = async () => {
    try {

      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        });
        setIsPoolCreationFeeExecutionLoading(false)
        return
      }

      setIsPoolCreationFeeExecutionLoading(true)
      const response = await setPoolCreationFeeWithTokenBNB(poolCreationFeeInWei, library.getSigner())
      console.log("response ", response)
      setIsPoolCreationFeeExecutionLoading(false)
      notification['success']({
        message: 'Success',
        description: 'Pool creation fee has been changed.',
      });
    } catch (error) {
      console.error("ERROR while changing pool creation fee ", error)
      setIsPoolCreationFeeExecutionLoading(false)
      notification['error']({
        message: 'Transaction Execution Failed',
        description: error,
      });

    }
  }


  const handlePoolCreationForNonBNBWithToken = async () => {
    try {

      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        });
        setIsPoolCreationFeeExecutionLoading(false)
        return
      }

      setIsPoolCreationFeeExecutionLoading(true)
      const response = await setPoolCreationFeeWithTokenNonBNB(poolCreationFeeInWei, library.getSigner())
      console.log("response ", response)
      setIsPoolCreationFeeExecutionLoading(false)
      notification['success']({
        message: 'Success',
        description: 'Pool creation fee has been changed.',
      });
    } catch (error) {
      console.error("ERROR while changing pool creation fee ", error)
      setIsPoolCreationFeeExecutionLoading(false)
      notification['error']({
        message: 'Transaction Execution Failed',
        description: error,
      });

    }
  }

  const handlePoolCreationForNonBNBWithoutToken = async () => {
    try {

      if (!account) {
        notification['error']({
          message: 'Authentication Error',
          description:
            'Please connect your wallet to proceed',
        });
        setIsPoolCreationFeeExecutionLoading(false)
        return
      }

      setIsPoolCreationFeeExecutionLoading(true)
      const response = await setPoolCreationFeeWithoutTokenNonBNB(poolCreationFeeInWei, library.getSigner())
      console.log("response ", response)
      setIsPoolCreationFeeExecutionLoading(false)
      notification['success']({
        message: 'Success',
        description: 'Pool creation fee has been changed.',
      });
    } catch (error) {
      console.error("ERROR while changing pool creation fee ", error)
      setIsPoolCreationFeeExecutionLoading(false)
      notification['error']({
        message: 'Transaction Execution Failed',
        description: error,
      });

    }
  }

  return (
    <div className='owner-section'>
      {
        account?.toLowerCase() === "0x54c7329245bD371221D753289e60C2bc397E4Bbb".toLowerCase() ? (
          <Card className="mt-4 admin-control-card">
            <Row>
              <div className="text-center">Contract Owner Widget (Danger Zone)</div>
            </Row>

            <Row className="mt-4">
              <span className='input-label'>
                Set pool creation fee (WEI)
              </span>
            </Row>
            <Row className="" style={{ padding: '0 10px' }}>

              <Input
                value={poolCreationFeeInWei}
                onChange={e => setPoolCreationFeeInWei(e.target.value)}
              />
            </Row>

            <Row className="mt-2">
              <div className='contribution-button-container mt-2'>
                <Button
                  loading={isPoolCreationFeeExecutionLoading}
                  onClick={handlePoolCreationForBNBWithoutToken}
                  block={true}
                  disabled={isPoolCreationFeeExecutionLoading}
                  type="primary">
                  Set Fee (without token BNB Pool)
                </Button>
              </div>
              <div className='contribution-button-container mt-2'>
                <Button
                  loading={isPoolCreationFeeExecutionLoading}
                  onClick={handlePoolCreationForBNBWithToken}
                  block={true}
                  disabled={isPoolCreationFeeExecutionLoading}
                  type="primary">
                  Set Fee (with token BNB Pool)
                </Button>
              </div>

              <div className='contribution-button-container mt-2'>
                <Button
                  loading={isPoolCreationFeeExecutionLoading}
                  onClick={handlePoolCreationForNonBNBWithToken}
                  block={true}
                  disabled={isPoolCreationFeeExecutionLoading}
                  type="primary">
                  Set Fee (with token NON BNB Pool)
                </Button>
              </div>

              <div className='contribution-button-container mt-2'>
                <Button
                  loading={isPoolCreationFeeExecutionLoading}
                  onClick={handlePoolCreationForNonBNBWithoutToken}
                  block={true}
                  disabled={isPoolCreationFeeExecutionLoading}
                  type="primary">
                  Set Fee (without token NON BNB Pool)
                </Button>
              </div>
            </Row>


          </Card>
        ) : (<></>)
      }
    </div>

  )
}