import React, { useEffect, useState } from 'react'
import { Row, Col } from 'reactstrap'
import { Card, Spin } from 'antd'
import DataTable, { createTheme } from 'react-data-table-component'
import { getPoolWhiteListedStatus, getWhitelistedAddresses } from '../../Blockchain/web3.service'

export default function WhitelistedAddressWidget(props) {

  const { isPrivateSaleLoading, privateSaleAddress } = props
  const [isWhitelisted, setIsWhitelisted] = useState(false)
  const [isWhitelistingStatusCheckLoading, setIsWhitelistingStatusCheckLoading] = useState(false)
  const [isWhitelistedUsersLoading, serIsWhitelistedUsersLoading] = useState(false)
  const [whitelistedUsers, setWhitelistedUsers] = useState([])

  const fetchWhitelistingStatus = async () => {
    try {
      setIsWhitelistingStatusCheckLoading(true)
      const whitelistStatusResponse = await getPoolWhiteListedStatus(privateSaleAddress)
      setIsWhitelisted(whitelistStatusResponse)
      setIsWhitelistingStatusCheckLoading(false)
    } catch (error) {
      setIsWhitelistingStatusCheckLoading(false)
      setIsWhitelisted(false)
      console.error("ERROR while fetching whitelisting status ", error)
    }
  }

  const getWhitelistedUsers = async () => {
    try {
      serIsWhitelistedUsersLoading(true)
      const whitelistedUsersList = await getWhitelistedAddresses(privateSaleAddress)
      setWhitelistedUsers(whitelistedUsersList)
      serIsWhitelistedUsersLoading(false)
    } catch (error) {
      serIsWhitelistedUsersLoading(false)
      setWhitelistedUsers([])
      console.error("ERROR while fetching whitelisted user list ", error)
    }
  }

  useEffect(() => {
    if (privateSaleAddress) {
      fetchWhitelistingStatus()
      getWhitelistedUsers()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [privateSaleAddress])


  const columns = [
    {
      name: '# No',
      sortable: false,
      cell: (row, index) => (
        <div className='d-flex justify-content-center align-items-center'>
          # {index + 1}
        </div>
      )
    },
    {
      name: 'User Address',
      sortable: false,
      cell: row => (
        <div className='d-flex justify-content-center align-items-center'>
          {row}
        </div>
      )
    }

  ]

  createTheme('dark', {
    background: {
      default: 'transparent',
    },
  });

  return (
    <>
      {
        isPrivateSaleLoading || isWhitelistingStatusCheckLoading ? (
          <Card className="mt-4">
            <div className="d-flex justify-content-center" >
              <Spin />
            </div>
          </Card>

        ) : (
          isWhitelisted ? (
            <Card className="mt-4" title="Whitelisted Addresses">

              {
                isPrivateSaleLoading || isWhitelistedUsersLoading ? (
                  <div className='d-flex mt-2 justify-content-center'>
                    <Spin size="small" />
                  </div>
                ) : (
                  <div>
                    <Row>
                      <Col>
                        <DataTable
                          noHeaders
                          pagination
                          theme="dark"
                          data={whitelistedUsers}
                          columns={columns}
                          highlightOnHover
                          pointerOnHover
                          paginationRowsPerPageOptions={[10, 25, 50, 100]}
                        />
                      </Col>
                    </Row>


                  </div>
                )
              }

            </Card>
          ) : (
            <></>
          )
        )
      }
    </>

  )
}