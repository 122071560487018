import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'
import { Card, Tooltip, Spin, Tag } from 'antd'
import { CopyFilled } from '@ant-design/icons'
import { getEllipsisTxt } from '../../Helpers/Formatters'
import usePoolAddresses from '../../Hooks/usePoolAddresses'
import { configs } from '../../Blockchain/web3.config'
export default function FundingPoolWidget(props) {

    const { privateSaleAddress, isPrivateSaleLoading } = props
    const { isLoading, poolAddressDetails } = usePoolAddresses({ poolAddress: privateSaleAddress })
    const [copied, setCopied] = useState('Copy')

    const CopyToClipboardTokenAddress = () => {

        navigator.clipboard.writeText(poolAddressDetails?.tokenAddress)
        setCopied('Copied')
        setTimeout(function () {
            setCopied('Copy')
        }, 1000);

    }

    const CopyToClipboardWalletAddress = () => {
        navigator.clipboard.writeText(poolAddressDetails?.poolOwner)
        setCopied('Copied')
        setTimeout(function () {
            setCopied('Copy')
        }, 1000);
    }

    return (
        <Card className="mt-4">
            <Row>
                <div className="text-center">Funding Pool</div>
            </Row>

            {
                isPrivateSaleLoading ? (
                    <div className='d-flex justify-content-center mt-3'>
                        <Spin size="small" />
                    </div>
                ) : (
                    <div>
                        <Row className="mt-3">
                            <Col>
                                Token Address
                            </Col>

                            <Col className="text-end">

                                {
                                    isLoading ? (
                                        <div className='loader'>
                                            <Spin size='small' />
                                        </div>
                                    ) : (<>

                                        {
                                            poolAddressDetails?.tokenAddress.includes("0x00000") ? (
                                                <Tag color="orange">Pending</Tag>
                                            ) : (
                                                <>
                                                    <a
                                                        className="small"
                                                        href={`${configs.blockExplorer}/address/${poolAddressDetails?.tokenAddress}`}
                                                        rel="noreferrer"
                                                        target="_blank">
                                                        {getEllipsisTxt(poolAddressDetails?.tokenAddress, 7)}
                                                    </a>
                                                    <Tooltip title={copied}><CopyFilled style={{ marginLeft: '5px' }} onClick={CopyToClipboardTokenAddress} /></Tooltip>
                                                </>
                                            )
                                        }

                                    </>)
                                }

                            </Col>
                        </Row>

                        <hr />

                        <Row className="mt-2">
                            <Col>
                                Owner Wallet
                            </Col>

                            <Col className="text-end">

                                {
                                    isLoading ? (
                                        <div className='loader'>
                                            <Spin size='small' />
                                        </div>
                                    ) : (<>
                                        <a
                                            className="small"
                                            href={`${configs.blockExplorer}/address/${poolAddressDetails?.poolOwner}`}
                                            rel="noreferrer"
                                            target="_blank">
                                            {getEllipsisTxt(poolAddressDetails?.poolOwner, 7)}
                                        </a>
                                        <Tooltip title={copied}><CopyFilled style={{ marginLeft: '5px' }} onClick={CopyToClipboardWalletAddress} /></Tooltip>
                                    </>)
                                }

                            </Col>
                        </Row>

                        <hr />


                    </div >
                )
            }

        </Card >
    )
}