
import React, { useEffect, useState } from 'react'
import './Navbar.css'
import { Drawer, Modal } from 'antd'
import { MenuUnfoldOutlined } from '@ant-design/icons'
import MenuComponent from '../Menu/MenuComponent'
import { Row, Col } from 'reactstrap'
import Metamask from '../../Images/MetaMask.png'
import WalletConnect from '../../Images/walletconnect.png'
import TrustWallet from '../../Images/TWT.png'
import { getEllipsisTxt } from '../../Helpers/Formatters'
import { useWeb3React } from '@web3-react/core'
import { injected } from '../../Blockchain/connectors/metaMask'
import { wcConnector } from '../../Blockchain/connectors/walletConnect'
import { Copy, ExternalLink, CheckCircle } from 'react-feather'
import Logo from '../../Images/logo.png'
import { getChainNetworkByChainId } from '../../Blockchain/utils/chainList'
import { configs } from '../../Blockchain/web3.config'

// social icons
import facebook from '../../Images/social/facebook.png'
import twitter from '../../Images/social/twitter.png'
import telegram from '../../Images/social/telegram.png'
import email from '../../Images/social/email.png'

function Navbar() {

    const [visible, setVisible] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const web3React = useWeb3React()
    const { account, deactivate } = useWeb3React()
    const [addressCopy, setAddressCopy] = useState(false)
    const [isDisconnectVisible, setIsDisconnectVisible] = useState(false)
    const [currentNetworkID, setCurrentNetworkID] = useState(0)
    const [networkName, setNetworkName] = useState('Unknown Network')
    //check current network if metamask installed and perform the switch network task
    useEffect(() => {
        if (window.ethereum) {
            window.ethereum.on('chainChanged', event => {
                const chainID = parseInt(event.toString(), 16)
                setCurrentNetworkID(chainID)
            });

            window.ethereum.request({ method: "eth_chainId" })
                .then(chainId => {
                    setCurrentNetworkID(parseInt(chainId, 16))
                })

        }

    }, [])

    useEffect(() => {
        const network = getChainNetworkByChainId(currentNetworkID)
        console.log('currentNetworkID', currentNetworkID)
        setNetworkName(network ? network.chainName : 'Unknown Network')
        if (currentNetworkID !== 0) {
            const defaultChainId = configs.chainId
            if (defaultChainId !== currentNetworkID) {
                changeNetwork(defaultChainId)
            }
        } else {
            setNetworkName('Unknown Network')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentNetworkID])


    const changeNetwork = async (chainID) => {
        const result = getChainNetworkByChainId(chainID)
        try {
            if (!window.ethereum) throw new Error("No crypto wallet found");
            await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                    {
                        ...result
                    }
                ]
            });
        } catch (err) {

        }
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        setIsDisconnectVisible(false)
    };

    const showDisconnectModal = () => {
        setIsDisconnectVisible(true)
        setVisible(false)
    }
    const handleDisconnect = async () => {
        setVisible(false);
        try {
            window.localStorage.removeItem("userData")
            deactivate()
            handleCancel()
        } catch (error) {
            console.error("error while logout from user ", error)
        }
    }

    const copyAddress = () => {
        setAddressCopy(true)
        navigator.clipboard.writeText(account)
    }

    const showDrawer = () => {
        setVisible(true);
    };

    const onClose = () => {
        setVisible(false);
    };

    const handleMetamaskConnect = async () => {
        try {
            await web3React.activate(injected)
            const loginPayload = {
                provider: 'injected',
                isLoggedIn: true
            }
            window.localStorage.setItem('userData', JSON.stringify(loginPayload))
        } catch (error) {

            const loginPayload = {
                provider: 'injected',
                isLoggedIn: false
            }
            window.localStorage.setItem('userData', JSON.stringify(loginPayload))
        } finally {
            setIsModalVisible(false)
        }
    }

    const handleWalletConnectConnect = async () => {
        try {
            await web3React.activate(wcConnector)
            const loginPayload = {
                provider: 'walletconnect',
                isLoggedIn: true
            }
            window.localStorage.setItem('userData', JSON.stringify(loginPayload))
        } catch (error) {
            const loginPayload = {
                provider: 'walletconnect',
                isLoggedIn: false
            }
            window.localStorage.setItem('userData', JSON.stringify(loginPayload))
            console.error('error connecting to wallet-connect ', error)
        } finally {
            setIsModalVisible(false)
        }
    }


    return (
        <nav className="navbar navbar-margin fixed-top navbar-light bg-dark-blue-nav pt-3 pb-3 d-flex justify-content-between">

            <div>
                <MenuUnfoldOutlined className="text-start hide-on-large-devices mobile-menu-icon" onClick={showDrawer} />
            </div>

            <div>
                <button className="btn blue-button-bordered hide-on-small-devices" style={{ marginRight: '15px' }}>
                    {networkName}
                </button>
                {!account ?
                    <button className="btn blue-button text-light" style={{ marginRight: '15px' }} onClick={showModal}>Connect</button>
                    :
                    <button className="btn blue-button text-light" style={{ marginRight: '15px' }} onClick={showDisconnectModal}>{getEllipsisTxt(account, 7)}</button>
                }
            </div>

            <Drawer placement="left" onClose={onClose} visible={visible} closable={false} width="250px">
                <MenuComponent />

                <div className='social-icons'>
                    <a href='https://facebook.com'>
                        <img src={facebook} alt="facebook" style={{ width: '25px', marginLeft: '43px' }} />
                    </a>
                    <a href='https://twitter.com'>
                        <img src={twitter} alt="twitter" style={{ width: '25px', marginLeft: '10px' }} />
                    </a>
                    <a href='https://telegram.com'>
                        <img src={telegram} alt="telegram" style={{ width: '25px', marginLeft: '10px' }} />
                    </a>
                    <span href='#'>
                        <img src={email} alt="email" style={{ width: '25px', marginLeft: '10px' }} />
                    </span>
                </div>
            </Drawer>

            <Modal title="Connect to a wallet" visible={isModalVisible} footer={false} onOk={handleOk} onCancel={handleCancel}>
                <Row>
                    <Col className="text-center hover-color" onClick={handleMetamaskConnect}>
                        <img src={Metamask} alt="metamask" style={{ width: '50px', marginTop: '2px' }} /><br />
                        <span className="fw-bold">Metamask</span>
                    </Col>

                    <Col className="text-center hover-color" onClick={handleWalletConnectConnect}>
                        <img src={WalletConnect} alt="walletconnect" style={{ width: '50px' }} /><br />
                        <span className="fw-bold">WalletConnect</span>
                    </Col>

                    <Col className="text-center hover-color" onClick={handleWalletConnectConnect}>
                        <img src={TrustWallet} alt="trustWallet" style={{ width: '50px' }} /><br />
                        <span className="fw-bold">Trust Wallet</span>
                    </Col>
                </Row>
            </Modal>

            <Modal title="Connect to wallet" visible={isDisconnectVisible} onOk={handleOk} onCancel={handleCancel} footer={null}>

                <div className="row">
                    <p className="text-muted fw-bold col-lg-8 col-sm-12 hide-on-mobile">Connected with Metamask</p>
                    <p className="text-lg-end col-lg-4 col-md-12 col-sm-12 fw-bold cursor" onClick={handleDisconnect}>
                        <button className="btn blue-button text-light col-12" style={{ marginRight: '15px' }} onClick={handleDisconnect}>Disconnect</button>
                    </p>
                </div>

                <button className="btn blue-button-bordered hide-on-small-devices" style={{ marginRight: '15px' }}><img src={Logo} style={{ width: '20px', marginRight: '15px' }} alt='' />{getEllipsisTxt(account, 18)}</button>

                <div className="row mt-3">
                    {
                        !addressCopy &&
                        <p className="fw-bold col-8 hide-on-mobile cursor" onClick={copyAddress}>
                            <Copy size={18} color="#c6b27d" />
                            <span style={{ marginLeft: '5px' }}>Copy address</span>
                        </p>
                    }
                    {
                        addressCopy &&
                        <p className="fw-bold col-8 hide-on-mobile">
                            <CheckCircle size={18} color="#c6b27d" />
                            <span style={{ marginLeft: '5px' }}>Copied</span>
                        </p>
                    }

                    <p className="text-lg-end col-md-4 col-sm-6 fw-bold">
                        <a href={`https://bscscan.com/address/${account}`} target="_blank" className="col-lg-4 col-md-6 col-sm-6  fw-bold" rel="noreferrer">
                            <ExternalLink size={18} color="#c6b27d" />
                            <span style={{ marginLeft: '5px' }}>View on explorer</span>
                        </a>
                    </p>
                </div>

            </Modal>

        </nav>
    )
}

export default Navbar
