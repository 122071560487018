import React, { useEffect, useState } from 'react'
import {
  Row,
  Col, Card,
  Input,
  Button,
  Form,
  DatePicker,
  Checkbox,
  Spin,
  Alert,
  message
} from 'antd';
import './ApproveToken.css'
import moment from 'moment'
import usePrivateSaleTokenFee from '../../../Hooks/usePrivateSaleTokenFee'
import NumberFormat from 'react-number-format'
import { getTheBalanceOfAnotherAddress } from '../../../Blockchain/web3.service'
import { useWeb3React } from '@web3-react/core';

export default function PreSalesInfo(props) {

  const {
    privateSaleName,
    setPrivateSaleName,
    setStepNumber,
    stepNumber,
    tokenPerBNB,
    setTokenPerBNB,
    minimumBuy,
    setMinimumBuy,
    maximumBuy,
    setMaximumBuy,
    hardCap,
    setHardCap,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    isVestingEnable,
    setIsVestingEnable,
    initialTokenReleasePercentage,
    setInitialTokenReleasePercentage,
    teamTokenReleasePercentage,
    setTeamTokenReleasePercentage,
    vestingPeriodInDays,
    setVestingPeriodInDays,
    tokenMetadata,
    isNextButtonActive,
    setIsNextButtonActive,
    isWhitelistingEnabled,
    setIsWhitelistingEnabled,
    publicStartTime,
    setPublicStartTime,
    selectedToken
  } = props

  const [tokenNeeded, setTokenNeeded] = useState(0)
  const [isMinBuyLarge, setIsMinBuyLarge] = useState(false)
  const [userTokenBalance, setUserTokenBalance] = useState(0.0)
  const [isTokenBalanceLoading, setIsTokenBalanceLoading] = useState(0.0)
  const [isTokenBalanceExceeds, setIsTokenBalanceExceeds] = useState(false)
  const isPublicStartTimeValid = true
  const { tokenFeePercentage } = usePrivateSaleTokenFee()
  const { account } = useWeb3React()

  // validation related data
  const [start, setStart] = useState(null)
  const [end, setEnd] = useState(null)
  const [endTimeError, setEndTimeError] = useState(false)
  const [publicTimeError, setPublicTimeError] = useState(false)

  useEffect(() => {
    if (parseFloat(minimumBuy) >= parseFloat(maximumBuy)) {
      setIsMinBuyLarge(true)
      setIsNextButtonActive(false)
    } else {
      setIsMinBuyLarge(false)
      setIsNextButtonActive(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maximumBuy, minimumBuy])

  useEffect(() => {

    if (account && tokenMetadata && tokenMetadata.tokenAddress) {
      calculateUserTokenBalance()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, tokenMetadata])

  useEffect(() => {
    if (!isWhitelistingEnabled) {
      setPublicTimeError(false)
      setPublicStartTime(null)
    } 
  }, [isWhitelistingEnabled, setPublicStartTime])

  const calculateUserTokenBalance = async () => {
    try {
      setIsTokenBalanceLoading(true)
      const actualTokenBalance = await getTheBalanceOfAnotherAddress(tokenMetadata?.tokenAddress, account)
      setUserTokenBalance(actualTokenBalance)
      setIsTokenBalanceLoading(false)
    } catch (error) {
      setIsTokenBalanceLoading(false)
      setUserTokenBalance(0.00)
      console.error("ERROR while calculating token balance of the user address ", error)
    }
  }

  useEffect(() => {
    let feeAmount = 0.0
    const tokenNeededWithoutFee = parseInt(hardCap) * parseInt(tokenPerBNB)

    //calculating fee
    if (tokenFeePercentage) {
      const fee = (tokenNeededWithoutFee / 100) * tokenFeePercentage
      feeAmount = fee
    }
    const totalTokenNeeded = feeAmount + tokenNeededWithoutFee
    setTokenNeeded(totalTokenNeeded)

    if (totalTokenNeeded > userTokenBalance) {
      setIsTokenBalanceExceeds(true)
    } else {
      setIsTokenBalanceExceeds(false)
    }

  }, [tokenFeePercentage, hardCap, tokenPerBNB, userTokenBalance])

  const handleBack = () => {
    setStepNumber(stepNumber - 1)
  }

  const onChangeStartDate = (value, dateString) => {
    setStartTime(dateString)
    setStart(dateString)
    //validatePublicStartTime()
  }

  const onChangeEndDate = (value, dateString) => {
    setEndTime(dateString)
    setEnd(dateString)
    validateStartAndEnd(dateString)
  }

  const onChangePublicStartTime = (value, dateString) => {
    setPublicStartTime(dateString)
    //validatePublicStartTime()
    validateEndTimeWithPublicStartTime(dateString)
  }
  const onFinish = () => {
    setStepNumber(stepNumber + 1)
  };

  const validateStartAndEnd = (end) => {
    if (moment(end).isBefore(start)) {
      message.error('Invalid end time!')
      setEndTimeError(true)
    }  else {
      setEndTimeError(false)
    }
  }

  const validateEndTimeWithPublicStartTime = (publicstarttime) => {
    if (moment(publicstarttime).isAfter(end) || moment(publicstarttime).isBefore(start)) {
      message.error('Invalid public sale start time!')
      setPublicTimeError(true)
    }  else {
      setPublicTimeError(false)
    }
  }


  return (

    <>
      {
        isTokenBalanceLoading ? (
          <div className="mx-auto mt-5 col-11">
            <Card className="pt-3 pb-3">
              <div className='d-flex justify-content-center text-centered'>
                <Spin size="default" />
              </div>
            </Card>
          </div>
        ) : (
          <div className="mx-auto mt-5 col-11">
            <Card className="pt-3 pb-3">
              <Form
                name="step_two"
                onFinish={onFinish}
                initialValues={{
                  privateSaleName: privateSaleName,
                  tokenPerBNB: tokenPerBNB,
                  minimumBuy: minimumBuy,
                  maximumBuy: maximumBuy,
                  hardCap: hardCap,
                  startTime: startTime ? moment(startTime, "yyyy-MM-DD HH:mm:ss") : null,
                  endTime: endTime ? moment(endTime, "yyyy-MM-DD HH:mm:ss") : null,
                  initialTokenReleasePercentage: initialTokenReleasePercentage,
                  vestingPeriodInDays: vestingPeriodInDays,
                  teamTokenReleasePercentage: teamTokenReleasePercentage,
                  publicStartTime: publicStartTime ? moment(publicStartTime, "yyyy-MM-DD HH:mm:ss") : null,
                }}
              >

                <Row className='pt-2'>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <span className="text-muted">Project Name</span>
                    <div className='right-padding'>
                      <Form.Item
                        value={privateSaleName}
                        name="privateSaleName"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field!',
                          },
                        ]}
                      >
                        <div>
                          <Input
                            className="input-background-inside-form"
                            value={privateSaleName}
                            onChange={e => setPrivateSaleName(e.target.value)}
                          />
                        </div>

                      </Form.Item>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <span className="text-muted">Tokens Per {selectedToken?.tokenName} ~ 1 {selectedToken?.tokenName} = {tokenPerBNB} {tokenMetadata?.tokenSymbol}</span>
                    <Form.Item
                      value={tokenPerBNB}
                      name="tokensPerBNB"
                      rules={[
                        {
                          required: true,
                          message: 'This is a required field!',
                        },
                      ]}
                    >
                      <div>
                        <Input
                          className="input-background-inside-form"
                          value={tokenPerBNB}
                          onChange={e => setTokenPerBNB(e.target.value)}
                        />

                      </div>

                    </Form.Item>
                  </Col>
                </Row>


                <Row>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mt-3">
                    <span className="text-muted">Minimum Buy ({selectedToken?.tokenName})</span>
                    <div className='right-padding'>
                      <Form.Item
                        value={minimumBuy}
                        onChange={e => setMinimumBuy(e.target.value)}
                        className="input-background-inside-form"
                        name="minimumBuy"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                      <div className='remove-margin-top'>
                        {
                          isMinBuyLarge ? (
                            <span className="text-validation-error">Min buy must be less than max buy</span>
                          ) : (
                            <></>
                          )
                        }
                      </div>
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mt-3">
                    <span className="text-muted">Maximum Buy ({selectedToken?.tokenName})</span>
                    <Form.Item
                      className="input-background-inside-form"
                      name="maximumBuy"
                      onChange={e => setMaximumBuy(e.target.value)}
                      rules={[
                        {
                          required: true,
                          message: 'This is a required field!',
                        },
                      ]}
                      value={maximumBuy}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className='right-padding'>
                      <span className="text-muted">Hard Cap ({selectedToken?.tokenName})</span>
                      <Form.Item
                        className="input-background-inside-form"
                        name="hardCap"
                        value={hardCap}
                        onChange={e => setHardCap(e.target.value)}
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field!',
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className='right-padding'>
                      <span className="text-muted">Start time (UTC {moment().utc().format('yyyy-MM-DD HH:mm:ss').toString()} Now)</span><br />
                      <Form.Item
                        name="startTime"
                        rules={[
                          {
                            required: true,
                            message: 'This is a required field!',
                          },
                        ]}
                      >
                        <DatePicker
                          className="input-background-inside-form col-12"
                          //format="YYYY-MM-DD HH:mm:ss"
                          format={ date => date.utc().format('YYYY-MM-DD HH:mm:ss')}
                          onChange={onChangeStartDate}
                          disabledDate={d => !d || d.isBefore(moment().utc().format('yyyy-MM-DD').toString())}
                          showTime
                        />
                      </Form.Item>
                    </div>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <span className="text-muted">End time (UTC)</span><br />
                    <Form.Item
                      name="endTime"
                      rules={[
                        {
                          required: true,
                          message: 'This is a required field!',
                        },
                      ]}
                    >
                      <DatePicker
                        className="input-background-inside-form col-12"
                        onChange={onChangeEndDate}
                        disabledDate={d => !d || d.isBefore(startTime)}
                        //format="YYYY-MM-DD HH:mm:ss"
                        format={ date => date.utc().format('YYYY-MM-DD HH:mm:ss')}
                        showTime
                        disabled={!startTime}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    <Checkbox
                      checked={isWhitelistingEnabled}
                      onChange={e => setIsWhitelistingEnabled(e.target.checked)}
                      disabled={!isNextButtonActive}
                      className="input-background-inside-form mt-4">
                      Enable Whitelisting Feature
                    </Checkbox>
                  </Col>

                  <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                    <div className='right-padding mt-2'>
                      <span className="text-muted">Public Sale Start Time (UTC)</span><br />
                      <Form.Item
                        name="publicStartTime"
                        rules={[
                          {
                            required: isWhitelistingEnabled ? true : false,
                            message: 'This is a required field!',
                          },
                        ]}
                      >
                        <DatePicker
                          className="input-background-inside-form col-12"
                          //format="YYYY-MM-DD HH:mm:ss"
                          format={ date => date.utc().format('YYYY-MM-DD HH:mm:ss')}
                          onChange={onChangePublicStartTime}
                          disabledDate={d => !d || d.isBefore(moment().utc().format('yyyy-MM-DD').toString())}
                          disabled={!isWhitelistingEnabled}
                          showTime
                        />
                      </Form.Item>
                      <div className='remove-margin-top'>
                        {
                          !isPublicStartTimeValid ? (
                            <span className="text-validation-error">Public sale should start after the pool started</span>
                          ) : (
                            <></>
                          )
                        }
                      </div>
                    </div>
                  </Col>
                </Row>


                <Row className='mt-4'>
                  <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                    {
                      isTokenBalanceExceeds ? (
                        <Alert
                          message="Insufficient Token Balance"
                          description={`Seems like you don't have enough token to create this pool. your current token balance is ${userTokenBalance ? userTokenBalance.toFixed(2) : 0}`}
                          type="warning"
                          showIcon
                          closable={false}
                        />
                      ) : (<></>)
                    }

                  </Col>

                </Row>

                <Checkbox
                  checked={isVestingEnable}
                  onChange={e => setIsVestingEnable(e.target.checked)}
                  className="input-background-inside-form mt-4">
                  Investor Vesting Period
                </Checkbox>

                {
                  isVestingEnable ?
                    <>
                      <Row className="mt-4">
                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <div className='right-padding'>
                            <span className="text-muted">Initial token release (percentage)</span>
                            <Form.Item
                              className="input-background-outside input-background-inside-form"
                              name="initialTokenReleasePercentage"
                              suffix="%"
                              value={initialTokenReleasePercentage}
                              onChange={e => setInitialTokenReleasePercentage(e.target.value)}
                              rules={[
                                {
                                  required: true,
                                  message: 'This is a required field!',
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                        </Col>

                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <span className="text-muted">Vesting period each cycle (days)</span>
                          <Form.Item
                            name="vestingPeriodInDays"
                            className="input-background-inside-form"
                            value={vestingPeriodInDays}
                            onChange={e => setVestingPeriodInDays(e.target.value)}
                            rules={[
                              {
                                required: true,
                                message: 'This is a required field!',
                              },
                            ]}
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row>

                        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                          <div className='right-padding'>
                            <span className="text-muted">Team token release cycle (percentage)</span>
                            <Form.Item
                              name="teamTokenReleasePercentage"
                              suffix="%"
                              className="input-background-inside-form"
                              value={teamTokenReleasePercentage}
                              onChange={e => setTeamTokenReleasePercentage(e.target.value)}
                              rules={[
                                {
                                  required: true,
                                  message: 'This is a required field!',
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                        </Col>

                      </Row>

                    </>
                    :
                    ''
                }

                <Row className="mt-3 text-center">
                  {tokenNeeded > 0 && <span className="text-muted">Need <NumberFormat
                    value={tokenNeeded}
                    displayType={'text'}
                    thousandSeparator={true}
                    suffix={` ${tokenMetadata?.tokenName} `}
                  />
                    tokens to create launchpad</span>}
                </Row>

                <Row className="d-flex justify-content-end mt-4">
                  <Form.Item>
                    <Button type="default" onClick={handleBack} style={{ marginRight: '8px' }}>
                      Back
                    </Button>
                    <Button type="primary" htmlType="submit" disabled={!isNextButtonActive || isTokenBalanceExceeds || endTimeError || publicTimeError}>
                      Next
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
            </Card>
          </div >
        )
      }
    </>

  )
}
